import moment from "moment";
import { baseOrderUrl } from "../../../../../Config";
import orderInstance from "../../../../../api/orderInterceptor";
import { API_PATH } from "../../../../../constant/apiPath";
import { useSelector } from "react-redux";

const createOrder = async (order, linedetails, orderplan, documents, type) => {
  console.warn(
    "createOrder=>",
    order,
    "\n linedetails ",
    // linedetails,
    "\n orderplan ",
    orderplan,
    "\n docs "
    // documents
  );

  const formatDate = (date) => {
    return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
  };
  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    let k = 1000,
      dm = decimalPoint || 2,
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }

  // console.log(documents);
  const orderLineCollectionData = [];
  if (linedetails.length) {
    linedetails.map((item, index) => {
      orderLineCollectionData.push({
        LineIndex: 0,
        LinePk: "",
        LineFk: "",
        LineNumber: item?.lineNo ?? 0,
        // LinePartNumber: item?.itemNumber.length ? Number(item?.itemNumber) : 0,
        LinePartNumber: 0,
        LinePartNumberPK: "",
        LineDescription: item?.description,
        LineQuantity: item?.qtyOrdered ? Number(item?.qtyOrdered) : 0,
        LineInvoicedQuantity: 0,
        LineRecievedQuantity: item?.qtyReceived.length
          ? Number(item.qtyReceived)
          : 0,
        LineRemainingQuantity: item?.qtyRemaining.length
          ? Number(item.qtyRemaining)
          : 0,
        UnitOfQuantity: item?.unitOfQty?.Unit,
        LineItemPrice: item?.itemPrice.length ? Number(item.itemPrice) : 0,
        LineTotalPrice: item?.totalPrice.length ? Number(item.totalPrice) : 0,
        LineIncoTerms: item?.incoTerm?.Code,
        LineAdditionalTerms: item?.additionalTerms,
        LineConfirmNumber: item?.confirmNumber,
        LineConfirmDate:
          item?.confirmDate.length > 0 ? formatDate(item?.confirmDate) : "",
        LineInnerPacks: item?.innerPacks,
        LineInnerPacksUnit: item?.innerPackageType?.Code,
        LineOuterPacks: item?.outerPack,
        LineOuterPacksUnit: item?.outerPackageType?.Code,
        LineActualVolume: Number(item?.actualVolume),
        LineUnitOfVolume: item?.unitOfVolume?.Unit,
        LineActualWeight: Number(item?.actualWeight),
        LineUnitOfWeight: item?.unitOfWeight?.Unit,
        LineOuterPacksLength: item?.outerPackLength.length
          ? Number(item.outerPackLength)
          : 0,
        LineOuterPacksHeight: item?.outerPackHeight
          ? Number(item.outerPackHeight)
          : 0,
        // LineOuterPacksWidth: item?.outerPackWidth.length
        //   ? Number(item.outerPackWidth)
        //   : 0,
        LineOuterPacksWidth: item?.outerPackWidth,

        UnitOfDimension: item.unitOfDimenssions?.Unit,
        LineReqExWorkDate: "",
        LineReqInStoreDate:
          item?.reqInStoreDate.length > 0
            ? formatDate(item?.reqInStoreDate)
            : "",
        LineContainerNumber: item?.containerNumber,
      });
    });
  }

  const documentData = [];
  if (documents.docList.length) {
    documents.docList.map((item, index) => {
      let splitted = item?.base?.split(",");
      let extension = item?.docValue[0]?.name.split(".");
      // console.log("extension", extension);
      documentData.push({
        DocsPk: "",
        DocsFk: "",
        DocsDocType: item?.docType?.Code,
        docTypeDesc: item?.docType?.Description,
        DocsFileName: item?.docName,
        DocsFileSize: `${Math.ceil(formatFileSize(item?.docValue[0]?.size))}`,
        DocsFileSizeUnit: "kB",
        DocsUploadedDate: formatDate(item.uploadedOn),
        DocsUploadedBY: item?.uploadedBy,
        DocsFileData: splitted.length > 0 ? splitted[1] : "",
        DocsFileExtention: extension[1],
        DocsFileConversion: splitted.length > 0 ? splitted[0] : "",
      });
    });
  }
  const planData = [];
  if (orderplan?.tabData?.length) {
    orderplan?.tabData?.map((item, index) => {
      planData.push({
        PlanningIndex: 0,
        PlanningPk: "",
        PlanningFk: "",
        PlanningOriginCountryCode: item?.originCountryCode?.Code,
        PlanningDestinationCountryCode: item.destinationCountryCode.Code,
        PlanningOriginPortCode: item?.originPort?.Code,
        PlanningDestinationPortCode: item?.desnationPort?.Code,
        PlanningContainerNumbers: "",
        PlanningCutOfDate:
          item?.cutOffDate.length > 0 ? formatDate(item?.cutOffDate) : "",
        PlanningVoyage: item?.voyage,
        PlanningVessel: item?.vessel,
        PlanningEstimatedDepart: "",
        PlanningEstimatedArrival:
          item?.arrivalDate.length > 0 ? formatDate(item?.arrivalDate) : "",
        PlanningOriginCountryName: item?.originCountryCode?.Description,
        PlanningDestinationCountryName: item.destinationCountryCode.Description,
        PlanningOriginPortName: item?.originPort?.Description,
        PlanningDestinationPortName: item?.desnationPort?.Description,
        PlanningMasterBill: "",
        PlanningCarrierCode: "Hapag-Lloyd",
        PlanningCarrierSacCode: "HLCU",
        PlanningCarrierPK: "",
        PlanningSendingAgentPK: "",
        PlanningSendingAgentCode: "",
        PlanningReceivingAgentCode: "",
        PlanningReceivingAgentPK: "",
        PlanningFinalDestination: "",
        PlanningFinalDestinationPortName: "",
        PlanningForwarderCarrier: "",
        PlanningRates: "0",
        PlanningOrderReadyDate: "",
        PlanningOriginLat: item?.originPort?.Lat,
        PlanningOriginLng: item.originPort.Lng,
        PlanningDestinationLat: item?.desnationPort?.Lat,
        PlanningDestinationLng: item.desnationPort.Lng,
        IsFreezed: false,
      });
    });
  }
  let body = {
    OrderHeader: {
      OrderPK: "",
      OrderNumber: order?.orderNumber?.orderNumber,
      SplitNumber: "0",
      OrderStatus: "PLC",
      OrderStatusDescription: "Placed",
      OrderCurrencyCode: order?.orderDetails?.currencyOfPayment?.Code,
      OrderDate:
        order?.orderNumber?.orderDate.length > 0
          ? formatDate(order?.orderNumber?.orderDate)
          : formatDate(new Date()),
      OrderExWorksRequiredBy:
        order?.orderDetails?.reqWork.length > 0
          ? formatDate(order?.orderDetails?.reqWork)
          : formatDate(new Date()),
      OrderDeliveryRequiredBy: "",
      OrderConfirmDate: formatDate(new Date()),
      OrderConfirmNumber: "",
      OrderInvoiceDate: "",
      Is_SupplierMainorder: false,
      OrderInvoiceNumber: order?.orderDetails?.invoiceNumber,
      OrderServiceLevelcode: order?.orderDetails?.serviceLevel?.Code
        ? order?.orderDetails?.serviceLevel?.Code
        : "",
      OrderServiceLevelDescription: order?.orderDetails?.serviceLevel
        ?.Description
        ? order?.orderDetails?.serviceLevel?.Description
        : "",
      OrderTransportMode: order?.orderDetails?.transportMode.Code ?? "",
      OrderContainerMode: order?.orderDetails?.containerMode.Code ?? "",
      OrderIncoTerm: order?.orderDetails?.incoTerm.Code,
      OrderCountryOfOrigin: "",
      OrderBuyerPK: "",
      OrderType: "",
      OrderBuyerCode: order?.buyer?.buyerName?.Code,
      OrderBuyerName: order?.buyer?.buyerName?.Name,
      // OrderBuyerAddressPK: order?.buyer?.buyerAddress?.AddressPk,
      OrderBuyerAddressPK: "",
      OrderBuyerAddress1: order?.buyer?.buyerAddress?.Address1,
      OrderBuyerDeliveryAddressPK: "",
      OrderBuyerDeliveryAddress1: "",
      OrderBuyerContactName: "",
      OrderBuyerContactPK: "",
      OrderSupplierPK: "",
      OrderSupplierCode: order?.supplier?.supplierName?.Code,
      OrderSupplierName: order?.supplier?.supplierName?.Name,
      OrderSupplierAddressPK: order?.supplier?.supplierAddress?.AddressPk,
      OrderSupplierAddress1: order?.supplier?.supplierAddress?.Address1,
      OrderSupplierContactName: "",
      OrderSupplierContactPK: "",
      OrderSendingAgentPK: "",
      OrderSendingAgentCode: "",
      OrderReceivingAgentCode: "",
      OrderReceivingAgentPK: "",
      OrderPreAdviceId: "",
      OrderGoodsDescription: "",
      OrderAdditionalTerms: "",
      OrderSpecialInstruction: "",
      OrderActualVolume: 0.0,
      OrderUnitOfVolume: "M3",
      OrderActualWeight: 0.0,
      OrderUnitOfWeight: "KG",
      OrderChargeableWeight: "",
      OrderUnitOfChargeableWeight: "KG",
      OrderCreateDate: "",
      IsShipmentCreated: "",
      OrderConsolNumber: "",
    },
    OrderLinesCollection: orderLineCollectionData,
    OrderPlanningCollection: planData,
    OrderDocumentsCollection: documentData,
  };
  // await orderInstance.post(`${baseOrderUrl}${API_PATH.CREATE_ORDER}`, body);
  let { message } = await orderInstance[type](
    `${baseOrderUrl}${API_PATH.CREATE_ORDER}`,
    body
  );
  return message;
};
export { createOrder };
