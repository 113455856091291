import { configureStore } from "@reduxjs/toolkit";
import orderSlice from "./orderRedux/createOrder/orderspecificslice";
import headerSlice from "./orderRedux/createOrder/headerSlice";
import orderspecificerrorslice from "./orderRedux/createOrder/orderErrors/orderspecificerrorslice";
import linedetailsSlice from "./orderRedux/createOrder/linedetailsSlice";
import orderplanSlice from "./orderRedux/createOrder/orderplanSlice";
import inputSlice from "./orderRedux/createOrder/inputSlice";
import docSlice from "./orderRedux/createOrder/docSlice";
import createOrderSlice from "./orderRedux/createOrderSlice";
import orderPermission from "./orderRedux/orderPermission";
import reportTableSlice from "./orderRedux/reports/reportTableSlice";
import createBookingSlice from "./bookingRedux/createBookingSlice";
import bookingBasicDetailSlice from "./bookingRedux/bookingBasicDetailSlice";
import updateInfomation from "./updateRedux/updateInfomation";
import storeDataSlice from "./bookingRedux/storeDataSlice";
import selectedDataSlice from "./bookingRedux/selectedDataSlice";
import errorDataSlice from "./bookingRedux/bookingErrorSlice";
export default configureStore({
  reducer: {
    createBooking: createBookingSlice,
    bookingBasicDetails: bookingBasicDetailSlice,
    order: orderSlice,
    header: headerSlice,
    orderSpecificError: orderspecificerrorslice,
    linedetails: linedetailsSlice,
    orderplan: orderplanSlice,
    input: inputSlice,
    documents: docSlice,
    createOrderData: createOrderSlice,
    orderPermission: orderPermission,
    reportTable: reportTableSlice,
    updateInformation: updateInfomation,
    storeData: storeDataSlice,
    selectedData: selectedDataSlice,
    errorBooking: errorDataSlice,
  },
});
