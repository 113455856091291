import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { Typography } from "@mui/material";
import { updateResponse } from "../../../redux/orderRedux/createOrderSlice";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResponseDialoge() {
  const [open, setOpen] = React.useState(false);
  const [timeer, setTimer] = React.useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { createOrderData } = useSelector((state) => state);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  {
    createOrderData?.response?.Code === "Success" &&
      setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
  }

  useEffect(() => {
    if (timeer === 0) {
      dispatch(updateResponse({ resp: {} }));
      navigate(Routeconstant.ORDER_DASHBOARD);
    }
  }, [timeer]);
  return (
    <div>
      <Dialog
        open={createOrderData?.response?.Code === "Success"}
        // open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="font-change">
          {createOrderData?.response?.Message}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography textAlign={"center"} className="font-change">
              Redirecting to Dashboard in
            </Typography>
            <Typography
              variant="h3"
              color={"primary"}
              textAlign={"center"}
              className="font-change"
            >
              {" "}
              {timeer}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
