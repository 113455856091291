import { useEffect, useState } from "react";
import Layout from "../create/Layout";
import "../create/index.css";
import instance from "../../../api/Interceptor";
import { useParams } from "react-router-dom";
import { API_PATH } from "../../../constant/apiPath";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBasicDetails,
  editInitialState,
} from "../../../redux/bookingRedux/bookingBasicDetailSlice";
import {
  editFill,
  userTypeSelect,
} from "../../../redux/bookingRedux/createBookingSlice";
import {
  deststorepage,
  originstorepage,
} from "../../../redux/bookingRedux/storeDataSlice";
import {
  destselectedpage,
  originselectedpage,
} from "../../../redux/bookingRedux/selectedDataSlice";

const EditBooking = () => {
  const { bookingId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const getOrg = (orgSearch, code) => {
    let body = {
      CountryCode: "",
      Search: orgSearch ?? "",
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        dispatch(originstorepage(res?.data));
        if (res?.data.length === 1) {
          dispatch(originselectedpage(res?.data[0]));
        } else {
          let value = res?.data.find((item) => item?.Code === code);
          dispatch(originselectedpage(value));
        }
      })
      .catch((err) => console.log(err));
  };
  const getDest = (destSearch, code) => {
    let body = {
      CountryCode: "",
      Search: destSearch ?? "",
    };

    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        dispatch(deststorepage(res?.data));
        if (res?.data.length === 1) {
          dispatch(destselectedpage(res?.data[0]));
        } else {
          let value = res?.data.find((item) => item?.Code === code);
          dispatch(destselectedpage(value));
        }
      })
      .catch((err) => console.log(err));
  };
  function setBuyerSupplier() {
    dispatch(
      editFill({
        initialBuyerOrSupplier: [
          {
            ContactName: "abhishek",
            JobTitle: "asdfasd",
            JobCategory: "EMU",
            Email: "abhishek@sflhub.com",
            IsWebAccessEnabled: true,
            IsActive: true,
            Gender: "",
            OrgCode: "",
            OrgFullName: "",
            IsPassFilled: false,
            Password: "",
            ContactNumber: "",
            RegisteredDate: "2023-12-07 06:20:48.0966667",
            Access: "Fully Access",
            Roles: "Admin,User,Supplier,Buyer",
            OrgnisationCollection: [
              {
                OrgCode: "AXISHIBHZ",
                OrgFullName: "AXIS SHIPPING AGENCIAMENTO DE CARGAS LTDA",
                IsBuyer: true,
                IsSupplier: true,
                IsAgent: true,
              },
              {
                OrgCode: "SOFFREICD",
                OrgFullName: "SFLINS LOGISTICS.LTD",
                IsBuyer: true,
                IsSupplier: true,
                IsAgent: true,
              },
              {
                OrgCode: "1064472",
                OrgFullName: "SFLINS LOGISTICS.LTD",
                IsBuyer: true,
                IsSupplier: true,
                IsAgent: true,
              },
            ],
            LastLoginUtcDate: "2024-02-05 08:19:57.3133333",
            IsLoginEnabled: true,
            PK: "3f0ee116-72b8-45cf-9047-e1b796fcc598",
          },
        ],
        viewBuyers: [
          {
            OrgCode: "AXISHIBHZ",
            OrgFullName: "AXIS SHIPPING AGENCIAMENTO DE CARGAS LTDA",
            IsBuyer: true,
            IsSupplier: true,
            IsAgent: true,
          },
          {
            OrgCode: "SOFFREICD",
            OrgFullName: "SFLINS LOGISTICS.LTD",
            IsBuyer: true,
            IsSupplier: true,
            IsAgent: true,
          },
          {
            OrgCode: "1064472",
            OrgFullName: "SFLINS LOGISTICS.LTD",
            IsBuyer: true,
            IsSupplier: true,
            IsAgent: true,
          },
        ],
        viewSupplier: [
          {
            OrgCode: "101SUPMNL",
            OrgFullName: "101 SUPPLY CHAIN SOLUTIONS INC",
            IsBuyer: "False",
            IsSupplier: "True",
            IsAgent: "False",
            Addresses: [
              {
                Code: "101SUPMNL",
                ShortCode: "UNIT LOT D, GEDCOR SQUARE",
                Address1: "UNIT LOT D, GEDCOR SQUARE, IRASAN STREET",
                Address2: "BRGY. SAN DIONISIO",
                City: "PARANAQUE",
                PinCode: "1780",
              },
            ],
          },
        ],
        selectedBuyer: {
          OrgCode: "SOFFREICD",
          OrgFullName: "SFLINS LOGISTICS.LTD",
          IsBuyer: true,
          IsSupplier: true,
          IsAgent: true,
        },
        selectedSupplier: {
          OrgCode: "101SUPMNL",
          OrgFullName: "101 SUPPLY CHAIN SOLUTIONS INC",
          IsBuyer: "False",
          IsSupplier: "True",
          IsAgent: "False",
          Addresses: [
            {
              Code: "101SUPMNL",
              ShortCode: "UNIT LOT D, GEDCOR SQUARE",
              Address1: "UNIT LOT D, GEDCOR SQUARE, IRASAN STREET",
              Address2: "BRGY. SAN DIONISIO",
              City: "PARANAQUE",
              PinCode: "1780",
            },
          ],
        },
        address: {
          buyerAddress: [
            {
              Code: "SOFFREICD",
              ShortCode: "BANDH ROAD, BEHIND RAJAS",
              Address1: "301 KRISHNA TOWER  II SECTOR 12",
              Address2: "DWARKA.",
              City: "NEW DELHI#@",
              PinCode: "110071",
            },
            {
              Code: "SOFFREICD",
              ShortCode: "YENI MAH.  ISMET INONU BL",
              Address1: "YENI MAH.  ISMET INONU BLV KLAS PLAZA",
              Address2: "B BLOK NO:78 D:15  ICEL MERKEZ/ MERSIN (ICEL)",
              City: "MERSIN",
              PinCode: "656",
            },
          ],
          supplierAddress: [],
        },
        selectedAddress: {
          buyerAddress: {
            Code: "SOFFREICD",
            ShortCode: "YENI MAH.  ISMET INONU BL",
            Address1: "YENI MAH.  ISMET INONU BLV KLAS PLAZA",
            Address2: "B BLOK NO:78 D:15  ICEL MERKEZ/ MERSIN (ICEL)",
            City: "MERSIN",
            PinCode: "656",
          },
          supplierAddress: null,
        },
      })
    );
  }
  const getDetails = () => {
    setLoading(true);
    const body = {
      BookingNo: bookingId,
    };
    instance
      .post(`${API_PATH.GET_BOOKING_DETAILS}`, body)
      .then((res) => {
        dispatch(editInitialState(res?.data));
        dispatch(userTypeSelect(res?.data?.BookingHeader?.SupplierBuyerType));
        setData(res?.data);
        let orgSearch = res?.data?.BookingHeader?.OriginPortName;
        let orgCode = res?.data?.BookingHeader?.OriginPort;
        let destSearch = res?.data?.BookingHeader?.DestinationPortName;
        let destCode = res?.data?.BookingHeader?.DestinationPort;

        getOrg(orgSearch, orgCode);
        getDest(destSearch, destCode);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getDetails();
    return () => {
      dispatch(clearBasicDetails());
    };
  }, []);
  return <Layout />;
};
export default EditBooking;
