import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addContainer,
  updateOrderSpecificContainer,
  removeContainer,
  updateOrderSpecific,
} from "../../../../redux/orderRedux/createOrder/orderspecificslice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getContainerModes,
  getCountries,
  getCurrencyofPayment,
  getIncoTerm,
  getServiceLevel,
  getTransportModes,
} from "../apiCalls/get/getSelectList";
import { updateInputState } from "../../../../redux/orderRedux/createOrder/inputSlice";

const OrderDetails = ({ EditPermission }) => {
  const {
    orderSpecificError: { errors },
    order: { orderDetails },
    input: {
      currencyOfPayment,
      containerMode,
      transportMode,
      incoTerm,
      serviceLevel,
      countries,
    },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const addContainerObj = () => {
    dispatch(addContainer());
  };

  const updateOrderSpecificFun = (name, value) => {
    const objName = "orderDetails";
    dispatch(updateOrderSpecific({ name, value, objName }));
  };
  const updateOrderSpecificContainerArr = (index, value, name) => {
    dispatch(updateOrderSpecificContainer({ index, value, name }));
  };
  const removeContainerArr = (index) => {
    dispatch(removeContainer({ index }));
  };
  const getData = async () => {
    const promiseCurrencyPayment = getCurrencyofPayment();
    const promiseTransportModes = getTransportModes();
    const promiseContainerModes = getContainerModes();
    const promiseIncoTerm = getIncoTerm();
    const promiseCountries = getCountries();
    const promiseServiceLevel = getServiceLevel();
    const dataOfCurrencyPayment = await promiseCurrencyPayment;
    const dataOfTransportMode = await promiseTransportModes;
    const dataOfContainerMode = await promiseContainerModes;
    const dataOfIncoTerm = await promiseIncoTerm;
    const dataOfCountries = await promiseCountries;
    const dataOfServiceLevel = await promiseServiceLevel;
    // stores data in redux so if needed can be accessed on another step
    dispatch(
      updateInputState({
        name: "currencyOfPayment",
        data: dataOfCurrencyPayment,
      })
    );
    dispatch(
      updateInputState({
        name: "transportMode",
        data: dataOfTransportMode,
      })
    );
    dispatch(
      updateInputState({
        name: "containerMode",
        data: dataOfContainerMode,
      })
    );
    dispatch(
      updateInputState({
        name: "incoTerm",
        data: dataOfIncoTerm,
      })
    );
    dispatch(
      updateInputState({
        name: "countries",
        data: dataOfCountries,
      })
    );
    dispatch(
      updateInputState({
        name: "serviceLevel",
        data: dataOfServiceLevel,
      })
    );
  };
  useEffect(() => {
    setLoading(true);
    getData();
  }, []);
  return (
    <div
      style={{
        width: "100%",
      }}
      className="overCard"
    >
      <Typography
        variant="overline"
        color={"primary"}
        className="font-change"
        gutterBottom
      >
        Order Details
      </Typography>
      <Grid
        container
        columnSpacing={2}
        sx={{
          maxHeight: "60vh",
          overflow: "auto",
          paddingTop: "1rem",
          paddingRight: "1rem",
        }}
      >
        <Grid item lg={6} xs={12}>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              freeSolo
              size="small"
              disabled={EditPermission ? false : true}
              id="currencyofpayment"
              onChange={(e, value) => {
                if (value === null) {
                  updateOrderSpecificFun("currencyOfPayment", {});
                } else {
                  updateOrderSpecificFun("currencyOfPayment", value);
                }
              }}
              value={
                orderDetails?.currencyOfPayment?.Code
                  ? orderDetails?.currencyOfPayment
                  : null
              }
              options={currencyOfPayment}
              getOptionLabel={(option) => option.Code}
              renderInput={(params) => (
                <TextField
                  error={errors?.hasOwnProperty(
                    "orderDetails.currencyOfPayment.Code"
                  )}
                  {...params}
                  label={
                    <div className="font-change">
                      Currency of payment
                      <span
                        className="font-change"
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        *
                      </span>
                    </div>
                  }
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  helperText={
                    errors?.hasOwnProperty(
                      "orderDetails.currencyOfPayment.Code"
                    )
                      ? errors["orderDetails.currencyOfPayment.Code"]
                      : ""
                  }
                />
              )}
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            {" "}
            <Autocomplete
              freeSolo
              size="small"
              disabled={EditPermission ? false : true}
              id="containermode"
              onChange={(e, value) => {
                if (value === null) {
                  updateOrderSpecificFun("containerMode", {});
                } else {
                  updateOrderSpecificFun("containerMode", value);
                }
              }}
              // value={}
              value={
                orderDetails?.containerMode?.Code
                  ? orderDetails?.containerMode
                  : null
              }
              options={containerMode}
              getOptionLabel={(option) => option.Code}
              renderInput={(params) => (
                <TextField
                  className="font-change"
                  error={errors?.hasOwnProperty(
                    "orderDetails.containerMode.Code"
                  )}
                  {...params}
                  label={
                    <div className="font-change">
                      Container mode
                      <span
                        className="font-change"
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        *
                      </span>
                    </div>
                  }
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  helperText={
                    errors?.hasOwnProperty("orderDetails.containerMode.Code")
                      ? errors["orderDetails.containerMode.Code"]
                      : ""
                  }
                />
              )}
            />
          </div>

          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              className="font-change"
              // {...params}
              type="number"
              fullWidth
              disabled={EditPermission ? false : true}
              label="Weight (kg)"
              value={orderDetails?.weight}
              onChange={(e) => {
                // console.log(
                //   e.target.value,
                //   Number(e.target.value) >= 0,
                //   "this is the information"
                // );
                if (Number(e.target.value) >= 0)
                  updateOrderSpecificFun("weight", e.target.value);
              }}
              // InputProps={{
              //   ...params.InputProps,
              //   type: "search",
              // }}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="w-100 datepicker removeRed font-change"
              label={
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Req. Ex. Works{" "}
                </span>
              }
              disabled={EditPermission ? false : true}
              inputFormat="DD/MM/YYYY"
              value={orderDetails?.reqWork}
              onChange={(e) => updateOrderSpecificFun("reqWork", e)}
              renderInput={(params) => (
                <TextField
                  size="small"
                  // style={{ borderColor: "red !important" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              sx={{
                paddingTop: "0.8rem",
              }}
              disabled={EditPermission ? false : true}
              freeSolo
              size="small"
              id="free-solo-2-demo"
              onChange={(e, value) => {
                if (value === null) updateOrderSpecificFun("serviceLevel", {});
                else updateOrderSpecificFun("serviceLevel", value);
              }}
              // value={}
              value={
                orderDetails?.serviceLevel?.Description
                  ? orderDetails?.serviceLevel
                  : null
              }
              options={serviceLevel}
              getOptionLabel={(option) => option.Description}
              renderInput={(params) => (
                <TextField
                  className="font-change"
                  {...params}
                  label="Service Level"
                  disabled={EditPermission ? false : true}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              className="font-change"
              // {...params}
              onChange={(e) =>
                updateOrderSpecificFun("invoiceNumber", e.target.value)
              }
              disabled={EditPermission ? false : true}
              fullWidth
              value={orderDetails?.invoiceNumber}
              label="Invoice No."
              error={errors?.hasOwnProperty("orderDetails.invoiceNumber")}
              helperText={
                errors?.hasOwnProperty("orderDetails.invoiceNumber")
                  ? errors["orderDetails.invoiceNumber"]
                  : ""
              }
              // InputProps={{
              //   ...params.InputProps,
              //   type: "search",
              // }}
            />
          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              freeSolo
              disabled={EditPermission ? false : true}
              size="small"
              id="incoterm"
              onChange={(e, value) => {
                if (value === null) updateOrderSpecificFun("incoTerm", {});
                else updateOrderSpecificFun("incoTerm", value);
              }}
              value={
                orderDetails?.incoTerm?.Code ? orderDetails?.incoTerm : null
              }
              options={incoTerm}
              getOptionLabel={(option) => option.Code}
              renderInput={(params) => (
                <TextField
                  className="font-change"
                  error={errors?.hasOwnProperty("orderDetails.incoTerm.Code")}
                  {...params}
                  label={
                    <div className="font-change">
                      Inco term
                      <span
                        className="font-change"
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        *
                      </span>
                    </div>
                  }
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  helperText={
                    errors?.hasOwnProperty("orderDetails.incoTerm.Code")
                      ? errors["orderDetails.incoTerm.Code"]
                      : ""
                  }
                />
              )}
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              freeSolo
              disabled={EditPermission ? false : true}
              size="small"
              id="transportmode"
              onChange={(e, value) =>
                updateOrderSpecificFun("transportMode", value)
              }
              // onInputChange={(e, value) =>
              //   updateOrderSpecificFun("transportMode", value?.Description)
              // }
              // inputValue={orderDetails?.transportMode}
              value={
                orderDetails?.transportMode?.Code
                  ? orderDetails?.transportMode
                  : null
              }
              options={transportMode}
              getOptionLabel={(option) => option.Description}
              renderInput={(params) => (
                <TextField
                  className="font-change"
                  {...params}
                  label="Transport Mode"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              className="font-change"
              type="number"
              onChange={(e, value) => {
                if (Number(e.target.value) >= 0)
                  updateOrderSpecificFun("volume", e.target.value);
              }}
              value={orderDetails?.volume}
              fullWidth
              disabled={EditPermission ? false : true}
              label="Volume (cbm)"
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="w-100 datepicker removeRed font-change"
              disabled={EditPermission ? false : true}
              label={
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Req. In Store{" "}
                </span>
              }
              inputFormat="DD/MM/YYYY"
              value={orderDetails?.reqStore}
              onChange={(e) => updateOrderSpecificFun("reqStore", e)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              className="font-change"
              sx={{ marginTop: "0.8rem", marginBottom: "0.8rem" }}
              // type="number"
              value={orderDetails?.shipmentNumber}
              fullWidth
              label="Shipment Number"
              disabled={EditPermission ? false : true}
              error={errors?.hasOwnProperty("orderDetails.shipmentNumber")}
              helperText={
                errors?.hasOwnProperty("orderDetails.shipmentNumber")
                  ? errors["orderDetails.shipmentNumber"]
                  : ""
              }
              onChange={(e) =>
                updateOrderSpecificFun("shipmentNumber", e.target.value)
              }
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              freeSolo
              size="small"
              id="transportmode"
              disabled={EditPermission ? false : true}
              options={countries}
              getOptionLabel={(option) => option.Description}
              onChange={(e, value) =>
                updateOrderSpecificFun("originCountry", value?.Description)
              }
              renderInput={(params) => (
                <TextField
                  className="font-change"
                  {...params}
                  label="Origin Country"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </div>
        </Grid>
        {/* <Grid item lg={12} xs={12}>
          <Typography variant="overline" color={"primary"}>
            Container Required
          </Typography>
          {orderDetails?.containerRequired?.map((item, index) => {
            return (
              <Grid container columnSpacing={2} key={index}>
                <Grid item lg={5} xs={5}>
                  <Autocomplete
                    key={index}
                    freeSolo
                    size="small"
                    id="free-solo-2-demo"
                    options={top100Films.map((option) => option.title)}
                    onChange={(e, value) =>
                      updateOrderSpecificContainerArr(index, value, "count")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supplier Name"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={5} xs={5}>
                  <Autocomplete
                    key={index}
                    freeSolo
                    size="small"
                    id="free-solo-2-demo"
                    options={top100Films.map((option) => option.title)}
                    onChange={(e, value) =>
                      updateOrderSpecificContainerArr(index, value, "type")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supplier Name"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={2} xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => removeContainerArr(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </Grid> */}
        {/* <Button
          variant="contained"
          color="primary"
          onClick={addContainerObj}
          sx={{
            marginLeft: "3%",
          }}
        >
          Add Container
        </Button> */}
      </Grid>
    </div>
  );
};
export default OrderDetails;
