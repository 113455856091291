import TabList from "@mui/lab/TabList";
import { Button, IconButton } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  addTab,
  removeTab,
  selectTab,
} from "../../../../redux/orderRedux/createOrder/orderplanSlice";
import React from "react";
const OrderHeader = () => {
  const dispatch = useDispatch();
  const { tabData, selectedTab } = useSelector((state) => state.orderplan);

  const handleAddNewPlan = () => {
    let index = tabData.length;
    dispatch(addTab({ index }));
  };
  const handleRemovePlan = (index) => {
    dispatch(removeTab({ index }));
  };
  const handleSelectTab = (index) => {
    dispatch(selectTab({ index }));
  };
  return (
    <>
      <TabList aria-label="tablist" value={selectedTab}>
        {tabData.map((item, i) => {
          return (
            <div
              className="font-change"
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 0.5%",
              }}
            >
              <Tab
                className="font-change"
                key={i}
                label={`Plan ${item.index + 1}`}
                value={item.index}
                onClick={() => handleSelectTab(i)}
              />
              {/* <CancelRoundedIcon
                color="secondary"
                onClick={() => handleRemovePlan(i)}
              /> */}
            </div>
          );
        })}

        {/* <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleAddNewPlan}
        >
          Add Plan
        </Button> */}
      </TabList>
    </>
  );
};
export default OrderHeader;
