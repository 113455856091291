import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_PATH } from "../../constant/apiPath";
import instance from "../../api/Interceptor";

// action  createAsyncThunk is a middle ware used to call methods those have some delay in working like api call
export const UpdatedDataCollectionAPi = createAsyncThunk(
  "aplicationUpdateApi",
  async () => {
    // try {
    //   const responce = await instance.get(
    //     `${API_PATH.GET_UPDATE_INFORMATION}?CurrentPage=1&PageSize=1000000&SortColumnName=PushUtcDate&SortDirection=desc`
    //   );
    //   return responce;
    // } catch (err) {
    //   console.log(err);
    // }
  }
);
export const changeReadibilityOfUpdate = createAsyncThunk(
  "changeReadibilityOfUpdate",
  async () => {
    try {
      await instance.put(`${API_PATH.CHANGE_UPDATE_READ_STATUS}false`);
    } catch (err) {
      console.log(err);
    }
  }
);

const updateInformation = createSlice({
  // slice name
  name: "updateInformation",
  // states available to store the information
  initialState: {
    // checkUpdate : fasle,
    updateInformation: {},
    loading: false,
  },
  // reducers that hold the method to manipulate the states we have
  reducers: {
    // all the methods have params state and action
    setUpdateInformation: (state, action) => {
      state.updateInformation = { ...action.payload.information };
    },
  },
  extraReducers: (builder) => {
    // builder is used to get that UpdateDataCollectionapi have changes like fullfiled ,pending , rejected
    // through this we have automatically added the data in the state
    builder.addCase(UpdatedDataCollectionAPi.fulfilled, (state, action) => {
      state.updateInformation = { ...action.payload };
      state.loading = false;
    });
    builder.addCase(UpdatedDataCollectionAPi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(UpdatedDataCollectionAPi.rejected, (state, action) => {
      state.updateInformation = {};
      state.loading = false;
    });
  },
});

export const { setUpdateInformation } = updateInformation.actions;
export default updateInformation.reducer;

// export default updateInfomation
