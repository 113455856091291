import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSelector } from "react-redux";
import moment from "moment/moment";
const DocTableBody = ({ EditPermission }) => {
  const {
    documents: { docList },
  } = useSelector((state) => state);
  const { permisisonOrder } = useSelector((state) => state.orderPermission);
  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  return (
    <tbody>
      {docList?.map((item, index) => {
        return (
          <tr key={index}>
            <td className="font-change">{item?.id}</td>
            <td className="font-change">{item?.docType?.Code}</td>
            <td className="font-change">{item?.docName}</td>
            <td className="font-change">
              {formatFileSize(item?.docValue[0]?.size)}
            </td>
            <td className="font-change">
              {moment(item?.uploadedOn).format("MMM Do YYYY")}
            </td>
            <td className="font-change">{item?.uploadedBy}</td>
            <td className="font-change">
              <a
                href={docList[0]?.docValue[0]}
                download={docList[0]?.docValue[0].name}
              >
                <IconButton
                  disabled={EditPermission ? false : true}
                  size="small"
                  color="secondary"
                >
                  <FileDownloadIcon />
                </IconButton>
              </a>
            </td>
            <td className="font-change">
              <IconButton
                disabled={
                  EditPermission
                    ? permisisonOrder?.DeleteOrderDocument
                      ? false
                      : true
                    : true
                }
                size="small"
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
export default DocTableBody;
