import { Autocomplete, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAdditionalServices,
  updateDetails,
} from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import instance from "../../../../api/Interceptor";
import { API_PATH } from "../../../../constant/apiPath";
import { useEffect, useState } from "react";
import orderInstance from "../../../../api/orderInterceptor";
import { useLocation } from "react-router";
import {
  deststorepage,
  originstorepage,
} from "../../../../redux/bookingRedux/storeDataSlice";
import {
  destselectedpage,
  originselectedpage,
} from "../../../../redux/bookingRedux/selectedDataSlice";

const OrgDest = () => {
  const {
    errorBooking,
    bookingBasicDetails: { BookingHeader },
    createBooking,
    storeData: {
      store: { originunloco, destunloco },
    },
    selectedData: {
      selected: { destselectedunloco, originselectedunloco },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  /* -----------------------------States----------------------------- */

  const [transPortModes, setTransPortModes] = useState([]);
  const [carrierCodes, setCarrierCodes] = useState([]);
  const [movementType, setMovementType] = useState([]);
  const [dropDownloader, setDropDownLoader] = useState({
    origin: false,
    destination: false,
  });
  const [transport, setTransport] = useState({});
  const [cargoType, setCargoType] = useState({});
  const [carrier, setCarrier] = useState({});

  /* -----------------------------APIs----------------------------- */
  const getOrg = (search) => {
    let temp = { ...dropDownloader };
    temp.origin = true;
    setDropDownLoader(temp);
    let body = {
      CountryCode: "",
      Search: search ?? "",
    };

    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        dispatch(originstorepage(res?.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        let temp = { ...dropDownloader };
        temp.origin = false;
        setDropDownLoader(temp);

        if (location.pathname.includes("edit")) {
          // getDest();
        }
      });
  };
  const getDest = (search) => {
    let temp = { ...dropDownloader };
    temp.destination = true;
    setDropDownLoader(temp);
    let body = {
      CountryCode: "",
      Search: search ?? "",
    };

    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        dispatch(deststorepage(res?.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        let temp = { ...dropDownloader };
        temp.destination = false;
        setDropDownLoader(temp);
      });
  };
  const getTransportMode = () => {
    instance
      .get(`${API_PATH.GET_TRANSPORT_MODE}`)
      .then((res) => {
        // console.log(res.data);
        let result = res?.data?.filter((item) => item?.Code !== "FAS");
        // console.log(result);
        setTransPortModes(result);
        setTransport(() =>
          res?.data?.find((item, index) => {
            return item.Code === BookingHeader?.Transportmode;
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const getCarrierCodes = (val) => {
    instance
      .get(`${API_PATH.GET_BOOKING_CARRIER_CODES}${val}`)
      .then((res) => {
        // console.log(res.data);
        setCarrierCodes(res?.data);
        if (BookingHeader?.CarrierCode?.length > 0) {
          setCarrier(() => {
            // console.log("Called inside API", BookingHeader?.CarrierCode);
            return res?.data?.find((item, index) => {
              return item.Code === BookingHeader?.CarrierCode;
            });
          });
        } else if (BookingHeader?.CarrierName?.length > 0) {
          setCarrier({
            Code: BookingHeader?.CarrierName,
            FullName: BookingHeader?.CarrierName,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const getMovementType = (val) => {
    let body = { TransportMode: val };
    instance
      .post(`${API_PATH.GET_MOVEMENT_TYPE}`, body)
      .then((res) => {
        // console.log(res.data);
        setMovementType(res?.data);
        setCargoType(() =>
          res?.data?.find((item, index) => {
            return item.Code === BookingHeader?.Carotype;
          })
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (BookingHeader?.TransportMode !== "" && transPortModes.length > 0) {
      setTransport(() =>
        transPortModes?.find((item, index) => {
          return item.Code === BookingHeader?.TransportMode;
        })
      );
    }
    if (
      BookingHeader?.Carotype !== "" &&
      movementType.length > 0 &&
      !cargoType?.Code
    ) {
      setCargoType(() =>
        transPortModes?.find((item, index) => {
          return item.Code === BookingHeader?.Carotype;
        })
      );
    }
    if (
      BookingHeader?.CarrierCode !== "" &&
      carrierCodes.length > 0 &&
      !carrier?.Code
    ) {
      setCarrier(() => {
        // console.log("Called inside useEffect", BookingHeader?.CarrierCode);
        return transPortModes?.find((item, index) => {
          return item.Code === BookingHeader?.CarrierCode;
        });
      });
    }
  }, [BookingHeader, transPortModes]);

  /* -----------------------------Functions----------------------------- */

  const handleOriginDetails = (val) => {
    dispatch(originselectedpage(val));
  };
  const handleUpdateDetails = (name, value) => {
    dispatch(
      updateDetails({ objName: "BookingHeader", name: name, value: value })
    );
  };
  const handleCarrierDetails = (name, value) => {
    dispatch(
      updateDetails({ objName: "BookingHeader", name: name, value: value })
    );
  };
  useEffect(() => {
    getTransportMode();
  }, []);

  useEffect(() => {
    getCarrierCodes(BookingHeader?.TransportMode);
    getMovementType(BookingHeader?.TransportMode);
  }, [BookingHeader?.TransportMode, transportMode]);
  return (
    <div className="org-dest input-gap">
      <p>Details</p>
      <Autocomplete
        sx={{ width: "100%" }}
        size="small"
        freeSolo
        disablePortal
        onChange={(e, val) => {
          handleUpdateDetails("OriginPort", val?.Code);
          handleUpdateDetails("OriginPortName", val?.Name);
          handleOriginDetails(val);
        }}
        id="combo-box-demo"
        options={originunloco}
        value={originselectedunloco}
        getOptionLabel={(option) =>
          option?.Code ? `${option?.Name} (${option?.Code})` : ""
        }
        renderInput={(params) => (
          <TextField
            error={
              errorBooking.Booking.origin
                ? errorBooking.Booking.origin.error
                : false
            }
            helperText={
              errorBooking.Booking.origin
                ? errorBooking.Booking.origin.label
                : ""
            }
            {...params}
            placeholder="Start typing to search"
            label={dropDownloader.origin ? "Origin loading" : "Origin"}
            onChange={(e) => {
              if (e.target.value.length >= 3) {
                getOrg(e.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          maxDate={BookingHeader?.ETA}
          // valueDefault={null}
          label="ETD"
          value={BookingHeader?.ETD ?? null}
          inputFormat="DD/MM/YYYY"
          onChange={(e) => {
            handleUpdateDetails("ETD", e);
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              color="primary"
              // error={null}
              error={
                errorBooking.Booking.etd ? errorBooking.Booking.etd.error : null
              }
              helperText={
                errorBooking.Booking.etd ? errorBooking.Booking.etd.label : ""
              }
              defaultValue={""}
            />
          )}
        />
      </LocalizationProvider>
      <Autocomplete
        sx={{ width: "100%", paddingTop: "16px" }}
        freeSolo
        size="small"
        disablePortal
        id="combo-box-demo"
        onChange={(e, val) => {
          handleUpdateDetails("DestinationPort", val?.Code);
          handleUpdateDetails("DestinationPortName", val?.Name);
          dispatch(destselectedpage(val));
        }}
        options={destunloco}
        value={destselectedunloco}
        getOptionLabel={(option) =>
          option?.Code ? `${option?.Name} (${option?.Code})` : ""
        }
        renderInput={(params) => (
          <TextField
            error={
              errorBooking.Booking.destination
                ? errorBooking.Booking.destination.error
                : false
            }
            helperText={
              errorBooking.Booking.destination
                ? errorBooking.Booking.destination.label
                : ""
            }
            {...params}
            label={
              dropDownloader.destination ? "Destination loading" : "Destination"
            }
            placeholder="Start typing to search"
            onChange={(e) => {
              if (e.target.value.length >= 3) {
                getDest(e.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="ETA"
          minDate={BookingHeader?.ETD}
          // valueDefault={null}
          value={BookingHeader?.ETA ?? null}
          inputFormat="DD/MM/YYYY"
          onChange={(e) => {
            handleUpdateDetails("ETA", e);
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              color="primary"
              error={
                errorBooking.Booking.eta ? errorBooking.Booking.eta.error : null
              }
              helperText={
                errorBooking.Booking.eta ? errorBooking.Booking.eta.label : ""
              }
            />
          )}
        />
      </LocalizationProvider>
      <Autocomplete
        sx={{ width: "100%", paddingTop: "16px" }}
        freeSolo
        size="small"
        disabled={location.pathname.includes("edit") ? true : false}
        disablePortal
        id="combo-box-demo"
        onChange={(e, val) => {
          handleUpdateDetails("TransportMode", val?.Code);
          setCargoType({});
          setCarrier({});
        }}
        options={transPortModes}
        value={transport}
        getOptionLabel={(option) =>
          option?.Description ? `${option?.Description}` : ""
        }
        renderInput={(params) => (
          <TextField
            error={
              errorBooking.Booking.transportmode
                ? errorBooking.Booking.transportmode.error
                : null
            }
            helperText={
              errorBooking.Booking.transportmode
                ? errorBooking.Booking.transportmode.label
                : ""
            }
            {...params}
            label="Transport Mode"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      <Autocomplete
        sx={{ width: "100%" }}
        freeSolo
        size="small"
        disablePortal
        id="combo-box-demo"
        disabled={location.pathname.includes("edit") ? true : false}
        onChange={(e, val) => {
          handleUpdateDetails("Carotype", val?.Code);
        }}
        options={movementType}
        value={cargoType}
        getOptionLabel={(option) =>
          option?.Description ? `${option?.Description} (${option?.Code})` : ""
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={
              errorBooking.Booking.cargotype
                ? errorBooking.Booking.cargotype.error
                : null
            }
            helperText={
              errorBooking.Booking.cargotype
                ? errorBooking.Booking.cargotype.label
                : ""
            }
            label="Cargo Type"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      <Autocomplete
        sx={{ width: "100%" }}
        freeSolo
        size="small"
        // disablePortal
        // disabled={location.pathname.includes("edit") ? true : false}
        onChange={(e, val) => {
          handleUpdateDetails("CarrierName", val?.FullName);
          handleUpdateDetails("CarrierCode", val?.Code);
        }}
        options={carrierCodes}
        value={carrier}
        getOptionLabel={(option) =>
          option?.FullName ? `${option?.FullName}` : ""
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Preferred Carrier"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            onChange={(e) => {
              handleCarrierDetails("CarrierName", e.target.value);
            }}
          />
        )}
      />
      <TextField
        size="small"
        label="Description"
        value={BookingHeader?.GoodsDescription ?? ""}
        onChange={(e) => {
          handleUpdateDetails("GoodsDescription", e.target.value);
        }}
        variant="outlined"
        sx={{ width: "100%", marginBottom: "15px" }}
      />
      <div className="d-flex align-itmes-center ">
        <span>Is Hazardous?</span>{" "}
        <input
          type="checkbox"
          checked={BookingHeader?.IsHazardous}
          onChange={(e) => {
            handleUpdateDetails("IsHazardous", e.target.checked);
          }}
          style={{ marginLeft: "6.75rem" }}
        />
      </div>
      <div className="additional-services">
        <p>Additional Services</p>
        <div className="d-flex align-itmes-center ">
          <span>Insurance</span>{" "}
          <input
            type="checkbox"
            style={{ marginLeft: "8.75rem" }}
            checked={
              BookingHeader?.AdditionalServices?.length &&
              BookingHeader?.AdditionalServices[0]?.IsTrue
            }
            onChange={(e) => {
              dispatch(
                updateAdditionalServices({ index: 0, value: e.target.checked })
              );
            }}
          />
        </div>
        <div className="d-flex align-itmes-center ">
          <span>Custom Clearance</span>{" "}
          <input
            type="checkbox"
            style={{ marginLeft: "5rem" }}
            checked={
              BookingHeader?.AdditionalServices?.length &&
              BookingHeader?.AdditionalServices[1]?.IsTrue
            }
            onChange={(e) => {
              dispatch(
                updateAdditionalServices({ index: 1, value: e.target.checked })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrgDest;
const transportMode = [{ label: "FCL" }, { label: "LCL" }, { label: "AIR" }];
