import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, CircularProgress } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Files from "react-files";
import { basename } from "../../Config";
import "./doc.css";
import { useLocation } from "react-router-dom";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { toast } from "react-hot-toast";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import urlResolver from "../../utils/urlResolver";
import CustomTooltip from "../../utils/CustomTooltip";
export default function DocUploadShipmentSidebar({
  setDocUpload,
  docUpload,
  documentList,
}) {
  const location = useLocation();
  const [uploaddocument, setUploaddocument] = useState({
    Document: [],
    SHipmentId: location?.state?.ShipmentId ?? "",
    DocType: "",
    CustomerRep: "",
  });
  const [formerror, setFormerror] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docuploadoption, setDocuploadoption] = useState([]);
  const [croption, setCroption] = useState([]);
  const [fileserror, setFileserror] = useState();
  const onFilesChange = (files) => {
    setUploaddocument({ ...uploaddocument, Document: [...files] });
    setFileserror("");
  };
  const onFilesError = (error, file) => {
    setFileserror("error code " + error.code + ": " + error.message, "xyz");
  };
  const userName = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    getOptionsForDocType();
    getOptionsForCR();
  }, []);

  const getOptionsForDocType = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId ?? "",
    };
    instance
      .post(API_PATH.GET_OPTIONS_FOR_DOC_UPLOAD, body)
      .then((res) => {
        setDocuploadoption(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const getOptionsForCR = () => {
    instance
      .get(API_PATH.GET_CR)
      .then((res) => {
        setCroption(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const onClickUpload = (body) => {
    if (body.DocType.Type) {
      setLoading(true);
      const formData = new FormData();
      formData.append("Document", body.Document[0]);
      formData.append("SHipmentId", body.SHipmentId);
      formData.append("DocType", body.DocType.Type);
      formData.append("CustomerServiceEmailId", body.CustomerRep.EmailId);
      formData.append("CustomerName", body.CustomerRep.Name);
      formData.append("DocumentDescription", `${body.DocType.Description}`);
      formData.append("LoginUser", userName.Name);
      instance
        .post(API_PATH.UPLOAD_DOC_SHIPMENT, formData)
        .then((res) => {
          setLoading(false);
          toast.success(res?.data?.Message ?? "");
          if (res?.data?.Message.includes("Upload Successfully!")) {
            documentList();
          }
          setUploaddocument({
            Document: [],
            SHipmentId: location?.state?.ShipmentId ?? "",
            DocType: "",
          });
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err ?? "Something went wrong!");
        });
    } else {
      setFormerror(true);
    }
  };

  return (
    <Drawer
      anchor={"right"}
      width={360}
      open={docUpload}
      onClose={() => {
        setDocUpload(false);
        setUploaddocument({
          Document: [],
          SHipmentId: location?.state?.ShipmentId ?? "",
          DocType: "",
        });
      }}
    >
      <Box sx={{ width: 360 }}>
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col-12 ">
              <CloseOutlinedIcon
                onClick={() => {
                  setDocUpload(false);
                  setUploaddocument({
                    Document: [],
                    SHipmentId: location?.state?.ShipmentId ?? "",
                    DocType: "",
                  });
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <p className="mb-3 docuploadtext doctitle">
                Uploading a new documents{" "}
                <CustomTooltip
                  of="Shipment_Listing"
                  forKey="Uploading_document"
                />
              </p>
              <p
                className="font-shipment"
                style={{ color: "var(--color-primary-font)" }}
              >
                Type of document
              </p>
              <FormControl error={formerror} fullWidth>
                <InputLabel
                  className="label_input"
                  id="demo-simple-select-error-label"
                >
                  Select
                </InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  value={uploaddocument.DocType}
                  // label="Select document type"
                  label="age"
                  onChange={(e) => {
                    setFormerror(false);
                    setUploaddocument({
                      ...uploaddocument,
                      DocType: e.target.value,
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {docuploadoption.map((item, index) => {
                    return (
                      <MenuItem
                        value={item}
                      >{`${item.Description} [${item.Type}]`}</MenuItem>
                    );
                  })}
                </Select>
                {formerror ? (
                  <FormHelperText>Please select document type</FormHelperText>
                ) : null}
              </FormControl>
              <br />
              <p
                className="font-shipment"
                style={{ color: "var(--color-primary-font)" }}
              >
                Customer Representative
              </p>
              <FormControl error={formerror} fullWidth>
                <InputLabel
                  className="label_input"
                  id="demo-simple-select-error-label"
                >
                  Select
                </InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  value={uploaddocument.CustomerRep}
                  // label="Select document type"
                  label="age"
                  onChange={(e) => {
                    setFormerror(false);
                    setUploaddocument({
                      ...uploaddocument,
                      CustomerRep: e.target.value,
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {croption.map((item, index) => {
                    return (
                      <MenuItem
                        value={item}
                      >{`${item.Name} [${item.Code}]`}</MenuItem>
                    );
                  })}
                </Select>
                {formerror ? (
                  <FormHelperText>
                    Please select Customer representative.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </div>

            <div className="col-12 mb-3">
              {uploaddocument.Document.length ? (
                <>
                  <div className="docuploadfile mb-3 py-3 d-flex justify-content-center align-items-center flex-column">
                    <div>
                      <TextSnippetOutlinedIcon style={{ fontSize: "4rem" }} />
                    </div>
                    <p
                      style={{ fontSize: "1rem", textAlign: "center" }}
                      title={uploaddocument.Document[0].name}
                    >
                      {uploaddocument.Document[0].name}
                    </p>
                  </div>
                </>
              ) : (
                <Files
                  className="files-dropzone docuploadfile mb-3 py-3"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={[
                    "image/*",
                    ".pdf",
                    ".xls",
                    ".xlsx",
                    ".csv",
                    ".html",
                    ".eml",
                    ".msg",
                  ]}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  multiple={false}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src={urlResolver("assets/upload_icon.svg")}
                      style={{ width: "15%" }}
                    />
                  </div>

                  <p className="d-flex justify-content-center m-0 docuploadtext">
                    Drag and Drop Docs Here
                  </p>
                  <p className="d-flex justify-content-center m-0 docuploadtext">
                    OR
                  </p>
                  <p className="d-flex justify-content-center m-0 docuploadtext">
                    Click here to browse Docs
                  </p>
                </Files>
              )}
            </div>
            {uploaddocument.Document.length ? (
              <div className="col-12 mb-3">
                <Stack spacing={2} direction="row" className="buttonupload">
                  <Button
                    onClick={() => {
                      setUploaddocument({
                        Document: [],
                        SHipmentId: location?.state?.ShipmentId ?? "",
                        DocType: "",
                      });
                    }}
                    fullWidth
                    style={{ color: "var(--color-white)" }}
                  >
                    Clear
                  </Button>
                </Stack>
              </div>
            ) : null}
            <div className="col-12 mb-3">
              <Stack spacing={2} direction="row" className="buttons-container">
                <Button
                  onClick={() => {
                    if (uploaddocument.Document.length) {
                      onClickUpload(uploaddocument);
                    } else {
                      toast.error("Please add document");
                    }
                  }}
                  fullWidth
                  style={{ width: "100%" }}
                >
                  {loading ? (
                    <CircularProgress
                      size="2rem"
                      style={{ color: "var(--color-miscellaneous-4)" }}
                    />
                  ) : (
                    "Upload"
                  )}
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </Box>
    </Drawer>
  );
}
