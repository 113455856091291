import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import Login from "../screens/auth/login/Login";
import { Routeconstant } from "./utils/RouteConstant";
import { basename, LOCAL_STORAGE_KEY, marginRms } from "../Config";
import Shipment from "../screens/shipment";
import Order from "../screens/order/index";
import ShipmentDetails from "../screens/shipment/components/ShipmentDetails";
import Routing from "../screens/shipment/components/Routing";
import CargoDetails from "../screens/shipment/components/CargoDetails";
import Documentation from "../screens/shipment/components/Documentation";
import ShipmentAnalytics from "../screens/shipment/components/ShipmentAnalytics";
import Enquiry from "../screens/Enquiry";
import Statement from "../screens/statement";
import Users from "../screens/users";
import NoRoute from "../common/NoRoute";
import Nointernet from "../common/Nointernet";
import PrivateRoute from "./component/PrivateRoute";
import PublicRoute from "./component/PublicRoute";

import { AuthContext } from "../store/context/AuthProvider";
import Dashboard from "../screens/dashboard/Dashboard";
import { localData } from "../services/auth-header";
import { lazy, Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { Puff } from "react-loader-spinner";
import decryptData from "../decryptencrypt/decrypt.js";
import Booking from "../screens/booking/index.js";
import CreateBooking from "../screens/booking/create/index.js";
import ViewBooking from "../screens/booking/dashboard/ViewBooking.js";
import EditBooking from "../screens/booking/edit/index.js";
import UpdateForm from "../screens/publicscreens/UpdateForm.jsx";
import SecureRoute from "./component/SecureRoute.jsx";
// import AdminInformation from "../screens/users/component/AdminInformation.jsx";

// import ResetPassword from "../screens/auth/reset/Resetpassword";
// import OrderTrack from "../screens/order/orderDashboard/orderdetails/Routes/OrderTrack";
// import Reports from "../screens/order/reports";
// import SystemLogs from "../screens/order/logs/SystemLogs";
// import AdminSettings from "../screens/adminSettings/AdminSettings";
// import OrganizationMapping from "../screens/users/component/OrganizationMapping";
// import Enquirypreview from "../screens/Enquiry/Enquirypreview";
// import Enquirydashboard from "../screens/Enquiry/Enquirydashboard";
// import QuoteRatesMargin from "../screens/rmsMargn/components/QuoteRatesMargin";
// import CreateRatesMargin from "../screens/rmsMargn/components/CreateRatesMargin";
// import DocumentRmsMargin from "../screens/rmsMargn/components/DocumentRmsMargin";
// import RmsReportMargin from "../screens/rmsMargn/components/RmsReportMargin";
// import CargoRatesMargin from "../screens/rmsMargn/components/CargoRatesMargin";
// import CreateRmsListMargin from "../screens/rmsMargn/components/CreateRmsListMargin";
// import CreateRmsMargin from "../screens/rmsMargn/CreateRmsMargin";
// import RmsMargin from "../screens/rmsMargn/RmsMargin";
// import OrderDetails from "../screens/order/orderDashboard/orderdetails/OrderDetails";
// import QuoteRates from "../screens/rms/components/QuoteRates";
// import CreateRates from "../screens/rms/components/CreateRates";
// import OrderStatus from "../screens/order/orderDashboard/OrderStatus";
// import OrderDashboard from "../screens/order/orderDashboard/OrderDashboard";
// import RmsReport from "../screens/rms/components/RmsReport";
// import DocumentRms from "../screens/rms/components/DocumentRms";
// import CargoRates from "../screens/rms/components/CargoRates";
// import CreateRmsList from "../screens/rms/components/CreateRmsList";
// import Rms from "../screens/rms/Rms";
// import CreateRms from "../screens/rms/CreateRms";
// import ShipmentDetailsPublic from "../screens/publicscreens/ShipmentDetailsPublic";
// import ForgetPassword from "../screens/auth/forget/ForgetPassword.js";

const ResetPassword = lazy(() => import("../screens/auth/reset/Resetpassword"));

const ShipmentDetailsPublic = lazy(() =>
  import("../screens/publicscreens/ShipmentDetailsPublic")
);
const Rms = lazy(() => import("../screens/rms/Rms"));
const AdminInformation = lazy(() =>
  import("../screens/users/component/AdminInformation.jsx")
);

const CreateRms = lazy(() => import("../screens/rms/CreateRms"));
const CreateRmsList = lazy(() =>
  import("../screens/rms/components/CreateRmsList")
);
const CargoRates = lazy(() => import("../screens/rms/components/CargoRates"));
// const DocumentRms = lazy(() => import("../screens/rms/components/DocumentRms"));
const RmsReport = lazy(() => import("../screens/rms/components/RmsReport"));
const OrderDashboard = lazy(() =>
  import("../screens/order/orderDashboard/OrderDashboard")
);
const OrderStatus = lazy(() =>
  import("../screens/order/orderDashboard/OrderStatus")
);
const CreateRates = lazy(() => import("../screens/rms/components/CreateRates"));
const QuoteRates = lazy(() => import("../screens/rms/components/QuoteRates"));
const OrderDetails = lazy(() =>
  import("../screens/order/orderDashboard/orderdetails/OrderDetails")
);

const RmsMargin = lazy(() => import("../screens/rmsMargn/RmsMargin"));
const CreateRmsMargin = lazy(() =>
  import("../screens/rmsMargn/CreateRmsMargin")
);
const CreateRmsListMargin = lazy(() =>
  import("../screens/rmsMargn/components/CreateRmsListMargin")
);
const CargoRatesMargin = lazy(() =>
  import("../screens/rmsMargn/components/CargoRatesMargin")
);
// const DocumentRmsMargin = lazy(() =>
//   import("../screens/rmsMargn/components/DocumentRmsMargin")
// );
const RmsReportMargin = lazy(() =>
  import("../screens/rmsMargn/components/RmsReportMargin")
);
const CreateRatesMargin = lazy(() =>
  import("../screens/rmsMargn/components/CreateRatesMargin")
);
const QuoteRatesMargin = lazy(() =>
  import("../screens/rmsMargn/components/QuoteRatesMargin")
);
const Enquirydashboard = lazy(() =>
  import("../screens/Enquiry/Enquirydashboard")
);
const Enquirypreview = lazy(() => import("../screens/Enquiry/Enquirypreview"));

const OrderTrack = lazy(() =>
  import("../screens/order/orderDashboard/orderdetails/Routes/OrderTrack")
);
const Reports = lazy(() => import("../screens/order/reports"));
const SystemLogs = lazy(() => import("../screens/order/logs/SystemLogs"));
const AdminSettings = lazy(() =>
  import("../screens/adminSettings/AdminSettings")
);
const OrganizationMapping = lazy(() =>
  import("../screens/users/component/OrganizationMapping")
);

const ForgetPassword = lazy(() =>
  import("../screens/auth/forget/ForgetPassword.js")
);
const Track = lazy(() =>
  import("../screens/auth/login/trackyourshipment/Track.js")
);
const Schedule = lazy(() => import("../screens/schedule/index.js"));
const ScheduleSearchResult = lazy(() =>
  import("../screens/schedule/ScheduleSearchResult.js")
);

const PublicEnquiry = lazy(() => import("../screens/publicEnquiry"));
const Navigation = () => {
  const context = useContext(AuthContext);
  const [online, setOnline] = useState(window.navigator.onLine);
  // const [ModuleAccessibility, setModuleAccessibility] = useState(
  //   decryptData("moduleAccessibility")
  // );
  const { ModuleAccessibility, setModuleAccessibility } =
    useContext(AuthContext);
  useEffect(() => {
    window.addEventListener("offline", () => setOnline(false));
    window.addEventListener("online", () => setOnline(true));
  }, []);
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  function Loading() {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        {/* <CircularProgress color="inherit" /> */}
        <Puff
          height="80"
          width="80"
          radius={1}
          color="#0a2d44"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  function lazySuspense(child) {
    return <Suspense fallback={<Loading />}>{child}</Suspense>;
  }
  return (
    <BrowserRouter basename={basename}>
      {online ? (
        <Routes>
          {/* log in route */}
          <Route
            path={Routeconstant.LOGIN}
            element={
              <PublicRoute>
                <Login setModuleAccessibility={setModuleAccessibility} />
              </PublicRoute>
            }
          />
          {/* update form */}
          <Route
            path={Routeconstant.UPDATE_FORM}
            element={
              <SecureRoute>
                <UpdateForm />
              </SecureRoute>
            }
          />
          {/*Public Shipment Details from track your shipment*/}
          <Route
            path={Routeconstant.SHIPMENTDETAILS}
            element={
              <PublicRoute>
                {lazySuspense(<ShipmentDetailsPublic />)}
              </PublicRoute>
            }
          />
          <Route
            path={Routeconstant.TRACKYOURSHIPMENT}
            element={<PublicRoute>{lazySuspense(<Track />)}</PublicRoute>}
          />

          {/* reset password */}
          <Route
            path={Routeconstant.RESETPASSWORD}
            element={
              <PublicRoute>{lazySuspense(<ResetPassword />)}</PublicRoute>
            }
          />

          {/* forget password */}
          <Route
            path={Routeconstant.FORGETPASSWORD}
            element={
              <PublicRoute>{lazySuspense(<ForgetPassword />)}</PublicRoute>
            }
          />
          <Route
            path={Routeconstant.PUBLICENQUIRY}
            element={
              <PublicRoute>{lazySuspense(<PublicEnquiry />)}</PublicRoute>
            }
          />
          {/* main layout includes all */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            {/* dashboard route */}
            <Route path={Routeconstant.LANDING_PAGES} element={<Dashboard />} />
            {/* Booking */}
            {ModuleAccessibility?.BookingModule == 1 && (
              <>
                <Route
                  path={Routeconstant.BOOKING_DASHBOARD}
                  element={<Booking />}
                />
                <Route
                  path={Routeconstant.BOOKING_VIEW}
                  element={<ViewBooking />}
                />
                <Route
                  path={Routeconstant.BOOKING_CREATE}
                  element={<CreateBooking />}
                />
                <Route
                  path={Routeconstant.BOOKING_EDIT}
                  element={<EditBooking />}
                />
              </>
            )}
            {/* shipment listing */}
            {/* {ModuleAccessibility?.Shipment == 1 && (
              <> */}
            <Route path={Routeconstant.SHIPMENT_LISTING} element={<Shipment />}>
              <Route
                path={Routeconstant.DETAILS}
                // element={<ShipmentDetails />}
                element={lazySuspense(<ShipmentDetails />)}
              />
              <Route
                path={Routeconstant.ROUTING}
                //  element={<Routing />}
                element={lazySuspense(<Routing />)}
              />
              <Route
                path={Routeconstant.CARGO_DETAILS}
                // element={<CargoDetails />}
                element={lazySuspense(<CargoDetails />)}
              />
              <Route
                path={Routeconstant.DOCUMENTATION}
                // element={<Documentation />}
                element={lazySuspense(<Documentation />)}
              />
            </Route>
            {/* </> */}
            {/* )} */}

            {/* Shipment Analytics */}
            <Route
              path={Routeconstant.SHIPMENT_ANALYTICS}
              // element={<ShipmentAnalytics />}
              element={lazySuspense(<ShipmentAnalytics />)}
            ></Route>

            {/* Order listing */}
            {/* <Route path={Routeconstant.ORDER_LISTING} element={<Order />}>
              <Route
                path={Routeconstant.ORDER_DETAILS}
                element={<OrderDetails />}
              />
              <Route
                path={Routeconstant.ORDER_ROUTING}
                element={<OrderRouting />}
              />
              <Route
                path={Routeconstant.ORDER_CARGO_DETAILS}
                element={<OrderCargoDetails />}
              />
              <Route
                path={Routeconstant.ORDER_DOCUMENTATION}
                element={<OrderDocumentation />}
              />
            </Route> */}

            {/* Order Analyics */}
            {/* <Route
              path={Routeconstant.ORDER_ANALYTICS}
              element={
                <PrivateRoute>
                  <OrderAnalytics />
                </PrivateRoute>
              }
            ></Route> */}

            {/* Enquiry */}
            {/* {ModuleAccessibility?.GeneralEnquiry == 1 && (
              <> */}
            <Route
              path={Routeconstant.ENQUIRY}
              element={<PrivateRoute>{lazySuspense(<Enquiry />)}</PrivateRoute>}
            ></Route>
            <Route
              path={Routeconstant.ENQUIRYDASHBOARD}
              element={
                <PrivateRoute>
                  {lazySuspense(<Enquirydashboard />)}
                </PrivateRoute>
              }
            ></Route>
            <Route
              path={Routeconstant.ENQUIRYPREVIEW}
              element={
                <PrivateRoute>{lazySuspense(<Enquirypreview />)}</PrivateRoute>
              }
            ></Route>
            {/* </>
            )} */}

            {/* RMS */}
            {ModuleAccessibility?.Rms == 1 && (
              <>
                {marginRms ? (
                  <>
                    <Route
                      path={Routeconstant.RMS}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<RmsMargin />)}
                        </PrivateRoute>
                      }
                    />
                    {/* CREATE-RMS */}
                    <Route
                      path={Routeconstant.CREATERMS}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CreateRmsMargin />)}
                        </PrivateRoute>
                      }
                    />
                    {/* CREATELIST-RMS */}
                    <Route
                      path={Routeconstant.CREATERMSLIST}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CreateRmsListMargin />)}
                        </PrivateRoute>
                      }
                    />
                    {/* CARGORATES-RMS */}
                    <Route
                      path={Routeconstant.CARGORATES}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CargoRatesMargin />)}
                        </PrivateRoute>
                      }
                    />
                    {/* Document-RMS */}
                    {/* <Route
                  path={Routeconstant.DOCUMENTRMS}
                  element={
                    <PrivateRoute>
                      {lazySuspense(<DocumentRmsMargin />)}
                    </PrivateRoute>
                  }
                /> */}
                    {/* Report-RMS */}
                    <Route
                      path={Routeconstant.RMSREPORT}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<RmsReportMargin />)}
                        </PrivateRoute>
                      }
                    />
                    {/* Create-Rates */}
                    <Route
                      path={Routeconstant.CREATERATES}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CreateRatesMargin />)}
                        </PrivateRoute>
                      }
                    />
                    {/* Create-Quotes */}
                    <Route
                      path={Routeconstant.QUOTESRATES}
                      element={
                        // <PrivateRoute>
                        lazySuspense(<QuoteRatesMargin />)
                        // </PrivateRoute>
                      }
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path={Routeconstant.RMS}
                      element={
                        <PrivateRoute>{lazySuspense(<Rms />)}</PrivateRoute>
                      }
                    />
                    {/* CREATE-RMS */}
                    <Route
                      path={Routeconstant.CREATERMS}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CreateRms />)}
                        </PrivateRoute>
                      }
                    />
                    {/* CREATELIST-RMS */}
                    <Route
                      path={Routeconstant.CREATERMSLIST}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CreateRmsList />)}
                        </PrivateRoute>
                      }
                    />
                    {/* CARGORATES-RMS */}
                    <Route
                      path={Routeconstant.CARGORATES}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CargoRates />)}
                        </PrivateRoute>
                      }
                    />

                    {/* Report-RMS */}
                    <Route
                      path={Routeconstant.RMSREPORT}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<RmsReport />)}
                        </PrivateRoute>
                      }
                    />
                    {/* Create-Rates */}
                    <Route
                      path={Routeconstant.CREATERATES}
                      element={
                        <PrivateRoute>
                          {lazySuspense(<CreateRates />)}
                        </PrivateRoute>
                      }
                    />
                    {/* Create-Quotes */}
                    <Route
                      path={Routeconstant.QUOTESRATES}
                      element={
                        // <PrivateRoute>
                        lazySuspense(<QuoteRates />)
                        // </PrivateRoute>
                      }
                    />
                  </>
                )}
              </>
            )}

            {/* Statement of Account */}

            {/* {ModuleAccessibility?.Finance == 1 && (
              <> */}
            <Route
              path={Routeconstant.STATEMENT}
              element={lazySuspense(<Statement />)}
            ></Route>
            {/* </>
            )} */}

            {ModuleAccessibility?.PurchageOrder == 1 && (
              <>
                <Route
                  path={Routeconstant.CREATE_ORDER}
                  element={lazySuspense(<Order />)}
                ></Route>
                <Route
                  path={Routeconstant.ORDER_DASHBOARD}
                  element={lazySuspense(<OrderDashboard />)}
                ></Route>
                <Route
                  path={Routeconstant.ORDER_STATUS}
                  element={lazySuspense(<OrderStatus />)}
                ></Route>
                <Route
                  path={Routeconstant.ORDER_DETAILS}
                  element={lazySuspense(<OrderDetails />)}
                ></Route>
                <Route
                  path={Routeconstant.ORDER_REPORTS}
                  element={lazySuspense(<Reports />)}
                />
                <Route
                  path={Routeconstant.ORDER_TRACK}
                  element={lazySuspense(<OrderTrack />)}
                ></Route>
                <Route
                  path={Routeconstant.SYSTEM_LOGS}
                  element={lazySuspense(<SystemLogs />)}
                ></Route>
              </>
            )}

            {/*admin settings */}
            <Route
              path={Routeconstant.ADMIN_SETTINGS}
              element={lazySuspense(<AdminSettings />)}
            ></Route>

            {/* user logs */}
            {storedUserData?.Role?.includes("Admin") && (
              <Route
                path={Routeconstant.USER_LOGS}
                element={lazySuspense(<Users />)}
              ></Route>
            )}
            {storedUserData?.Role?.includes("Admin") && (
              <>
                <Route
                  path={Routeconstant.USER_Orgination_mapping}
                  element={lazySuspense(<OrganizationMapping />)}
                ></Route>
                <Route
                  path={Routeconstant.INFORMATION_ADMIN}
                  element={lazySuspense(<AdminInformation />)}
                />
              </>
            )}
            {/* Schedule */}
            {ModuleAccessibility?.ScheduleModule == 1 && (
              <>
                <Route
                  path={Routeconstant.SCHEDULE}
                  element={lazySuspense(<Schedule />)}
                />
                <Route
                  path={Routeconstant.SEARCH_RESULT}
                  element={lazySuspense(<ScheduleSearchResult />)}
                />
              </>
            )}
          </Route>

          {/* No route */}
          <Route path="*" element={lazySuspense(<NoRoute />)} />
        </Routes>
      ) : (
        <Nointernet />
      )}
    </BrowserRouter>
  );
};

export default Navigation;
