import { customerName } from "../Config";
let permission = {
  financesAccess: true,
  whatsAppIcon: true,
  exportToExcel: true,
  rms: {
    dashboard: {
      getrates: true,
    },
  },
};

const accessGiven = () => {
  switch (customerName) {
    case "SFLInsight":
      return permission;
    case "Brokeman":
      permission.financesAccess = false;
      return permission;
    case "Ingram Logistics":
      permission.whatsAppIcon = false;
      return permission;
    default:
      return permission;
  }
};
export default accessGiven;
