import Sidebar from "./components/Sidebar";
import Details from "./Details";
import Footer from "./Footer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetErrorState } from "../../../redux/bookingRedux/bookingErrorSlice";
const Layout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetErrorState());
    };
  }, []);
  return (
    <div className="layout-top">
      <div className="layout-cover">
        {/* Sidebar */}
        <aside className="layout-sidebar">
          <Sidebar />
        </aside>
        {/* Main */}
        <main className="layout-main layout-padding">
          <Details />
          {/* Footer */}
          <footer className="footer-layout">
            <Footer />
          </footer>
        </main>
      </div>
    </div>
  );
};
export default Layout;
