import { useDispatch } from "react-redux"
import "./index.css"
import Layout from "./Layout"
import { useEffect } from "react"
import { clearBasicDetails } from "../../../redux/bookingRedux/bookingBasicDetailSlice"
import { clearCreateBooking } from "../../../redux/bookingRedux/createBookingSlice"
const CreateBooking = () => {
    const dispatch = useDispatch()
    // to clear all the redux states
    useEffect(() => {
        return (() => {
            dispatch(clearBasicDetails())
            dispatch(clearCreateBooking())
        })
    }, [])
    return <Layout />
}
export default CreateBooking