import { Grid } from "@mui/material";
import OrderSpecifics from "./orderspecifics/OrderSpecifics";
import { useDispatch, useSelector } from "react-redux";
import LineDetails from "./linedetails/LineDetails";
import OrderPlan from "./orderplan/OrderPlan";
import Edoc from "./edoc/Edoc";
import Confirmation from "./confirmation/Confirmation";
import { useLocation } from "react-router";
import { updatePermissionData } from "../../../redux/orderRedux/orderPermission";
import getpermision from "../Permission/permison";
import { useEffect } from "react";

const CreateOrderLayout = () => {
  const header = useSelector((state) => state.header);
  const order = useSelector((state) => state.order);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { permisisonOrder } = useSelector((state) => state.orderPermission);
  const arrivingFrom = state?.location === "editButton"; //this true means we land on this page from edit button click
  // console.log(arrivingFrom === "editButton", "statestatestate")

  // in case use reload the page this work to make sure that permission works in order management
  const getPermissionData = async () => {
    const data = await getpermision();
    dispatch(updatePermissionData({ ...data }));
  };
  useEffect(() => {
    if (Object.keys(permisisonOrder).length === 0) {
      getPermissionData();
    }
  }, []);
  return (
    <Grid container className="overlayPadding">
      {header.currentStepper === 0 && (
        <OrderSpecifics
          EditPermission={
            arrivingFrom ? permisisonOrder?.EditOrderDetail : true
          }
        />
      )}
      {header.currentStepper === 1 && (
        <LineDetails
          EditPermission={arrivingFrom ? permisisonOrder?.EditOrderLine : true}
        />
      )}
      {header.currentStepper === 2 && (
        <OrderPlan
          EditPermission={arrivingFrom ? permisisonOrder?.EditOrderPlan : true}
        />
      )}
      {header.currentStepper === 3 && (
        <Edoc
          EditPermission={
            arrivingFrom ? permisisonOrder?.EditOrderDocument : true
          }
        />
      )}
      {header.currentStepper === 4 && <Confirmation />}
    </Grid>
  );
};
export default CreateOrderLayout;
