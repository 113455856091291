import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import LinetableHeader from "./LinetableHeader";
import LineTableBody from "./LineTableBody";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { addLine } from "../../../../redux/orderRedux/createOrder/linedetailsSlice";
const LineDetails = ({ EditPermission }) => {
  const { linedetails } = useSelector((state) => state);
  const dispatch = useDispatch();
  const addNewRow = () => {
    let index = linedetails?.length;
    dispatch(addLine(index));
  };
  const { permisisonOrder } = useSelector((state) => state.orderPermission);
  // console.log(EditPermission,"EditPermissionEditPermission")
  return (
    <div
      style={{
        height: "64vh",
        width: "100%",
        borderRadius: "12px",
        padding: "1rem 1rem 0 1rem",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      }}
      // className="overCard"
    >
      <div
        style={{
          display: "flex",
          // width: "100%",
          justifyContent: "space-between",
          // padding: "0% 5% 0% 5%",
        }}
      >
        <Typography variant="overline" color="primary">
          Order Line
        </Typography>
        <Tooltip title="Add new row">
          <IconButton
            disabled={
              EditPermission
                ? permisisonOrder?.CreateOrderLine
                  ? false
                  : true
                : true
            }
            color="primary"
            onClick={() => {
              addNewRow();
            }}
          >
            <AddCircleOutlineSharpIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <table
          style={{
            // to add scroll in table
            display: "block",
            overflow: " auto",
            height: "58vh",
            whiteSpace: "nowrap",
            padding: "0",
            fontFamily: "var(--family-label)",
          }}
        >
          <LinetableHeader />
          <LineTableBody EditPermission={EditPermission} />
        </table>
      </div>
    </div>
  );
};
export default LineDetails;
