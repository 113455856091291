import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";

const Left = () => {
  const {
    order: { orderNumber, supplier, buyer, orderDetails },
  } = useSelector((state) => state);
  let NA = "N/A";

  const formatDate = (date) => {
    return moment(new Date(date)).format("D MMMM  YYYY");
  };
  return (
    <Grid
      container
      spacing={1}
      sx={{
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Order Number </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {`${orderNumber?.orderNumber}`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>CW1 Order Number </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {`${orderNumber?.cw1OrderNumber}`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Sending Agent </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          :{" "}
          {orderNumber?.sendingAgent?.Code
            ? `${orderNumber?.sendingAgent?.Code}`
            : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Supplier Name </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          :{" "}
          {supplier?.supplierName?.Name
            ? `${supplier?.supplierName?.Name}`
            : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Buyer Name </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {`${buyer?.buyerName?.Name ?? buyer?.buyerName?.Code}`}
        </Typography>
      </Grid>
      {/* <Grid item lg={5}>
        <Typography color={"primary"}>Final Delivery Location </Typography>
      </Grid>
      <Grid item lg={7}>
        <Typography color={"secondary"}>
          : {`${buyer?.buyerName?.finalDeliveryLocation}`}
        </Typography>
      </Grid> */}
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Currency Of Payment </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          {" "}
          :{" "}
          {`${
            orderDetails?.currencyOfPayment?.Description ??
            orderDetails?.currencyOfPayment?.Code
          }`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Container Mode </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          {" "}
          :{" "}
          {`${
            orderDetails?.containerMode?.Description ??
            orderDetails?.containerMode?.Code
          }`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Req. Ex. Works </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {orderDetails?.reqWork ? formatDate(orderDetails?.reqWork) : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Service Level </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          :{" "}
          {orderDetails?.serviceLevel?.Description
            ? `${orderDetails?.serviceLevel?.Description}`
            : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Invoice No.</Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {`${orderDetails?.invoiceNumber}`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Goods Description</Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {`${orderDetails?.goodsDescription}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default Left;
