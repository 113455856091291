import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Left from "./Left";
import Right from "./Right";
const Confirmation = () => {
  return (
    <Grid
      container
      columnSpacing={{ sm: 2, xs: 0 }}
      style={{
        minHeight: "54vh",
        width: "100%",
      }}
      className="overCard"
    >
      <Grid item xs={12}>
        <Typography variant="overline" color={"primary"}>
          Confirmation
        </Typography>
      </Grid>
      <Grid item lg={6} md={12}>
        <Left />
      </Grid>
      <Grid item lg={6} md={12}>
        <Right />
      </Grid>
    </Grid>
  );
};
export default Confirmation;
