import { useSelector } from "react-redux";
import UserType from "./components/UserType";
import CargoDetails from "./components/CargoDetails";
import DocumentUpload from "./components/DocumentUpload";

const Details = () => {
  const {
    createBooking: { sidebar },
  } = useSelector((state) => state);
  return (
    <>
      {sidebar.selected === 0 && <UserType />}
      {sidebar.selected === 1 && <CargoDetails />}
      {sidebar.selected === 2 && <DocumentUpload />}
    </>
  );
};
export default Details;
