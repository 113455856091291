import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { basename } from "../../../Config";
import "../shipment.css";
import { AuthContext } from "../../../store/context/AuthProvider";
import { DateFormat } from "../../../utils/moment";
import BtnOutline from "../../../components/button/BtnOutline";
import { Box, Button, CircularProgress, Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { toast } from "react-hot-toast";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import urlResolver from "../../../utils/urlResolver";
import { useState } from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import TableColumnModal from "../../../components/model/TableColumnModal";
import { TableColumns } from "../../../utils/TableColumns";
import CustomTooltip from "../../../utils/CustomTooltip";
const ShipmentListTable = ({
  sortColumnCopy,
  getShipmentCollection,
  currentPage,
  setCurrentPage,
  setLoading,
  loading,
  totalCount,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  setStatus,
  loadingOnSearch,
  setTableCols,
  tableCols,
  updateTableCols,
}) => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [resetCopy, setResetCopy] = useState(structuredClone(TableColumns));
  const [tableCopy, setTableCopy] = useState(structuredClone(tableCols));
  const [tableModal, setTableModal] = useState(false);
  const markFavourite = (shipmentId, isFavourite) => {
    instance
      .post(
        `${API_PATH.MARK_FAVOURITE}ShipmentId=${shipmentId}&IsFavourite=${isFavourite}`
      )
      .then((res) => {
        toast.success(res?.message?.Message ?? "");
        getShipmentCollection(0, "");
      })
      .catch((err) => {
        toast.success(err ?? "");
      });
  };
  const handleClickOpen = () => {
    setTableModal(true);
  };

  function getkey(key) {
    const SortNameObj = {
      ShipmentId: "JS_ShipmentId",
      OrderRef: "JS_OrderRef",
      ConsigneeName: "JS_ConsigneeName",
      ConsignorName: "JS_ConsignorName",
      OriginPortName: "JS_OriginPortName",
      DestinationPortName: "JS_DestinationPortName",
      ETADate: "JS_ETADate",
      ETDDate: "JS_ETDDate",
    };
    return SortNameObj[key];
  }
  return (
    <>
      <div className="tableWrap">
        <table className="table">
          <thead style={{ fontFamily: "var(--family-label)" }}>
            <tr>
              <th
                scope="col"
                className="font-table"
                style={{ textAlign: "center" }}
              >
                <div className="d-flex">
                  <div style={{ marginLeft: "-5px" }}>
                    <TableColumnModal
                      updateTableCols={updateTableCols}
                      tableModal={tableModal}
                      setTableModal={setTableModal}
                      tableCopy={tableCopy}
                      setTableCopy={setTableCopy}
                    />
                    <EditNoteIcon
                      onClick={handleClickOpen}
                      style={{ cursor: "pointer" }}
                    />
                    <CustomTooltip
                      of="Shipment_Listing"
                      forKey="Customize_columns"
                    />
                  </div>
                  <div style={{ marginLeft: "31px" }}>Favourite</div>
                </div>
              </th>
              {tableCopy.map((item) => {
                if (item?.IsVisible) {
                  if (
                    item?.DataKey === "ShipmentId" ||
                    item?.DataKey === "OrderRef" ||
                    item?.DataKey === "ConsigneeName" ||
                    item?.DataKey === "ConsignorName" ||
                    item?.DataKey === "OriginPortName" ||
                    item?.DataKey === "DestinationPortName" ||
                    item?.DataKey === "ETADate" ||
                    item?.DataKey === "ETDDate"
                  ) {
                    const key = getkey(item?.DataKey);
                    return (
                      <th scope="col" className="font-table">
                        {item?.ColumnName}
                        {sortColumn !== key ? (
                          <ImportExportIcon
                            onClick={() => {
                              sortColumnCopy.current = {
                                columnName: key,
                                columndir: "Asc",
                              };
                              setSortColumn(key);
                              setSortDirection("Asc");
                              setStatus("");
                              getShipmentCollection(0, "");
                            }}
                          />
                        ) : (
                          <>
                            {sortDirection === "Asc" ? (
                              <ArrowDownwardIcon
                                onClick={() => {
                                  sortColumnCopy.current = {
                                    columnName: key,
                                    columndir: "Desc",
                                  };
                                  setSortDirection("Desc");
                                  setStatus("");
                                  getShipmentCollection(0, "");
                                }}
                              />
                            ) : (
                              <ArrowUpwardIcon
                                onClick={() => {
                                  sortColumnCopy.current = {
                                    columnName: key,
                                    columndir: "Asc",
                                  };
                                  setSortDirection("Asc");
                                  setStatus("");
                                  getShipmentCollection(0, "");
                                }}
                              />
                            )}
                          </>
                        )}
                      </th>
                    );
                  }
                  return (
                    <th scope="col" className="font-table">
                      {item?.ColumnName}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          {loading.initial || loadingOnSearch ? null : (
            <tbody>
              {context.shipmentCollection.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className="d-flex justify-content-center align-items-center h-100 w-100"
                        style={{ cursor: "pointer" }}
                      >
                        {item.IsFavourite ? (
                          <img
                            style={{ marginLeft: "62px" }}
                            src={urlResolver("assets/coloredStar.svg")}
                            onClick={() =>
                              markFavourite(item.ShipmentId, false)
                            }
                          />
                        ) : (
                          <img
                            style={{ marginLeft: "62px" }}
                            src={urlResolver("assets/star.svg")}
                            onClick={() => markFavourite(item.ShipmentId, true)}
                          />
                        )}
                      </div>
                    </td>
                    {tableCopy.map((row) => {
                      if (row?.IsVisible) {
                        if (row?.DataKey === "ShipmentId") {
                          return (
                            <td
                              className={
                                row?.DataKey === "ShipmentId"
                                  ? "color-back ellipsis"
                                  : "ellipsis"
                              }
                              title={item?.ShipmentId ?? "N/A"}
                              onClick={() => {
                                context.setShipmentId(item.ShipmentId);
                                navigate(Routeconstant.DETAILS, {
                                  state: {
                                    ShipmentId: item[row.DataKey],
                                    ETA: item.ETADate,
                                    ETD: item.ETDDate,
                                    ORIGIN: item.OriginPortName,
                                    DESTINATION: item.DestinationPortName,
                                    data: item,
                                    TransportMode: item.TransportMode,
                                    HouseBillNumber: item.HouseBillNumber,
                                  },
                                });
                              }}
                            >
                              <span>{item[row.DataKey] ?? null}</span>
                            </td>
                          );
                        } else if (row?.DataKey === "ETADate") {
                          return (
                            <td
                              className="ellipsis font-shipment"
                              // title={row?.ShipmentId ?? "N/A"}
                            >
                              <span> {DateFormat(item?.ETADate) ?? ""}</span>
                            </td>
                          );
                        } else if (row?.DataKey === "ETDDate") {
                          return (
                            <td
                              className="ellipsis font-shipment"
                              // title={row?.ShipmentId ?? "N/A"}
                            >
                              <span> {DateFormat(item?.ETDDate) ?? ""}</span>
                            </td>
                          );
                        } else if (
                          row?.DataKey === "MasterBillNumber" ||
                          row?.DataKey === "ContainerNumbers"
                        ) {
                          return (
                            <td
                              className="ellipsis font-shipment"
                              // title={row?.ShipmentId ?? "N/A"}
                            >
                              <span>
                                {item[row.DataKey] && item[row.DataKey].length
                                  ? item[row.DataKey][0] ?? null
                                  : ""}
                              </span>
                            </td>
                          );
                        }
                        return (
                          <td
                            className="ellipsis font-shipment"
                            // title={row?.ShipmentId ?? "N/A"}
                          >
                            <span>
                              {item[row.DataKey] ?? null}
                              {item[row.DataKey] && row?.AltKey
                                ? item[row.AltKey]
                                : ""}
                            </span>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading.initial || loadingOnSearch ? (
          <div className="skeleton-div">
            <Skeleton style={{ height: "47rem" }} />
          </div>
        ) : null}
        {context.shipmentCollection.length === 0 && (
          <div style={{ textAlign: "center" }}>No Data Found</div>
        )}
      </div>

      <div className="mt-2">
        {context.shipmentCollection.length !== totalCount ? (
          <BtnOutline
            title={
              loading.loadmore ? (
                <CircularProgress
                  size="1rem"
                  style={{ color: "var(--color-miscellaneous-4)" }}
                />
              ) : (
                "Show More"
              )
            }
            onClick={() => {
              const pagenumber = currentPage + 1;
              getShipmentCollection(pagenumber, "");
              setCurrentPage(pagenumber);
              context.setListSize(pagenumber);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default ShipmentListTable;
