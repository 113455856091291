import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSelector } from "react-redux";

const steps = [
  "Order Specifics",
  "Line Details",
  "Order Plan",
  "E-Doc Upload",
  "Confirmation",
];

export default function Header() {
  const activeStep = useSelector((state) => state.header.currentStepper);
  const state = useSelector((state) => state);
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step className="font-change" key={label}>
            <StepLabel className="font-change">
              <p style={{ fontSize: "var(--fs-mid)" }}>{label}</p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
