import { useSelector } from "react-redux";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import "../../order.css";
import {
  deleteLine,
  updateLineDetails,
} from "../../../../redux/orderRedux/createOrder/linedetailsSlice";
import {
  getPackageType,
  getWeightUnit,
  getVolumeUnit,
  getDimensionsUnit,
  getIncoTerm,
} from "../apiCalls/get/getSelectList";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateInputState } from "../../../../redux/orderRedux/createOrder/inputSlice";
const LineTableBody = ({ EditPermission }) => {
  const {
    orderSpecificError: { errors },
    linedetails,
    input,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log(errors, "hgchg");
  const hideClass = {
    // border: "none",
    background: "transparent",
  };
  const handleInputChange = (index, name, value) => {
    dispatch(updateLineDetails({ index, name, value }));
  };
  const handleDelete = (index) => {
    dispatch(deleteLine(index));
  };
  const getDimensionsUnitList = async () => {
    const getDimensionsUnitListPromise = getDimensionsUnit();
    const dataOfDimensionsUnitList = await getDimensionsUnitListPromise;
    dispatch(
      updateInputState({
        name: "dimensionsUnitList",
        data: dataOfDimensionsUnitList,
      })
    );
  };

  const getVolumeUnitList = async () => {
    const getVolumeUnitListPromise = getVolumeUnit();
    const dataOfVolumeUnitList = await getVolumeUnitListPromise;
    dispatch(
      updateInputState({
        name: "volumeUnitList",
        data: dataOfVolumeUnitList,
      })
    );
  };

  const getWeightUnitList = async () => {
    const getWeightUnitListPromise = getWeightUnit();
    const dataOfWeightUnitList = await getWeightUnitListPromise;
    dispatch(
      updateInputState({
        name: "weightUnitList",
        data: dataOfWeightUnitList,
      })
    );
  };

  const getPackageTypeList = async () => {
    const getPackageTypeListPromise = getPackageType();
    const dataOfPackageList = await getPackageTypeListPromise;
    dispatch(
      updateInputState({
        name: "packageList",
        data: dataOfPackageList,
      })
    );
  };
  const getIncoTermList = async () => {
    const getIncoTermListPromise = getIncoTerm();
    const dataOfIncoTermList = await getIncoTermListPromise;
    dispatch(
      updateInputState({
        name: "incoTerm",
        data: dataOfIncoTermList,
      })
    );
  };

  useEffect(() => {
    getPackageTypeList();
    getWeightUnitList();
    getVolumeUnitList();
    getDimensionsUnitList();
    getIncoTermList();
  }, []);
  const { permisisonOrder } = useSelector((state) => state.orderPermission);
  // console.log(permisisonOrder,"permisionfoahsfdhsd")
  return (
    <tbody>
      {linedetails.map((item, index) => {
        return (
          <tr>
            <td>{item.lineNo + 1}</td>
            <td>
              {/* <TextareaAutosize
                style={hideClass}
                name="itemNumber"
                value={linedetails[index]?.itemNo}
                onChange={(e) =>
                  handleInputChange(index, "itemNo", e.target.value)
                }
                error={errors?.hasOwnProperty("itemNo")}
                helperText={
                  errors?.hasOwnProperty("itemNo") ? errors.itemNo : ""
                }
                // onChange={(e) => console.log("eeeeeeeeee", e.target.value)}
              /> */}
              <TextField
                size="small"
                type="text"
                style={hideClass}
                name="itemNumber"
                disabled={EditPermission ? false : true}
                value={linedetails[index]?.itemNo}
                onChange={(e) =>
                  handleInputChange(index, "itemNo", e.target.value)
                }
                error={errors?.hasOwnProperty("itemNo")}
                helperText={
                  errors?.hasOwnProperty("itemNo") ? errors.itemNo : ""
                }
              />
              <p className="validation-error">
                {errors?.hasOwnProperty("itemNo") ? errors.itemNo : ""}
              </p>
            </td>
            <td>
              <TextareaAutosize
                style={hideClass}
                name="description"
                value={linedetails[index]?.description}
                disabled={EditPermission ? false : true}
                onChange={(e) =>
                  handleInputChange(index, "description", e.target.value)
                }
              />
              <p className="validation-error">
                {errors?.hasOwnProperty("description") ? errors.itemNo : ""}
              </p>
            </td>
            <td>
              <TextField
                size="small"
                type="number"
                style={hideClass}
                disabled={EditPermission ? false : true}
                name="innerPacks"
                value={linedetails[index]?.innerPacks}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "innerPacks", e.target.value);
                }}
              />
            </td>
            <td>
              <Autocomplete
                name="innerPackageType"
                sx={hideClass}
                disabled={EditPermission ? false : true}
                freeSolo
                size="small"
                id="free-solo-2-demo"
                options={input.packageList}
                value={
                  linedetails[index]?.innerPackageType?.Code
                    ? linedetails[index]?.innerPackageType
                    : null
                }
                getOptionLabel={(option) => option.Code}
                onChange={(e, val) =>
                  handleInputChange(index, "innerPackageType", val)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={hideClass}
                    // label="Service Level"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </td>
            <td>
              <TextField
                size="small"
                type="number"
                style={hideClass}
                name="outerPack"
                disabled={EditPermission ? false : true}
                // error={errors?.hasOwnProperty("outerPack")}
                // helperText={
                //   errors?.hasOwnProperty("outerPack") ? errors["outerPack"] : ""
                // }
                value={linedetails[index]?.outerPack}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "outerPack", e.target.value);
                }}
              />
            </td>
            <td>
              <Autocomplete
                name="outerPackageType"
                sx={hideClass}
                disabled={EditPermission ? false : true}
                freeSolo
                size="small"
                id="free-solo-2-demo"
                options={input.packageList}
                value={
                  linedetails[index]?.outerPackageType?.Code
                    ? linedetails[index]?.outerPackageType
                    : null
                }
                getOptionLabel={(option) => option.Code}
                onChange={(e, val) =>
                  handleInputChange(index, "outerPackageType", val)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={hideClass}
                    // label="Service Level"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                disabled={EditPermission ? false : true}
                type="number"
                name="outerPackLength"
                value={linedetails[index]?.outerPackLength}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "outerPackLength", e.target.value);
                }}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                disabled={EditPermission ? false : true}
                type="number"
                name="outerPackWidth"
                value={linedetails[index]?.outerPackWidth}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "outerPackWidth", e.target.value);
                }}
              />
            </td>
            <td>
              <TextField
                size="small"
                type="number"
                style={hideClass}
                disabled={EditPermission ? false : true}
                name="outerPackHeight"
                value={linedetails[index]?.outerPackHeight}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "outerPackHeight", e.target.value);
                }}
              />
            </td>
            <td>
              <Autocomplete
                name="unitOfDimenssions"
                sx={hideClass}
                disabled={EditPermission ? false : true}
                freeSolo
                size="small"
                id="free-solo-2-demo"
                options={input.dimensionsUnitList}
                value={
                  linedetails[index]?.unitOfDimenssions?.Unit
                    ? linedetails[index]?.unitOfDimenssions
                    : null
                }
                getOptionLabel={(option) => option.Unit}
                onChange={(e, val) =>
                  handleInputChange(index, "unitOfDimenssions", val)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={hideClass}
                    // label="Service Level"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                disabled={EditPermission ? false : true}
                type="number"
                name="qtyOrdered"
                value={linedetails[index]?.qtyOrdered}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "qtyOrdered", e.target.value);
                }}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                type="number"
                name="qtyReceived"
                disabled={EditPermission ? false : true}
                value={linedetails[index]?.qtyReceived}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "qtyReceived", e.target.value);
                }}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                type="number"
                name="qtyRemaining"
                disabled={EditPermission ? false : true}
                value={linedetails[index]?.qtyRemaining}
                onChange={(e) =>
                  handleInputChange(index, "qtyRemaining", e.target.value)
                }
              />
            </td>
            <td>
              <Autocomplete
                name="unitOfQty"
                sx={hideClass}
                freeSolo
                size="small"
                id="free-solo-2-demo"
                options={input.volumeUnitList}
                disabled={EditPermission ? false : true}
                value={
                  linedetails[index]?.unitOfQty?.Unit
                    ? linedetails[index]?.unitOfQty
                    : null
                }
                getOptionLabel={(option) => option.Unit}
                onChange={(e, val) =>
                  handleInputChange(index, "unitOfQty", val)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={hideClass}
                    // label="Service Level"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                type="number"
                name="actualVolume"
                disabled={EditPermission ? false : true}
                value={linedetails[index]?.actualVolume}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "actualVolume", e.target.value);
                }}
              />
            </td>
            <td>
              <Autocomplete
                name="unitOfVolume"
                sx={hideClass}
                freeSolo
                size="small"
                id="free-solo-2-demo"
                onChange={(e, val) =>
                  handleInputChange(index, "unitOfVolume", val)
                }
                options={input.volumeUnitList}
                disabled={EditPermission ? false : true}
                value={
                  linedetails[index]?.unitOfVolume?.Unit
                    ? linedetails[index]?.unitOfVolume
                    : null
                }
                getOptionLabel={(option) => option.Unit}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={hideClass}
                    // label="Service Level"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                type="number"
                value={linedetails[index]?.actualWeight}
                disabled={EditPermission ? false : true}
                name="actualWeight"
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "actualWeight", e.target.value);
                }}
              />
            </td>
            <td>
              <Autocomplete
                name="unitOfWeight"
                sx={hideClass}
                freeSolo
                size="small"
                id="free-solo-2-demo"
                disabled={EditPermission ? false : true}
                onChange={(e, val) =>
                  handleInputChange(index, "unitOfWeight", val)
                }
                options={input.weightUnitList}
                value={
                  linedetails[index]?.unitOfWeight?.Unit
                    ? linedetails[index]?.unitOfWeight
                    : null
                }
                getOptionLabel={(option) => option.Unit}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={hideClass}
                    // label="Service Level"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </td>
            <td>
              <TextField
                size="small"
                style={hideClass}
                type="number"
                value={linedetails[index]?.itemPrice}
                name="itemPrice"
                disabled={EditPermission ? false : true}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    handleInputChange(index, "itemPrice", e.target.value);
                }}
              />
            </td>
            <td>
              <TextField
                disabled
                size="small"
                style={hideClass}
                type="number"
                name="totalPrice"
                value={
                  linedetails[index]?.qtyOrdered * linedetails[index]?.itemPrice
                }
                // onChange={(e) =>
                //   handleInputChange(index, "totalPrice", e.target.value)
                // }
              />
            </td>
            <td>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={EditPermission ? false : true}
                  label={
                    <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                      Req. In Store{" "}
                    </span>
                  }
                  className="w-100 datepicker removeRed"
                  inputFormat="DD/MM/YYYY"
                  value={linedetails[index]?.reqInStoreDate}
                  onChange={(e) =>
                    handleInputChange(index, "reqInStoreDate", e)
                  }
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </td>
            <td>
              <Autocomplete
                name="incoTerm"
                sx={hideClass}
                freeSolo
                size="small"
                id="free-solo-2-demo"
                options={input.incoTerm}
                disabled={EditPermission ? false : true}
                value={
                  linedetails[index]?.incoTerm?.Code
                    ? linedetails[index]?.incoTerm
                    : null
                }
                getOptionLabel={(option) => option.Code}
                onChange={(e, val) => handleInputChange(index, "incoTerm", val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={hideClass}
                    // label="Service Level"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </td>
            <td>
              <TextareaAutosize
                style={hideClass}
                name="additionalTerms"
                disabled={EditPermission ? false : true}
                value={linedetails[index]?.additionalTerms}
                onChange={(e) =>
                  handleInputChange(index, "additionalTerms", e.target.value)
                }
              />
              <p className="validation-error">
                {errors?.hasOwnProperty("additionalTerms")
                  ? errors.additionalTerms
                  : ""}
              </p>
            </td>
            <td>
              <TextareaAutosize
                style={hideClass}
                name="confirmNumber"
                disabled={EditPermission ? false : true}
                value={linedetails[index]?.confirmNumber}
                onChange={(e) =>
                  handleInputChange(index, "confirmNumber", e.target.value)
                }
              />
              <p className="validation-error">
                {errors?.hasOwnProperty("confirmNumber")
                  ? errors.confirmNumber
                  : ""}
              </p>
            </td>
            <td>
              <TextareaAutosize
                style={hideClass}
                disabled={EditPermission ? false : true}
                value={linedetails[index]?.containerNumber}
                name="containerNumber"
                onChange={(e) =>
                  handleInputChange(index, "containerNumber", e.target.value)
                }
              />
              <p className="validation-error">
                {errors?.hasOwnProperty("containerNumber")
                  ? errors.containerNumber
                  : ""}
              </p>
            </td>
            <td>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="confirmDate"
                  className="w-100 datepicker removeRed"
                  inputFormat="DD/MM/YYYY"
                  disabled={EditPermission ? false : true}
                  value={linedetails[index]?.confirmDate}
                  onChange={(e) => handleInputChange(index, "confirmDate", e)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </td>
            <td>
              <IconButton
                size="small"
                color="secondary"
                disabled={
                  EditPermission
                    ? permisisonOrder?.DeleteOrderLine
                      ? false
                      : true
                    : true
                }
                onClick={() => handleDelete(item?.lineNo)}
              >
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
export default LineTableBody;
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];
