import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import instance from "../../api/Interceptor";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { AuthContext } from "../../store/context/AuthProvider";
import EnquiryList from "./EnquiryList";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const DetailsEnquiry = ({
  formdropData1,
  setFormdropData1,
  formOriginData,
  setFormOriginData,
  handleRmsDataChange1,
  rows,
  setRows,
  validationErrors,
  setValidationErrors,
}) => {
  const context = React.useContext(AuthContext);
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const navigate = useNavigate();
  const [transport, setTransport] = useState([]);
  const [movement, setMovement] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [inco, setInco] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryPort, setCountryPort] = useState([]);
  const [countryDestPort, setCountryDestPort] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rmsFormData, setRmsFormData] = useState({
    InquiryNo: "",
    CWBookingNumber: "",
    Transportmode: "",
    Containertype: "",
    MovementType: "",
    Incoterm: "",
    Carotype: "",
    IMOClass: "",
    HSCode: "",
    UnNumber: "",
    OriginPort: "",
    destinationport: "",
    POReceipt: "",
    FinalDeliveryUnlocoCode: "",
    ShipperInvoiceNumber: "",
    Goodsvalue: "",
    Cargoreadydate: "",
    AdditionalServices: "",
    Goodsdescription: "",
    StatusCode: "",
    StatusDescription: "",
    JobType: "",
    CreatedUserName: "",
    ConsignorCode: "",
    ConsignorName: "",
    ConsigneeCode: "",
    ConsigneeName: "",
    NotifypartyCode: "",
    NotifypartyName: "",
    PaymentTerm: "",
    ClientNotes: "",
    CarrierCode: "",
    CarrierName: "",
    validityDate: "",
  });
  const [isOriginPortFilled, setIsOriginPortFilled] = useState(false);
  const [isDestPortFilled, setIsDestPortFilled] = useState(false);
  const isCountryNameSelected = !!formOriginData.originCountry;
  const isDestCountryNameSelected = !!formOriginData.destCountry;
  const isDoorToDoor = formdropData1.movementType === "Door to Door";
  const isPortToDoor = formdropData1.movementType === "Port to Door";
  const isDoorToPort = formdropData1.movementType === "Door to port";
  const [selectedOriginCountry, setSelectedOriginCountry] = useState(null);
  const [selectedOriginPort, setSelectedOriginPort] = useState(null);
  const [selectedDestCountry, setSelectedDestCountry] = useState(null);
  const [selectedDestPort, setSelectedDestPort] = useState(null);

  const handleDropdownOriginChange = (event) => {
    const { name, value } = event.target;
    const selectedCountry = country.find((c) => c.CountryCode === value);
    setFormOriginData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setFormdropData1((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "transportType") {
      localStorage.setItem("TransportData", JSON.stringify(value));
    } // Set the selected value in local storage
  };
  useEffect(() => {
    setIsOriginPortFilled(!!formOriginData.originPort); // !! converts to boolean
  }, [formOriginData.originPort]);

  useEffect(() => {
    setIsDestPortFilled(!!formOriginData.destPort);
  }, [formOriginData.destPort]);

  useEffect(() => {
    getTransporttype();
  }, [context.buttonActive]);

  useEffect(() => {
    getcountryPort();
    setSelectedOriginCountry(
      country.find((c) => c.CountryCode === formOriginData.originCountry) ||
        null
    );
    setSelectedOriginPort(
      country.find((c) => c.CountryCode === formOriginData.OriginPort) || null
    );
  }, [formOriginData.originCountry, formOriginData.originCountryName, country]);

  useEffect(() => {
    getdestcountryPort();
    setSelectedDestCountry(
      country.find((c) => c.CountryCode === formOriginData.destCountry) || null
    );
    setSelectedDestPort(
      country.find((c) => c.CountryCode === formOriginData.destPort) || null
    );
  }, [formOriginData.destCountry, country]);

  useEffect(() => {
    getTransporttype();
    getMovementtype();
    getcargotype();
    getincotype();
    getcountry();
  }, []);
  const getTransporttype = () => {
    setLoading(true);
    const body = {
      TransportMode: context?.buttonActive?.Code ?? "AIR",
    };
    instance
      .post("api/v1/master/GetContainerTypeInquiry", body)
      .then((res) => {
        setTransport(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getMovementtype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetMovementType")
      .then((res) => {
        setMovement(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcargotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetCargoType")
      .then((res) => {
        //  console.log("movement",res.data);
        setCargo(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getincotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetIncoTerm")
      .then((res) => {
        //  console.log("movement",res.data);
        setInco(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcountry = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetCountries")
      .then((res) => {
        setCountry(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcountryPort = () => {
    setLoading(true);
    const body = {
      CountryCode: formOriginData.originCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        const nameAndCode = res.data.map((obj) => {
          return { title: `${obj.Name} (${obj.Code})` };
        });
        setCountryPort(nameAndCode ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getdestcountryPort = () => {
    setLoading(true);
    const body = {
      CountryCode: formOriginData.destCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        const nameAndCode = res.data.map((obj) => {
          return { title: `${obj.Name} (${obj.Code})` };
        });
        setCountryDestPort(nameAndCode ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.title,
  });
  const filterOptionsForCountryName = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.CountryName,
  });

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ width: "100%" }}
      >
        {/* --FIRST-- */}
        <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: " var(--color-mile)" }}
              >
                TRANSPORT TYPE
              </InputLabel>
              <Select
                name="transportType"
                value={formdropData1.transportType}
                onChange={handleDropdownChange}
                fullWidth
                className="MuiSelect-outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                default={20}
                label="TRANSPORT TYPE"
                IconComponent={KeyboardArrowDownOutlinedIcon}
                variant="outlined"
              >
                {/* <MenuItem value={0} disabled>Select</MenuItem> */}
                {transport.map((trans, index) => {
                  const value = `${trans.Description} (${trans.Code})`;
                  return (
                    <MenuItem value={value}>
                      {trans.Description} ({trans.Code})
                    </MenuItem>
                  );
                })}
              </Select>
              {validationErrors.transportType && (
                <div className="error">{validationErrors.transportType}</div>
              )}
            </FormControl>
            <br />
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: " var(--color-mile)" }}
              >
                MOVEMENT TYPE
                <span style={{ color: "var(--color-miscellaneous-7)" }}>*</span>
              </InputLabel>
              <Select
                name="movementType"
                value={formdropData1.movementType}
                onChange={handleDropdownChange}
                fullWidth
                className="MuiSelect-outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                default={20}
                label="MOVEMENT TYPE"
                IconComponent={KeyboardArrowDownOutlinedIcon}
                variant="outlined"
              >
                {/* <MenuItem value={0} disabled>Select</MenuItem> */}
                {movement.map((movem, index) => {
                  const value = `${movem.Description} (${movem.Code})`;
                  return (
                    <MenuItem value={movem.Description}>
                      {movem.Description}
                    </MenuItem>
                  );
                })}
              </Select>
              {validationErrors.movementType && (
                <div className="error">{validationErrors.movementType}</div>
              )}
            </FormControl>

            {/* /////////// */}
            <br />
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: " var(--color-mile)" }}
              >
                CARGO TYPE
              </InputLabel>
              <Select
                fullWidth
                name="cargoType"
                value={formdropData1.cargoType}
                onChange={handleDropdownChange}
                className="MuiSelect-outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                default={20}
                label="CARGO TYPEE"
                IconComponent={KeyboardArrowDownOutlinedIcon}
                variant="outlined"
              >
                {/* <MenuItem value={0} disabled>Select</MenuItem> */}
                {cargo.map((cargo, indexC) => {
                  return (
                    <MenuItem value={cargo.Description}>
                      {cargo.Description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* /////////////// */}

            <br />
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: " var(--color-mile)" }}
              >
                INCO TERMS
                <span style={{ color: "var(--color-miscellaneous-7)" }}>*</span>
              </InputLabel>
              <Select
                fullWidth
                name="incoType"
                value={formdropData1.incoType}
                onChange={handleDropdownChange}
                className="MuiSelect-outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                default={20}
                label="INCO TERMS"
                IconComponent={KeyboardArrowDownOutlinedIcon}
                variant="outlined"
              >
                {/* <MenuItem value={0} disabled>Select</MenuItem> */}
                {inco.map((inco, indexI) => {
                  const value = `${inco.Description}(${inco.Code})`;
                  return (
                    <MenuItem value={value}>
                      {inco.Description}({inco.Code})
                    </MenuItem>
                  );
                })}
              </Select>
              {validationErrors.incoType && (
                <div className="error">{validationErrors.incoType}</div>
              )}
            </FormControl>
          </Paper>
        </Grid>
        {/* --MID-- */}
        <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper
            elevation={4}
            sx={{ padding: "24px", height: "100%", width: "100%" }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                <Typography className="pb-3">
                  <LocationOnIcon color="primary" />
                  <span className="card-rms">Origin</span>
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "0rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="COUNTRYNAME"
                    options={country}
                    getOptionLabel={(option) => option.CountryName}
                    value={selectedOriginCountry}
                    filterOptions={filterOptionsForCountryName}
                    onChange={(event, newValue) => {
                      setSelectedOriginCountry(newValue);
                      setFormOriginData({
                        ...formOriginData,
                        originCountry: newValue?.CountryCode,
                        originCountryName: newValue?.CountryName,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p>
                            COUNTRY NAME
                            <span
                              style={{ color: "var(--color-miscellaneous-7)" }}
                            >
                              *
                            </span>
                          </p>
                        }
                      />
                    )}
                  />
                  {validationErrors.originCountry && (
                    <div className="error1">
                      {validationErrors.originCountry}
                    </div>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "0rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="COUNTRYPORT"
                    options={countryPort}
                    getOptionLabel={(option) => option.title}
                    value={selectedOriginPort}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      const informationToSet = newValue.title.split("(");
                      setSelectedOriginPort(newValue);
                      setFormOriginData({
                        ...formOriginData,
                        originPortName: informationToSet[0] ?? "",
                        originPort:
                          informationToSet[informationToSet.length - 1]
                            .trim()
                            .substring(
                              0,
                              informationToSet[informationToSet.length - 1]
                                .length - 1
                            ) ?? "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p>
                            COUNTRY PORT
                            <span
                              style={{ color: "var(--color-miscellaneous-7)" }}
                            >
                              *
                              {countryPort.length > 0 ? (
                                ""
                              ) : selectedOriginCountry ? (
                                <CircularProgress size={"1em"} />
                              ) : (
                                ""
                              )}
                            </span>
                          </p>
                        }
                      />
                    )}
                    disabled={!isCountryNameSelected}
                  />
                  {validationErrors.originPort && (
                    <div className="error1">{validationErrors.originPort}</div>
                  )}
                </FormControl>
                {(isDoorToDoor || isDoorToPort) && (
                  <FormControl
                    fullWidth
                    sx={{ paddingBottom: "1rem" }}
                    className="details-section"
                  >
                    <TextField
                      label="COUNTRY PIN/ZIP CODE"
                      variant="outlined"
                      name="originPin"
                      value={formOriginData.originPin}
                      onChange={(e) =>
                        handleDropdownOriginChange(
                          e,
                          e.target.value,
                          "originPin"
                        )
                      }
                      disabled={!isOriginPortFilled}
                    />
                  </FormControl>
                )}
              </Grid>
              <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                <Typography className="pb-3">
                  <LocationOnIcon color="primary" />
                  <span className="card-rms">Destination</span>
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "0rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="DESTCOUNTRYNAME"
                    options={country}
                    getOptionLabel={(option) => option.CountryName}
                    value={selectedDestCountry}
                    filterOptions={filterOptionsForCountryName}
                    onChange={(event, newValue) => {
                      setSelectedDestCountry(newValue);
                      setFormOriginData({
                        ...formOriginData,
                        destCountry: newValue?.CountryCode,
                        destCountryName: newValue?.CountryName,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p>
                            COUNTRY NAME
                            <span
                              style={{ color: "var(--color-miscellaneous-7)" }}
                            >
                              *
                            </span>
                          </p>
                        }
                      />
                    )}
                  />
                  {validationErrors.destCountry && (
                    <div className="error1">{validationErrors.destCountry}</div>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "0rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="DESTCOUNTRYPORT"
                    options={countryDestPort}
                    getOptionLabel={(option) => option.title}
                    value={selectedDestPort}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setSelectedDestPort(newValue);
                      const informationToSet = newValue.title.split("(");
                      setFormOriginData({
                        ...formOriginData,
                        destPortName: informationToSet[0] ?? "",
                        destPort:
                          informationToSet[informationToSet.length - 1]
                            .trim()
                            .substring(
                              0,
                              informationToSet[informationToSet.length - 1]
                                .length - 1
                            ) ?? "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p>
                            COUNTRY PORT
                            <span
                              style={{ color: "var(--color-miscellaneous-7)" }}
                            >
                              *
                              {countryDestPort.length > 0 ? (
                                ""
                              ) : selectedDestCountry ? (
                                <CircularProgress size={"1em"} />
                              ) : (
                                ""
                              )}
                            </span>
                          </p>
                        }
                      />
                    )}
                    disabled={!isDestCountryNameSelected}
                  />
                  {validationErrors.destPort && (
                    <div className="error1">{validationErrors.destPort}</div>
                  )}
                </FormControl>
                {(isDoorToDoor || isPortToDoor) && (
                  <FormControl
                    fullWidth
                    sx={{ paddingBottom: "1rem" }}
                    className="details-section"
                  >
                    <TextField
                      label="COUNTRY PIN/ZIP CODE"
                      variant="outlined"
                      name="destPin"
                      value={formOriginData.destPin}
                      disabled={!isDestPortFilled}
                      onChange={(e) =>
                        handleDropdownOriginChange(e, e.target.value, "destPin")
                      }
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* --LAST-- */}
        <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
            <Typography className="pb-3"></Typography>

            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <TextField
                name="remarks"
                value={formOriginData.remarks}
                onChange={(e) => {
                  var allowedCharacters = /^[A-Za-z _-]+$/;
                  if (
                    e.target.value.match(allowedCharacters) ||
                    e.target.value.length === 0
                  ) {
                    handleDropdownOriginChange(e);
                  }
                }}
                label="REMARKS"
                variant="outlined"
                multiline
                rows={5}
              />
              {validationErrors.remarks && (
                <div className="error">{validationErrors.remarks}</div>
              )}
            </FormControl>
          </Paper>
        </Grid>
        {/* --BOTTOM-- */}
        {formdropData1.transportType.length !== 0 ? (
          <Grid item lg={12} md={12} sm={12} sx={{ width: "100%" }}>
            <Paper
              elevation={4}
              sx={{ padding: "24px", height: "100%", width: "100%" }}
            >
              <Grid container spacing={2}>
                <EnquiryList rows={rows} setRows={setRows} />
              </Grid>
              <Grid
                container
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  style={{ display: "flex", justifyContent: "start" }}
                  className="buttons-container"
                >
                  <Button
                    // sx={{
                    //   borderRadius: "10%",
                    //   width: "5%",
                    //   backgroundColor: "var(--color-secondary-dark)",
                    //   color: "var(--color-white)",
                    //   "&:hover": {
                    //     backgroundColor: "var(--color-secondary-dark)", // Keep the same background color on hover
                    //   },
                    // }}

                    onClick={() => {
                      // console.log("contex", context);
                      handleRmsDataChange1();
                    }}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default DetailsEnquiry;
