import moment from "moment/moment";

// const DateFormat = (date) => {
//   if (date) {
//     return moment(date).format("DD MMM YYYY");
//   }
//   return "";
// };
const DateFormat = (date) => {
  if (date) {
    return moment(date).format("MMM DD, YYYY");
  }
  return "";
};

const DateFormatWithtime = (date) => {
  if (date) {
    return moment(date).format("DD MMM YYYY HH:MM ");
  }
  return "";
};

const DateFormatApi = (date) => {
  if (date) {
    return moment(date).format("DD MMM YYYY");
  }
  return "";
};

const TimeFormat = (date) => {
  if (date) {
    return moment(date).format("hh:mm a");
  }

  return "-";
};

export { DateFormat, TimeFormat, DateFormatApi, DateFormatWithtime };
