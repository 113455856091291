import { Typography } from "@mui/material";
import DocTableHeader from "./docTable/DocTableHeader";
import DocTableBody from "./docTable/DocTableBody";

const DocList = ({ EditPermission }) => {
  return (
    <div>
      <Typography variant="overline" color="primary" className="font-change">
        Document List
      </Typography>
      <table
        style={{
          // to add scroll in table
          display: "block",
          overflow: " auto",
          height: "54vh",
          whiteSpace: "nowrap",
        }}
      >
        <DocTableHeader />
        <DocTableBody EditPermission={EditPermission} />
      </table>
    </div>
  );
};
export default DocList;
