import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Container,
  DetailsContainer,
  Heading,
  HeadingDetails,
} from "./CustomComp";

const PackingDetails = ({ data }) => {
  const packageDisplay = (item) => {
    const transportTypeAndCargoType = {
      AIR: {
        LSE: "ALL",
        ULD: "QC",
      },
      COU: {
        COU: "ALL",
      },
      RAI: {
        FCL: "QC",
        FWL: "QC",
        LCL: "ALL",
      },
      ROA: {
        FLC: "QC",
        FTL: "QC",
        LCL: "ALL",
        LTL: "QC",
      },
      SEA: {
        BBK: "ALL",
        FCL: "QCN",
        LCL: "ALL",
        LQD: "ALL",
        ROR: "QC",
      },
      "": {
        "": "ALL",
      },
    };
    const type =
      transportTypeAndCargoType[data?.BookingHeader?.TransportMode][
        data?.BookingHeader?.Carotype
      ];
    if (type == "ALL") {
      return <DisplayAllPackageDetails item={item} />;
    } else if (type == "QC") {
      return <DisplayQCPackageDetails item={item} />;
    } else if (type == "QCN") {
      return <DisplayQCNPackageDetails item={item} />;
    }
  };

  const DisplayQCPackageDetails = ({ item }) => {
    return (
      <Container>
        <DetailsContainer>
          <Heading>Container Type: </Heading>{" "}
          <HeadingDetails>{item?.ContainerType}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>Container Count: </Heading>{" "}
          <HeadingDetails>{item?.ContainerCount}</HeadingDetails>
        </DetailsContainer>
      </Container>
    );
  };
  const DisplayAllPackageDetails = ({ item }) => {
    return (
      <Container>
        <DetailsContainer>
          <Heading>Weight: </Heading>{" "}
          <HeadingDetails>{`${item?.Weight} ${item?.WeightUnit}`}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>PakageTypeName: </Heading>{" "}
          <HeadingDetails>{item?.PakageTypeName ?? "N/A"}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>Package Quantity: </Heading>{" "}
          <HeadingDetails>{item?.PackageQuantity ?? "N/A"}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>Volume: </Heading>{" "}
          <HeadingDetails>{`${item?.Volume} ${item?.VolumeUnit}`}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>Height: </Heading>{" "}
          <HeadingDetails> {`${item?.Height} ${item?.Unit}`}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>Width: </Heading>{" "}
          <HeadingDetails> {`${item?.Width} ${item?.Unit}`}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>Length: </Heading>{" "}
          <HeadingDetails> {`${item?.Length} ${item?.Unit}`}</HeadingDetails>
        </DetailsContainer>
      </Container>
    );
  };
  const DisplayQCNPackageDetails = ({ item }) => {
    return (
      <Container>
        <DetailsContainer>
          <Heading>Container Type: </Heading>{" "}
          <HeadingDetails>{item?.ContainerType}</HeadingDetails>
        </DetailsContainer>
        <DetailsContainer>
          <Heading>Container Count: </Heading>{" "}
          <HeadingDetails>{item?.ContainerCount}</HeadingDetails>
        </DetailsContainer>{" "}
        <DetailsContainer>
          <Heading>Package Quantity: </Heading>{" "}
          <HeadingDetails>{item?.PackageQuantity ?? "N/A"}</HeadingDetails>
        </DetailsContainer>
      </Container>
    );
  };
  return (
    <>
      {data?.BookingPacking?.length && (
        <>
          <div className=" view-booking-header">
            <h5 className="common-p">Packing Details</h5>
            <hr />

            {data?.BookingPacking.map((item, index) => {
              packageDisplay();
              return (
                <Accordion className="accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Heading>Package {index + 1}</Heading>
                  </AccordionSummary>
                  <AccordionDetails>{packageDisplay(item)}</AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
export default PackingDetails;
