import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderPlan } from "../../../../../redux/orderRedux/createOrder/orderplanSlice";
import moment from "moment/moment";
import SelectQuoteCard from "./SelectQuoteCard";
import { getCarrierCodes } from "../../apiCalls/get/getSelectList";
import { updateInputState } from "../../../../../redux/orderRedux/createOrder/inputSlice";
import { useEffect } from "react";

const OrderSchedule = ({ EditPermission }) => {
  const { orderplan, input } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [scheduleCards, setScheduleCards] = useState(null);
  const [open, setOpen] = useState(false);
  const [indexOfCard, setIndexOfCard] = useState("");
  const [loading, setLoading] = useState(false);

  // console.log("OrderPlandjkvnsdkjv", orderplan);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getScheduleCards = () => {
    setLoading(true);
    const URL = `https://dashboard.mts-logistics.com/MTSConnectAPIServices/api/GetSearchResults?origin_code=${
      orderplan?.tabData[0]?.originPort?.Code ?? ""
    }&destination_code=${
      orderplan?.tabData[0]?.desnationPort?.Code ?? ""
    }&start_date=${moment(new Date()).format("DD-MM-YYYY")}&weeks_ahead=${
      orderplan?.tabData[0]?.weeks
    }&direction_A_or_D=${
      orderplan?.tabData[0]?.direction === "male" ? "A" : "D"
    }&sac_codes=`;

    axios
      .post(URL)
      .then((res) => {
        setScheduleCards(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCarrierCode = async () => {
    const getCarrierCodePromise = getCarrierCodes();
    const dataOfCarrierCodeList = await getCarrierCodePromise;
    dispatch(
      updateInputState({
        name: "carriercodes",
        data: dataOfCarrierCodeList,
      })
    );
  };

  useEffect(() => {
    getCarrierCode();
  }, []);

  const handleChange = (index, name, value) => {
    dispatch(updateOrderPlan({ index, name, value }));
  };
  return (
    <>
      <SelectQuoteCard
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        scheduleCards={scheduleCards}
        setIndexOfCard={setIndexOfCard}
        loading={loading}
      />
      <Grid container columnSpacing={2}>
        {/* col 1 */}
        <Grid item lg={6} xs={12}>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              fullWidth
              size="small"
              defaultValue=" "
              label="Vessel"
              value={orderplan?.tabData[0]?.vessel}
              disabled={EditPermission ? false : true}
              //   type="number"
              name="vessel"
              onChange={(e) => handleChange(0, "vessel", e.target.value)}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="reqInStoreDate"
              className="w-100 datepicker removeRed"
              inputFormat="DD/MM/YYYY"
              label={
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Departure Date{" "}
                </span>
              }
              disabled={EditPermission ? false : true}
              value={orderplan?.tabData[0]?.departureDate}
              onChange={(e) => handleChange(0, "departureDate", e)}
              renderInput={(params) => (
                <TextField label="Departure Date" size="small" {...params} />
              )}
            />
          </LocalizationProvider>
          <div
            style={{
              paddingTop: "0.88rem",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name="reqInStoreDate"
                className="w-100 datepicker removeRed"
                inputFormat="DD/MM/YYYY"
                label={
                  <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    Cut off Date{" "}
                  </span>
                }
                disabled={EditPermission ? false : true}
                value={orderplan?.tabData[0]?.cutOffDate}
                onChange={(e) => handleChange(0, "cutOffDate", e)}
                renderInput={(params) => (
                  <TextField label="Cut off Date" size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            {" "}
            <Autocomplete
              style={{
                paddingTop: "0.87rem",
              }}
              name="innerPackageType"
              freeSolo
              size="small"
              id="free-solo-2-demo"
              disabled={EditPermission ? false : true}
              options={forwarderOptions.map((option) => option.value)}
              // onChange={(e, val) =>
              //   handleInputChange(index, "innerPackageType", val)
              // }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Forwarder/Carrier"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </div>
        </Grid>
        {/* col 2 */}
        <Grid item lg={6} xs={12}>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            {" "}
            <TextField
              fullWidth
              size="small"
              label="Voyage Number"
              defaultValue=" "
              //   type="number"
              disabled={EditPermission ? false : true}
              value={orderplan?.tabData[0]?.voyage}
              name="voyage"
              onChange={(e) => handleChange(0, "voyage", e.target.value)}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="reqInStoreDate"
              className="w-100 datepicker removeRed"
              inputFormat="DD/MM/YYYY"
              label={
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Arrival Date{" "}
                </span>
              }
              disabled={EditPermission ? false : true}
              value={orderplan?.tabData[0]?.arrivalDate}
              onChange={(e) => handleChange(0, "arrivalDate", e)}
              renderInput={(params) => (
                <TextField label="Arrival Date" size="small" {...params} />
              )}
            />
          </LocalizationProvider>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              style={{
                paddingTop: "0.87rem",
              }}
              name="innerPackageType"
              freeSolo
              size="small"
              id="free-solo-2-demo"
              disabled={EditPermission ? false : true}
              options={input?.carriercodes}
              // value={orderplan?.tabData[0]?.carrier}
              getOptionLabel={(option) => option?.FullName}
              // onChange={(e, val) =>
              //   handleInputChange(index, "innerPackageType", val)
              // }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Carrier"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    value: `${orderplan?.tabData[0]?.carrier}`,
                  }}
                />
              )}
            />
          </div>
        </Grid>
        {/* row 2 */}
        <Grid item lg={12} md={12}>
          <Grid container>
            <Grid item lg={12} md={6} xs={12}>
              <Typography
                variant="overline"
                color={"primary"}
                className="font-change"
              >
                Available Directions
              </Typography>
              <div
                className="font-change"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    handleChange(0, "direction", e.target.value);
                  }}
                >
                  <FormControlLabel
                    className="font-change"
                    value="female"
                    control={<Radio />}
                    disabled={EditPermission ? false : true}
                    label="Earlier Departure Date"
                  />
                  <FormControlLabel
                    className="font-change"
                    value="male"
                    control={<Radio />}
                    disabled={EditPermission ? false : true}
                    label="Latest Arrival Date"
                  />
                </RadioGroup>
              </div>
            </Grid>
            <Grid item lg={12} md={6} xs={12}>
              <Typography
                id="non-linear-slider"
                gutterBottom
                className="font-change"
              >
                Weeks:
              </Typography>
              <Stack
                direction={"row"}
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Slider
                  aria-label="Temperature"
                  disabled={EditPermission ? false : true}
                  defaultValue={1}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={10}
                  onChange={(e) => {
                    handleChange(0, "weeks", e.target.value);
                  }}
                />{" "}
                <div className="buttons-container">
                  <Button
                    className="font-change"
                    disabled={EditPermission ? false : true}
                    onClick={() => {
                      getScheduleCards();
                      handleClickOpen();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Find
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default OrderSchedule;

const forwarderOptions = [{ key: "0", value: "Other" }];
