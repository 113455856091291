import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderSpecific } from "../../../../redux/orderRedux/createOrder/orderspecificslice";
import { useEffect } from "react";
import { updateInputState } from "../../../../redux/orderRedux/createOrder/inputSlice";
import {
  getAddress,
  getBuyer,
  getSupplier,
} from "../apiCalls/get/getSelectList";
import CloseIcon from "@mui/icons-material/Close";
const SupplierAndBuyer = ({ EditPermission }) => {
  const {
    orderSpecificError: { errors },
    order: { supplier, buyer },
    input: { buyerList, supplierList, supplierAddress, buyerAddress },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const getBuyerList = async () => {
    const promiseBuyerList = getBuyer();
    const dataOfBuyerList = await promiseBuyerList;
    // stores data in redux so if needed can be accessed on another step
    dispatch(
      updateInputState({
        name: "buyerList",
        data: dataOfBuyerList,
      })
    );
    if (dataOfBuyerList.length === 1) {
      getSupplierList();
      updateOrderSpecificFun("buyerName", dataOfBuyerList[0]);
      getSupplierList();
    }
  };
  const getSupplierList = async () => {
    const promiseSupplierList = getSupplier();
    const dataOfSupplierList = await promiseSupplierList;
    dispatch(
      updateInputState({
        name: "supplierList",
        data: dataOfSupplierList,
      })
    );
  };
  const updateOrderSpecificFun = (name, value, objName) => {
    // const objName = "buyer";
    dispatch(updateOrderSpecific({ name, value, objName }));
  };
  const getAddressList = async (code, listName) => {
    const getAddressDataPromise = getAddress(code);
    const { addessCollecition } = await getAddressDataPromise;
    dispatch(
      updateInputState({
        name: listName,
        data: addessCollecition,
      })
    );
  };
  useEffect(() => {
    getBuyerList();
  }, []);
  return (
    <div
      style={{
        height: "100%",
      }}
      className="overCard"
    >
      <Typography variant="overline" color={"primary"} className="font-change">
        Supplier
      </Typography>
      <Stack
        sx={{
          maxHeight: "60vh",
          overflow: "auto",
          paddingTop: "1rem",
        }}
      >
        {" "}
        <div
          className="font-change"
          style={{
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            freeSolo
            size="small"
            id="free-solo-2-demo"
            disabled={EditPermission ? false : true}
            clearIcon={
              <CloseIcon
                fontSize="small"
                onClick={(e) => {
                  // console.log(e);
                }}
              />
            }
            // defaultValue={supplier?.supplierName?.Name}
            inputValue={supplier?.supplierName?.Code}
            // value={supplier?.supplierName?.Name}
            options={supplierList}
            getOptionLabel={(option) => option?.Code}
            onChange={(e, value) => {
              updateOrderSpecificFun("supplierName", value, "supplier");
              getAddressList(value?.Code, "supplierAddress");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supplier Name"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div>
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            freeSolo
            size="small"
            id="free-solo-2-demo"
            options={supplierAddress}
            disabled={EditPermission ? false : true}
            getOptionLabel={(option) => option.Address1}
            value={supplier?.supplierAddress ? supplier?.supplierAddress : null}
            onChange={(e, value) => {
              updateOrderSpecificFun("supplierAddress", value, "supplier");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supplier Address"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div>
        <Typography
          variant="overline"
          color={"primary"}
          className="font-change"
        >
          Buyer
        </Typography>
        <div
          className="font-change"
          style={{
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            freeSolo
            size="small"
            id="free-solo-2-demo"
            disabled={EditPermission ? false : true}
            value={buyer?.buyerName?.Code ? buyer?.buyerName : null}
            options={buyerList}
            getOptionLabel={(option) => option.Code}
            onChange={(e, value) => {
              // console.log("data coming up is ", value)
              if (value === null) {
                updateOrderSpecificFun("buyerName", {}, "buyer");
              } else {
                updateOrderSpecificFun("buyerName", value, "buyer");
                getAddressList(value?.Code, "buyerAddress");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors?.hasOwnProperty("buyer.buyerName.Code")}
                label={
                  <div className="font-change">
                    Buyer Name
                    <span
                      className="font-change"
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      *
                    </span>
                  </div>
                }
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                helperText={
                  errors?.hasOwnProperty("buyer.buyerName.Code")
                    ? errors["buyer.buyerName.Code"]
                    : ""
                }
              />
            )}
          />
        </div>
        <div
          className="font-change"
          style={{
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            freeSolo
            size="small"
            id="free-solo-2-demo"
            disabled={EditPermission ? false : true}
            value={buyer?.buyerAddress ? buyer.buyerAddress : null}
            options={buyerAddress}
            getOptionLabel={(option) => option.Address1}
            onChange={(e, value) => {
              updateOrderSpecificFun("buyerAddress", value, "buyer");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buyer Address"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div>
      </Stack>
    </div>
  );
};
export default SupplierAndBuyer;
const top100Films = [];
