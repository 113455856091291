import { useSelector } from "react-redux";
const LinetableHeader = () => {
  const { linedetails } = useSelector((state) => state);
  return (
    <thead>
      <tr>
        <td style={{ color: "var(--color-primary-font)" }}>Line No.</td>
        <td style={{ color: "var(--color-primary-font)" }}>Item No.</td>
        <td style={{ color: "var(--color-primary-font)" }}>Description </td>
        <td style={{ color: "var(--color-primary-font)" }}>Inner Pack</td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Inner Package Type{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>Outer Pack </td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Outer Package Type{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Outer Pack Length{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Outer Pack Width{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Outer Pack Height{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Unit Of Dimenssions{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>Qty. Ordered </td>
        <td style={{ color: "var(--color-primary-font)" }}>Qty. Received </td>
        <td style={{ color: "var(--color-primary-font)" }}>Qty. Remaining </td>
        <td style={{ color: "var(--color-primary-font)" }}>Unit Of Qty. </td>
        <td style={{ color: "var(--color-primary-font)" }}>Actual Volume </td>
        <td style={{ color: "var(--color-primary-font)" }}>Unit of Volume </td>
        <td style={{ color: "var(--color-primary-font)" }}>Actual Weight </td>
        <td style={{ color: "var(--color-primary-font)" }}>Unit of Weight </td>
        <td style={{ color: "var(--color-primary-font)" }}>Item Price </td>
        <td style={{ color: "var(--color-primary-font)" }}>Total Price </td>
        <td style={{ color: "var(--color-primary-font)" }}>Req. in Store </td>
        <td style={{ color: "var(--color-primary-font)" }}>INCO term </td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Additional Terms{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>Confirm Number </td>
        <td style={{ color: "var(--color-primary-font)" }}>
          Container Number{" "}
        </td>
        <td style={{ color: "var(--color-primary-font)" }}>Confirm Date </td>
        <td style={{ color: "var(--color-primary-font)" }}>Delete </td>
      </tr>
    </thead>
  );
};
export default LinetableHeader;
