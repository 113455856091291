import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Fcl from "./cargodetails/Fcl";
import Lcl from "./cargodetails/Lcl";
import Air from "./cargodetails/Air";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import instance from "../../../../api/Interceptor";
import { useEffect, useState } from "react";
import { API_PATH } from "../../../../constant/apiPath";
import { useLocation } from "react-router-dom";
const CargoDetails = () => {
  const {
    bookingBasicDetails: { BookingHeader, BookingPackages },
  } = useSelector((state) => state);
  const { bookingBasicDetails } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();

  /* -----------------------------States----------------------------- */

  const [incoTerms, setIncoTerms] = useState([]);
  const [movementType, setMovementType] = useState([]);
  const [releaseType, setReleaseType] = useState([]);

  const [incoTermsSelected, setIncoTermsSelected] = useState(null);
  const [movementTypeSelected, setMovementTypeSelected] = useState(null);
  const [releaseTypeSelected, setReleaseTypeSelected] = useState(null);

  /* -----------------------------APIs----------------------------- */

  const getIncoTerms = () => {
    instance
      .get(`${API_PATH.GET_INCO_TERMS}`)
      .then((res) => {
        // console.log(res.data);
        setIncoTerms(res?.data);
        setIncoTermsSelected(() =>
          res?.data?.find((item, index) => {
            return item.Code === BookingHeader?.Incoterm;
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        if (location.pathname.includes("edit")) {
        }
      });
  };

  const getMovementType = () => {
    instance
      .get(`${API_PATH.GET_BOOKING_MOVEMENT}`)
      .then((res) => {
        // console.log(res.data);
        setMovementType(res?.data);
        setMovementTypeSelected(() =>
          res?.data?.find((item, index) => {
            return item.Code === BookingHeader?.MovementType;
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const getReleaseType = () => {
    instance
      .get(`${API_PATH.GET_RELEASE_TYPE}`)
      .then((res) => {
        setReleaseType(res?.data);
        setReleaseTypeSelected(() =>
          res?.data?.find((item, index) => {
            return item.Code === BookingHeader?.ReleaseType;
          })
        );
      })
      .catch((err) => console.log(err));
  };
  /* ----------------------------Functions--------------------------- */

  const handleUpdateDetails = (name, value) => {
    dispatch(
      updateDetails({ objName: "BookingHeader", name: name, value: value })
    );
  };

  const RenderedPackages = () => {
    if (BookingHeader?.TransportMode === "AIR") {
      if (BookingHeader?.Carotype === "LSE") {
        return <Air />;
      } else if (BookingHeader?.Carotype === "ULD") {
        return <Fcl />;
      }
    } else if (BookingHeader?.TransportMode === "COU") {
      return <Air />;
    } else if (BookingHeader?.TransportMode === "RAI") {
      if (BookingHeader?.Carotype === "FCL") {
        return <Fcl />;
      } else if (BookingHeader?.Carotype === "FWL") {
        return <Fcl />;
      } else if (BookingHeader?.Carotype === "LCL") {
        return <Air />;
      }
    } else if (BookingHeader?.TransportMode === "ROA") {
      if (BookingHeader?.Carotype === "FCL") {
        return <Fcl />;
      } else if (BookingHeader?.Carotype === "FTL") {
        return <Fcl />;
      } else if (BookingHeader?.Carotype === "LCL") {
        return <Air />;
      } else if (BookingHeader?.Carotype === "LTL") {
        return <Fcl />;
      }
    } else if (BookingHeader?.TransportMode === "SEA") {
      if (BookingHeader?.Carotype === "BBK") {
        return <Air />;
      } else if (BookingHeader?.Carotype === "FCL") {
        return <Lcl />;
      } else if (BookingHeader?.Carotype === "LCL") {
        return <Air />;
      } else if (BookingHeader?.Carotype === "LQD") {
        return <Air />;
      } else if (BookingHeader?.Carotype === "ROR") {
        return <Fcl />;
      }
    }
  };

  useEffect(() => {
    if (BookingHeader?.Incoterm !== "" && incoTerms.length > 0) {
      setIncoTermsSelected(() =>
        incoTerms?.find((item, index) => {
          return item.Code === BookingHeader?.Incoterm;
        })
      );
    }
    if (BookingHeader?.MovementType !== "" && movementType.length > 0) {
      setMovementTypeSelected(() =>
        movementType?.find((item, index) => {
          return item.Code === BookingHeader?.MovementType;
        })
      );
    }
    if (BookingHeader?.ReleaseType !== "" && releaseType.length > 0) {
      setReleaseTypeSelected(() =>
        releaseType?.find((item, index) => {
          return item.Code === BookingHeader?.ReleaseType;
        })
      );
    }
  }, [BookingHeader, incoTerms, movementType, releaseType]);

  useEffect(() => {
    getIncoTerms();
    getMovementType();
    getReleaseType();
  }, []);

  return (
    <div className="cargo-details">
      <div className="details">
        <div>
          <h5>Cargo Details</h5>
        </div>
        <div className="details-container">
          <div className="details-left input-gap">
            <div className="cargo-details-bottom">
              <TextField
                type="number"
                label="Gross Weight"
                value={BookingHeader?.GrossWeight ?? ""}
                fullWidth
                size="small"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleUpdateDetails("GrossWeight", e.target.value);
                  }
                }}
              />

              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Weight Unit
                </InputLabel>
                <Select
                  label="WeightUnit"
                  value={BookingHeader?.WeightUnit}
                  onChange={(e) => {
                    handleUpdateDetails("WeightUnit", e.target.value);
                  }}
                >
                  {weightUnits.map((item, index) => {
                    return (
                      <MenuItem
                        className="font-change"
                        key={index}
                        value={item?.label}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="cargo-details-bottom">
              <TextField
                type="number"
                label="Volume"
                value={BookingHeader?.GrossVolume ?? ""}
                fullWidth
                size="small"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleUpdateDetails("GrossVolume", e.target.value);
                  }
                }}
              />

              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Volume Unit
                </InputLabel>
                <Select
                  label="VolumeUnit"
                  value={BookingHeader?.VolumeUnit}
                  onChange={(e) => {
                    handleUpdateDetails("VolumeUnit", e.target.value);
                  }}
                >
                  {volumeUnits.map((item, index) => {
                    return (
                      <MenuItem
                        className="font-change"
                        key={index}
                        value={item?.label}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <TextField
              label="Hazardous good details"
              fullWidth
              value={BookingHeader?.HazardousGoodDetails}
              size="small"
              onChange={(e) => {
                handleUpdateDetails("HazardousGoodDetails", e.target.value);
              }}
            />
          </div>
          <div className="details-right input-gap">
            {/* <div className="cargo-details-bottom"> */}
            <Autocomplete
              sx={{ width: "100%" }}
              freeSolo
              size="small"
              disablePortal
              value={incoTermsSelected}
              id="combo-box-demo"
              onChange={(e, val) => {
                handleUpdateDetails("Incoterm", val?.Code);
              }}
              options={incoTerms}
              getOptionLabel={(option) => `${option?.Description}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Inco Term"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            <Autocomplete
              sx={{ width: "100%" }}
              freeSolo
              size="small"
              disablePortal
              value={movementTypeSelected}
              id="combo-box-demo"
              onChange={(e, val) => {
                // console.log(val);
                handleUpdateDetails("MovementType", val?.Code);
              }}
              options={movementType}
              getOptionLabel={(option) => `${option?.Description}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service Level"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            {/* </div> */}
            {/* <div className="cargo-details-bottom"> */}
            <Autocomplete
              sx={{ width: "100%" }}
              freeSolo
              size="small"
              disablePortal
              value={releaseTypeSelected}
              id="combo-box-demo"
              options={releaseType}
              onChange={(e, val) => {
                handleUpdateDetails("ReleaseType", val?.Code);
              }}
              getOptionLabel={(option) => `${option?.Description}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Release Type"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />

            <TextField
              fullWidth
              size="small"
              value={BookingHeader?.MarksandNumbers}
              label="Marks and Number"
              onChange={(e, val) => {
                handleUpdateDetails("MarksandNumbers", e.target.value);
              }}
            />

            {/* </div> */}
          </div>
        </div>
      </div>

      <div className="cargo-common ">
        <p style={{ paddingBottom: "0.5rem" }}>Packages</p>
        {/* {BookingHeader?.TransportMode === "SEA" &&
        BookingHeader?.Carotype === "FCL" ? (
          <Lcl />
        ) : BookingHeader?.Carotype === "LCL" ? (
          <Air />
        ) : BookingHeader?.Carotype === "FCL" || "ULD" ? (
          <Fcl />
        ) : BookingHeader?.Carotype === "LSE" ? (
          <Air />
        ) : (
          ""
        )} */}
        <RenderedPackages />
      </div>
    </div>
  );
};
export default CargoDetails;

const weightUnits = [{ label: "KG" }, { label: "Tonn" }];
const volumeUnits = [{ label: "M3" }];
