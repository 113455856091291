import instance from "../../api/Interceptor";
export default function downloadDocument(body) {
  let prom = new Promise(function (resolve, reject) {
    instance
      .get(
        `api/v1/Booking/downloadbookingdocs?BookingNumber=${body?.BookingNumber}&DocPk=${body?.DocumentPk}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return prom;
}
