import React, { useEffect, useState } from "react";
import LayoutDetail from "../../../components/layout/LayoutDetail";
import "../shipment.css";
import { basename } from "../../../Config";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DocUploadShipmentSidebar from "../../../components/sidebar/DocUploadShipmentSidebar";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, Skeleton } from "@mui/material";
import { toast } from "react-hot-toast";
import { genrateDownloadLink } from "../../../utils/utility";
import urlResolver from "../../../utils/urlResolver";
import { AuthContext } from "../../../store/context/AuthProvider";
import { useContext } from "react";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import FileViewer from "react-file-viewer";
import PreviewIcon from "@mui/icons-material/Preview";

const Documentation = () => {
  const [docUpload, setDocUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docList, setDocList] = useState({});
  const [docListOld, setDocListOld] = useState({});
  const [filter, setFilter] = useState("ALL");
  const location = useLocation();
  const context = useContext(AuthContext);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [extension, setExtension] = useState("");

  console.log(extension, docLink);

  // ----------------------------------------
  // const [checkALL, setCheckAll] = useState(false);
  // const [individualCheckArr, setIndividualCheckArr] = useState([]);

  const documentList = () => {
    setLoading(true);
    const body = {
      ShipmentID: location?.state?.ShipmentId ?? "",
    };
    instance
      .post(API_PATH.GET_SHIPMENT_DOCUMENT_LIST, body)
      .then((res) => {
        if (res.data) {
          setDocList(res?.data);
          setDocListOld(res?.data);
        } else {
          setDocList(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (context?.shipmentId?.length !== 0) documentList();
  }, [location?.state?.ShipmentId]);

  const file = docLink;
  const type = extension;

  const downloadDocs = (item, call) => {
    const body = {
      ShipmentID: item?.SC_JobNum ?? "",
      DocumentPk: item?.SC_PK ?? "",
    };

    instance
      .post(API_PATH.GET_DOCUMENT_DATA, body)
      .then((i) => {
        const downloadLink = document.createElement("a");
        const prefix = genrateDownloadLink(i?.data[0]?.SC_FileName ?? "");
        downloadLink.href = `${prefix}${i?.data[0]?.ImageDataVC ?? ""}`;
        if (prefix && i?.data[0]?.ImageDataVC) {
          downloadLink.download = i?.data[0]?.SC_FileName ?? "";
          downloadLink.click();
        } else {
          toast.error("Invalid document found!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      navigate(Routeconstant.SHIPMENT_LISTING);
    }
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent style={{ height: "70rem" }}>
          {/* <iframe
            title="doc-view"
            src={`${docLink}#toolbar=0`}
            width="100%"
            height="100%"
          /> */}
          <FileViewer fileType={type} filePath={file} />
        </DialogContent>
      </Dialog>
      <LayoutDetail>
        {docUpload ? (
          <DocUploadShipmentSidebar
            setDocUpload={setDocUpload}
            docUpload={docUpload}
            documentList={documentList}
          />
        ) : null}

        <div className="p-2">
          <p className="basic-head font-shipment">Documentation</p>
        </div>
        {loading ? (
          <div className="skeleton-div">
            <Skeleton style={{ height: "3rem" }} />
            <Skeleton style={{ height: "30rem" }} />
          </div>
        ) : (
          <>
            {docList?.DocumentModelCollection?.length ? (
              <>
                <div className="d-flex justify-content-around align-items-center">
                  <button
                    // key={item}
                    // value={item}
                    className={
                      filter === "ALL"
                        ? "btn-doc px-5 py-1 backgroundColor font-shipment"
                        : "btn-doc px-5 py-1 font-shipment"
                    }
                    onClick={(e) => {
                      setFilter("ALL");
                      const temp = { ...docListOld };
                      setDocList(temp);
                    }}
                  >
                    ALL
                  </button>
                  {docList.DocumentTypes.map((item) => {
                    return (
                      <button
                        key={item}
                        value={item}
                        className={
                          filter === item
                            ? "btn-doc px-5 py-1 backgroundColor font-shipment"
                            : "btn-doc px-5 py-1 font-shipment"
                        }
                        onClick={(e) => {
                          setFilter(e.target.value);
                          const temp = { ...docListOld };
                          const temp2 = temp.DocumentModelCollection.filter(
                            (item) => {
                              return item.SC_DocType === e.target.value;
                            }
                          );
                          setDocList({
                            ...docList,
                            DocumentModelCollection: [...temp2],
                          });
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
                <table className="w-100 documentation-info mt-3 tableBody-div">
                  <thead>
                    <tr className="documentation table">
                      {/* <th>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onClick={() => {
                            setCheckAll(!checkALL);
                            setIndividualCheckArr([]);
                          }}
                          value=""
                          id="defaultCheck1"
                        />
                        <span style={{ paddingLeft: "3rem" }}>Name</span>
                      </div>{" "}
                    </th> */}
                      <th className="font-shipment">Name</th>
                      <th className="font-shipment">Type </th>
                      <th className="font-shipment">File type</th>
                      <th className="font-shipment">Preview</th>
                      <th className="d-flex justify-content-center shadow-none">
                        <img
                          alt=""
                          src={urlResolver("assets/dotted.svg")}
                          style={{ visibility: "hidden" }}
                        />
                      </th>
                    </tr>
                  </thead>
                  <>
                    <tbody>
                      {docList.DocumentModelCollection.map((i, index) => {
                        return (
                          <tr className="documentation-row" key={index}>
                            {/* <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                onClick={() => {
                                  if (individualCheckArr.includes(index)) {
                                    const updatedCheckArr =
                                      individualCheckArr.filter(
                                        (obj) => obj !== index
                                      );
                                    setIndividualCheckArr(updatedCheckArr);
                                  } else {
                                    const updatedCheckArr = [
                                      ...individualCheckArr,
                                      index,
                                    ];
                                    setIndividualCheckArr(updatedCheckArr);
                                  }
                                }}
                                checked={
                                  checkALL
                                    ? true
                                    : individualCheckArr.includes(index)
                                }
                              />
                              <span
                                className="invoice"
                                style={{ paddingLeft: "3rem" }}
                              >
                                {i?.SC_FileName ?? "N/A"}{" "}
                              </span>
                            </div>{" "}
                          </td> */}
                            <td className="invoice font-shipment">
                              {i?.SC_FileName ?? "N/A"}{" "}
                            </td>
                            <td className="font-shipment">
                              {i?.SC_DocType ?? "N/A"}{" "}
                            </td>
                            <td className="font-shipment">
                              {i?.SC_FileName.split(".")[1] ?? "N/A"}

                              {/* <Button>
                              <PictureAsPdfOutlinedIcon className="pdf-icon" />
                            </Button> */}
                            </td>
                            <td className="font-shipment">
                              <PreviewIcon
                                onClick={() => {
                                  setExtension(i?.SC_FileName.split(".")[1]);
                                  const body = {
                                    ShipmentID: i?.SC_JobNum ?? "",
                                    DocumentPk: i?.SC_PK ?? "",
                                  };

                                  instance
                                    .post(API_PATH.GET_DOCUMENT_DATA, body)
                                    .then((i) => {
                                      const downloadLink =
                                        document.createElement("a");
                                      const prefix = genrateDownloadLink(
                                        i?.data[0]?.SC_FileName ?? ""
                                      );
                                      downloadLink.href = `${prefix}${
                                        i?.data[0]?.ImageDataVC ?? ""
                                      }`;
                                      let link = `${prefix}${i?.data[0]?.ImageDataVC}`;
                                      console.log(link, "link");
                                      setDocLink(link);
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    })
                                    .finally(() => {
                                      setOpen(true);
                                    });
                                }}
                              />
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="outlined"
                                  className="mr-2 font-shipment"
                                  onClick={() => {
                                    downloadDocs(i, "download");
                                  }}
                                >
                                  <FileDownloadOutlinedIcon />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                </table>

                {/*--Mobile Respnsive */}
                <>
                  {docList.DocumentModelCollection.map((i, index) => {
                    return (
                      <div
                        class="card sip-card mt-3"
                        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                      >
                        <div className="d-flex align-items-center">
                          <div class="card-body">
                            <ul
                              className="list-inline"
                              style={{ display: "flex" }}
                            >
                              <li className="font-shipment">Name</li>
                              <li className="font-shipment ps-2">
                                {i?.SC_FileName ?? "N/A"}{" "}
                              </li>
                            </ul>
                            <ul
                              className="list-inline"
                              style={{ display: "flex" }}
                            >
                              <li className="font-shipment">Type</li>
                              <li className="ps-2 font-shipment">
                                {" "}
                                {i?.SC_DocType ?? "N/A"}
                              </li>
                            </ul>
                            <ul
                              className="list-inline"
                              style={{ display: "flex" }}
                            >
                              <li className="font-shipment">File Type</li>
                              <li className="ps-2 font-shipment">
                                {" "}
                                {i?.SC_FileName.split(".")[1] ?? "N/A"}
                              </li>
                            </ul>
                            <ul
                              className="list-inline"
                              style={{ display: "flex" }}
                            >
                              <li className="font-shipment">File Type</li>
                              <li className="ps-2 font-shipment">
                                {" "}
                                {i?.SC_FileName.split(".")[1] ?? "N/A"}
                              </li>
                            </ul>
                          </div>

                          <Button
                            variant="outlined"
                            className="mr-5 font-shipment"
                            onClick={() => {
                              downloadDocs(i);
                            }}
                          >
                            <FileDownloadOutlinedIcon />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100 ">
                No document found!
              </div>
            )}
            <img
              onClick={() => {
                setDocUpload(true);
              }}
              src={urlResolver("assets/DocumentUploadIcon.svg")}
              className="doc-upload-icon"
            />
          </>
        )}
      </LayoutDetail>
    </>
  );
};

export default Documentation;
