import React, { useState } from "react";
import "./index.css";

import Dashboard from "./dashboard/Dashboard";
const Booking = () => {

  return (
    <Dashboard />
  );
};
export default Booking;
