import { useEffect, useState } from "react";
import OrgDest from "./OrgDest";
import BuyerSupplier from "./BuyerSupplier";
import instance from "../../../../api/Interceptor";
import { API_PATH } from "../../../../constant/apiPath";
import DialogActions from "@mui/material/DialogActions";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserData,
  userTypeSelect,
} from "../../../../redux/bookingRedux/createBookingSlice";
import { updateDetails } from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import { useLocation } from "react-router-dom";
import EditBuyerSupplier from "./EditBuyerSupplier";
import UserTypeDialog from "./UserTypeDialog";

const UserType = () => {
  /* -----------------------------States----------------------------- */
  const location = useLocation();
  // const locStage = location?.pathname;
  // console.log(location, "location");
  // console.log(locStage, "locStage");

  const dispatch = useDispatch();
  const { userType } = useSelector((state) => state.createBooking);
  const {
    bookingBasicDetails: { BookingHeader },
    createBooking: { userData },
  } = useSelector((state) => state);
  const [edit, setEdit] = useState([
    {
      editBuyer: false,
      newName: "",
      newAddress1: "",
      newAddress2: "",
      newCity: "",
      newPin: "",
    },
    {
      editSupplier: false,
      newName: "",
      newAddress1: "",
      newAddress2: "",
      newCity: "",
      newPin: "",
    },
  ]);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const { Email } = JSON.parse(localStorage.getItem("userData"));
  const [buyerSuplier, setBuyerSupplier] = useState([]);
  const [open, setOpen] = useState(
    location.pathname.includes("edit") ? false : !userType
  );

  const { Name } = JSON.parse(localStorage.getItem("userData"));

  // console.log(Name);

  /* -----------------------------APIs----------------------------- */

  const getUserData = () => {
    let body = {
      OrgCode: "",
      OrgFullName: "",
      EmailId: Email,
      SearchData: "",
      Role: "",
      RegisteredDateFrom: "",
      RegisteredDateTo: "",
      LastLoginUtcDateFrom: "",
      LastLoginUtcDateTo: "",
      ContactName: "",
      ContactNumber: "",
      Access: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
    };
    instance.post(`${API_PATH.GET_ALL_USER}`, body).then((res) => {
      setBuyerSupplier(res?.data);
      dispatch(
        updateUserData({ keyName: "initialBuyerOrSupplier", value: res.data })
      );
    });
  };

  /* -----------------------------Functions----------------------------- */

  const handleClose = () => {
    if (!location.pathname.includes("edit")) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <div className="user-type">
        <UserTypeDialog
          open={open}
          handleClose={handleClose}
          Name={Name}
          buyerSuplier={buyerSuplier}
        />
        <div>
          <h5>Basic Details</h5>
        </div>
        <div className="userTypesContainer">
          {location.pathname.includes("edit") ? (
            <EditBuyerSupplier />
          ) : (
            <BuyerSupplier
              edit={edit}
              setEdit={setEdit}
              collection={userData?.initialBuyerOrSupplier}
              role={userType}
            />
          )}
          <OrgDest edit={edit} setEdit={setEdit} />
        </div>
      </div>
    </>
  );
};
export default UserType;

const roles = [{ label: "Buyer" }, { label: "Supplier" }];
