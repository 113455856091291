import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import instance from "../../../../api/Interceptor";
import { API_PATH } from "../../../../constant/apiPath";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { updateUserData } from "../../../../redux/bookingRedux/createBookingSlice";

const BuyerSupplier = ({ edit, setEdit, collection, role }) => {
  const {
    errorBooking,
    bookingBasicDetails: { BookingHeader },
    createBooking: { userData, userType },
  } = useSelector((state) => state);
  const { createBooking } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();

  /* -----------------------------States----------------------------- */

  const [address, setAddress] = useState({
    buyerAddress: [],
    supplierAddress: [],
  });
  const [viewBuyer, setViewBuyer] = useState([]);
  const [viewSupplier, setViewSupplier] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({
    buyerAddress: "",
    supplierAddress: "",
  });

  // const [buyer, setBuyer] = useState(null);
  // const [supplier, setSupplier] = useState(null);

  /* -----------------------------APIs----------------------------- */
  console.log(userData, "dataaaa");
  const getBuyerSupplier = (org, role) => {
    let body = {
      OrganisationCode: org,
      Type: role,
    };
    instance.post(`${API_PATH.GET_BUYER_SUPPLIER}`, body).then((res) => {
      if (
        role === "Buyer" &&
        BookingHeader?.ConsigneeCode &&
        userData?.address?.buyerAddress?.length === 0
      ) {
        dispatch(
          updateUserData({
            keyName: "viewSupplier",
            value: res?.data?.SupplierBuyers,
          })
        );
        let temp = { ...userData.address };
        temp.buyerAddress = res?.data?.Organisation?.Addresses;

        dispatch(updateUserData({ keyName: "address", value: temp }));
      } else if (
        role === "Supplier" &&
        BookingHeader.ConsignorCode &&
        userData?.address?.supplierAddress?.length === 0
      ) {
        dispatch(
          updateUserData({
            keyName: "viewBuyers",
            value: res.data.SupplierBuyers,
          })
        );
        let temp = { ...userData.address };
        temp.supplierAddress = res?.data?.Organisation?.Addresses;
        dispatch(updateUserData({ keyName: "address", value: temp }));
      }
    });
  };

  /* -----------------------------Functions----------------------------- */

  function handleUpdateDetails(name, value) {
    dispatch(
      updateDetails({ objName: "BookingHeader", name: name, value: value })
    );
  }

  useEffect(() => {
    if (role === "Buyer") {
      let buyers = collection[0]?.OrgnisationCollection.filter(
        (type) => type?.IsBuyer === true
      );
      dispatch(updateUserData({ keyName: "viewBuyers", value: buyers }));
    } else if (role === "Supplier") {
      let suppliers = collection[0]?.OrgnisationCollection.filter(
        (type) => type?.IsSupplier === true
      );
      dispatch(updateUserData({ keyName: "viewSupplier", value: suppliers }));
    }
  }, [role, collection]);

  useEffect(() => {
    if (role === "Buyer" && BookingHeader.ConsigneeCode) {
      getBuyerSupplier(BookingHeader.ConsigneeCode, "Buyer");
    } else if (role === "Supplier" && BookingHeader.ConsignorCode) {
      getBuyerSupplier(BookingHeader.ConsignorCode, "Supplier");
    }
  }, [BookingHeader.ConsigneeCode, BookingHeader.ConsignorCode]);

  // GetAddress
  useEffect(() => {
    if (
      BookingHeader?.ConsigneeCode?.length > 0 &&
      BookingHeader?.ConsignorCode?.length > 0
    ) {
      if (role === "Buyer") {
        let tempAdd = { ...userData.address };
        tempAdd.supplierAddress = userData?.viewSupplier[0]?.Addresses;
        dispatch(updateUserData({ keyName: "address", value: tempAdd }));
        console.log("tempAddtempAdd", userData, tempAdd);
      } else if (role === "Supplier") {
        console.log("tempAddtempAdd", "innnnn");
        let tempAdd = { ...userData.address };
        tempAdd.buyerAddress = userData.viewBuyers[0]?.Addresses;
        dispatch(updateUserData({ keyName: "address", value: tempAdd }));
      }
    }
  }, [
    BookingHeader.ConsigneeCode,
    BookingHeader.ConsignorCode,
    selectedAddress,
  ]);

  // useEffect(() => {
  //   if (location.pathname.includes("edit")) {
  //     let buyer = viewBuyer?.find((item, index) => {
  //       return item.OrgCode === BookingHeader?.ConsigneeCode;
  //     });
  //     let supplier = viewSupplier?.find((item, index) => {
  //       return item.OrgCode === BookingHeader?.ConsignorCode;
  //     });
  //     setBuyer(buyer);
  //     setSupplier(supplier);
  //   }
  // }, []);
  return (
    <div className="buyer-supplier input-gap">
      {/* <div className="select-organisation">
        <p>Select Organisation</p>
        
      </div> */}
      <div className="edit ">
        <p>Consignee (Buyer)</p>
        {/* <input type="checkbox"></input> */}
        <div className="buttons-container">
          <button
            className="d-flex align-items-center"
            style={{ border: "none", height: "25px" }}
            onClick={() => {
              let temp = [...edit];
              temp[0].editBuyer = !temp[0]?.editBuyer;
              setEdit(temp);
              // setSelectedAddress({
              //   buyerAddress: "",
              //   supplierAddress: "",
              // });
              setSelectedAddress((prev) => {
                return {
                  ...prev,
                  buyerAddress: "",
                };
              });
            }}
          >
            Edit
          </button>
        </div>
      </div>

      {edit[0].editBuyer === true ? (
        <>
          <TextField
            size="small"
            label="Consignee name"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "15px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newName = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            size="small"
            label="Address 1"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newAddress = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            size="small"
            label="Address 2"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newAddress = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            size="small"
            label="City"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newAddress = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            type="number"
            size="small"
            value={edit[0]?.newPin}
            label="Pincode"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              if (e.target.value >= 0) {
                let temp = [...edit];
                temp[0].newPin = e.target.value;
                setEdit(temp);
              }
            }}
          />
        </>
      ) : (
        <>
          <Autocomplete
            sx={{ width: "100%" }}
            freeSolo
            size="small"
            disabled={
              role === "Supplier" && !userData?.selectedAddress?.supplierAddress
                ? true
                : false
            }
            disablePortal
            id="combo-box-demo"
            onChange={(e, val) => {
              dispatch(
                updateUserData({ keyName: "selectedBuyer", value: val })
              );
              handleUpdateDetails("ConsigneeName", val?.OrgFullName);
              handleUpdateDetails("ConsigneeCode", val?.OrgCode);
            }}
            onInputChange={(e, value, reason) => {
              if (reason === "clear") {
                if (createBooking?.userType === "Supplier") {
                  let temp = { ...selectedAddress };
                  temp.buyerAddress = "";
                  setSelectedAddress(temp);
                } else {
                  setSelectedAddress({
                    buyerAddress: "",
                    supplierAddress: "",
                  });
                  setAddress({
                    buyerAddress: [],
                    supplierAddress: [],
                  });
                }
              }
            }}
            options={userData?.viewBuyers}
            value={userData?.selectedBuyer}
            getOptionLabel={(option) => `${option?.OrgFullName}`}
            renderInput={(params) => (
              <TextField
                error={
                  errorBooking?.Booking?.consignee
                    ? errorBooking?.Booking?.consignee?.error
                    : false
                }
                helperText={
                  errorBooking?.Booking?.consignee
                    ? errorBooking?.Booking?.consignee?.label
                    : ""
                }
                {...params}
                label="Please select consignee name"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          <Autocomplete
            sx={{ width: "100%" }}
            freeSolo
            size="small"
            disabled={
              role === "Supplier" && !userData?.selectedAddress?.supplierAddress
                ? true
                : false
            }
            disablePortal
            id="combo-box-demo"
            onChange={(e, val) => {
              handleUpdateDetails("ConsigneeAddress1", val?.Address1);
              handleUpdateDetails("ConsigneeAddress2", val?.Address2);
              let temp = { ...userData.selectedAddress };
              temp.buyerAddress = val;
              dispatch(
                updateUserData({ keyName: "selectedAddress", value: temp })
              );
            }}
            options={userData?.address?.buyerAddress}
            value={userData?.selectedAddress?.buyerAddress}
            getOptionLabel={(option) => `${option?.ShortCode}`}
            renderInput={(params) => (
              <TextField
                error={
                  errorBooking?.Booking?.consigneeAddress
                    ? errorBooking?.Booking?.consigneeAddress?.error
                    : false
                }
                helperText={
                  errorBooking?.Booking?.consigneeAddress
                    ? errorBooking?.Booking?.consigneeAddress?.label
                    : ""
                }
                {...params}
                label="Please enter consignee address"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          {userData?.selectedAddress?.buyerAddress ? (
            <>
              <TextField
                size="small"
                label="Address 1"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.buyerAddress?.Address1 ?? ""}
              />
              <TextField
                size="small"
                label="Address 2"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.buyerAddress?.Address2 ?? ""}
              />
              <TextField
                size="small"
                label="City"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.buyerAddress?.City ?? ""}
              />
              <TextField
                type="number"
                size="small"
                disabled
                label="Pincode"
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.buyerAddress?.PinCode ?? ""}
              />
            </>
          ) : (
            ""
          )}
        </>
      )}

      <div className="edit">
        <p>Consignor (Supplier)</p>
        {/* <input type="checkbox"></input> */}
        <div className="buttons-container">
          <button
            className="d-flex align-items-center"
            style={{ border: "none", height: "25px" }}
            onClick={() => {
              let temp = [...edit];
              temp[1].editSupplier = !temp[1].editSupplier;
              setEdit(temp);

              // setSelectedAddress({
              //   buyerAddress: "",
              //   supplierAddress: "",
              // });
              setSelectedAddress((prev) => {
                return {
                  ...prev,
                  supplierAddress: "",
                };
              });
            }}
          >
            Edit
          </button>
        </div>
      </div>
      {edit[1].editSupplier === true ? (
        <>
          <TextField
            size="small"
            label="Consignor name"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "15px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newName = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            size="small"
            label="Address 1"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newAddress = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            size="small"
            label="Address 2"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newAddress = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            size="small"
            label="State"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              let temp = [...edit];
              temp[0].newAddress = e.target.value;
              setEdit(temp);
            }}
          />
          <TextField
            type="number"
            size="small"
            value={edit[1]?.newPin}
            label="Pincode"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "17px" }}
            onChange={(e) => {
              if (e.target.value >= 0) {
                let temp = [...edit];
                temp[1].newPin = e.target.value;
                setEdit(temp);
              }
            }}
          />
        </>
      ) : (
        <>
          <Autocomplete
            sx={{ width: "100%" }}
            freeSolo
            size="small"
            disabled={
              role === "Buyer" && !userData?.selectedAddress?.buyerAddress
                ? true
                : false
            }
            disablePortal
            id="combo-box-demo"
            onChange={(e, val) => {
              dispatch(
                updateUserData({ keyName: "selectedSupplier", value: val })
              );
              handleUpdateDetails("ConsignorName", val?.OrgFullName);
              handleUpdateDetails("ConsignorCode", val?.OrgCode);
            }}
            onInputChange={(e, val, reason) => {
              if (reason === "clear") {
                if (createBooking?.userType === "Buyer") {
                  let temp = { ...selectedAddress };
                  temp.supplierAddress = "";
                  setSelectedAddress(temp);
                } else {
                  dispatch(
                    updateUserData({
                      keyName: "selectedAddress",
                      value: {
                        buyerAddress: "",
                        supplierAddress: "",
                      },
                    })
                  );
                  dispatch(
                    updateUserData({
                      keyName: "address",
                      value: {
                        buyerAddress: [],
                        supplierAddress: [],
                      },
                    })
                  );
                }
              }
            }}
            options={userData?.viewSupplier}
            value={userData?.selectedSupplier}
            getOptionLabel={(option) => `${option?.OrgFullName}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Please enter consignor name"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          <Autocomplete
            sx={{ width: "100%" }}
            freeSolo
            size="small"
            disabled={
              role === "Buyer" && !userData?.selectedAddress?.buyerAddress
                ? true
                : false
            }
            disablePortal
            id="combo-box-demo"
            onChange={(e, val) => {
              handleUpdateDetails("ConsignorAddress1", val?.Address1);
              handleUpdateDetails("ConsignorAddress2", val?.Address2);
              let temp = { ...userData?.selectedAddress };
              temp.supplierAddress = val;
              dispatch(
                updateUserData({ keyName: "selectedAddress", value: temp })
              );
            }}
            options={userData?.address?.supplierAddress}
            value={userData?.selectedAddress?.supplierAddress}
            getOptionLabel={(option) => `${option?.ShortCode}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Please enter consignor address"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          {userData?.selectedAddress?.supplierAddress ? (
            <>
              <TextField
                size="small"
                label="Address 1"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.supplierAddress?.Address1}
              />
              <TextField
                size="small"
                label="Address 2"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.supplierAddress?.Address2}
              />
              <TextField
                size="small"
                label="City"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.supplierAddress?.City}
              />
              <TextField
                type="number"
                size="small"
                label="Pincode"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={userData?.selectedAddress?.supplierAddress?.PinCode}
              />
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default BuyerSupplier;
