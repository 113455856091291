import Box from "@mui/material/Box";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import OrderHeader from "./OrderHeader";
import OrderBody from "./OrderBody";
const OrderPlan = ({ EditPermission }) => {
  const { tabData, selectedTab } = useSelector((state) => state.orderplan);
  // console.log(EditPermission,"EditPermissionEditPermission")
  return (
    <div
      style={{
        height: "fit-content",
        width: "100%",
        // padding: "0% 5% 0% 5%",
      }}
      className="overCard"
    >
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* Header */}
          <OrderHeader />
        </Box>
        {/* Body */}
        <OrderBody EditPermission={EditPermission} />
      </TabContext>
    </div>
  );
};
export default OrderPlan;
