import React from "react";
import { useEffect, useContext } from "react";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { useState } from "react";
import { DateFormat } from "../../utils/moment";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { AuthContext } from "../../store/context/AuthProvider";
import urlResolver from "../../utils/urlResolver";
import CustomTooltip from "../../utils/CustomTooltip";
import { toast } from "react-hot-toast";
import { clsx } from "clsx";

export default function PendingRms() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [isloading, setisLoading] = useState(true);

  const [cardClicked, setCardClicked] = useState(null);
  const [favoriteShipment, setFavoriteShipment] = useState([]);

  useEffect(() => {
    getFavoriteShipment();
  }, []);
  const markFavourite = (shipmentId, isFavourite) => {
    instance
      .post(
        `${API_PATH.MARK_FAVOURITE}ShipmentId=${shipmentId}&IsFavourite=${isFavourite}`
      )
      .then((res) => {
        toast.success(res?.message?.Message ?? "");
        getFavoriteShipment();
      })
      .catch((err) => {
        toast.success(err ?? "");
      })
      .finally(() => {
        setCardClicked(null);
      });
  };
  const getFavoriteShipment = () => {
    setisLoading(true);
    const body = {
      ShipmentId: "",
      ETAFrom: "",
      ETATo: "",
      ETDFrom: "",
      ETDTo: "",
      ATDFrom: "",
      ATDTo: "",
      ATAFrom: "",
      ATATo: "",
      ShowDeveloperResponse: true,
      DateFrom: "",
      DateTo: "",
      Status: "",
      CurrentPage: 0,
      PageSize: 0,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_FAVORITESHIPMENT, body)
      .then((res) => {
        setisLoading(false);
        setFavoriteShipment(res?.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const transportImg = (locate) => {
    if (locate === "SEA") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/ship.svg")} />
      );
    } else if (locate === "AIR") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/Air.svg")} />
      );
    } else if (locate === "ROA") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/road.svg")} />
      );
    } else if (locate === "RAI") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/rail.svg")} />
      );
    } else if (locate === "COU") {
      return (
        <img
          className="img-ship-cargo"
          src={urlResolver("assets/courier.svg")}
        />
      );
    }
  };

  return (
    <div className="favorite-shipment-container">
      <div
        className="heading-filterbutton-container"
        style={{ paddingRight: "20px" }}
      >
        <div
          className="heading-shipment"
          style={{ padding: " 0px 20px 0px 0px" }}
        >
          Favorites <CustomTooltip of="Dashboard" forKey="Favorites" />
        </div>
      </div>
      <div className="shipment-data">
        {favoriteShipment.length ? (
          <>
            {favoriteShipment.map((item, index) => {
              return (
                <div className="card data-card" key={index}>
                  <div className="card-body-container">
                    <div
                      className="status-shipmentnumber-container"
                      style={{ position: "relative" }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          position: "absolute",
                          left: " -25px",
                          top: "-26px",
                        }}
                      >
                        <div className="status-container">
                          {item?.ShipmentStatus}
                        </div>
                        <img
                          alt=""
                          style={{ cursor: "pointer" }}
                          className={clsx(
                            "img-star ps-2",
                            index === cardClicked && "flash"
                          )}
                          src={urlResolver("assets/coloredStar.svg")}
                          onClick={() => {
                            if (index !== cardClicked) {
                              setCardClicked(index);
                              markFavourite(item?.ShipmentId, false);
                            }
                          }}
                        />
                        {/* {index === cardClicked ? (
                          <img
                            style={{ cursor: "pointer" }}
                            className="img-star ps-2 flash"
                            src={urlResolver(
                              "assets/coloredStar.svg"
                            )}
                          />
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            className="img-star ps-2"
                            src={urlResolver(
                              "assets/coloredStartfordetials.svg"
                            )}
                            onClick={() => {
                              setCardClicked(index);
                              markFavourite(item?.ShipmentId, false);
                            }}
                          />
                        )} */}
                      </div>
                      <div className="status-img-container">
                        <div
                          className="shipment-image"
                          style={{ position: "absolute", right: "-28px" }}
                        >
                          {transportImg(item.TransportMode)}
                        </div>
                        <div
                          className="shipmentID-container"
                          onClick={() => {
                            context.setShipmentId(item?.ShipmentId);
                            context.setGridViewShipment(false);
                            navigate(Routeconstant.SHIPMENT_LISTING_DETAILS, {
                              state: {
                                ShipmentId: item.ShipmentId,
                                data: item,
                              },
                            });
                          }}
                        >
                          <span>{item?.ShipmentId}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="logistics-container"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        className="origin-etd-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <p className="check-font">Origin</p>
                          <p className="check-font-list">
                            {item?.OriginPortName ?? "N/A"}
                            {`(${item?.OriginPortCode ?? ""})`}
                          </p>
                        </div>
                        <div>
                          <p className="check-font">ETD</p>
                          <p className="check-font-list">
                            {DateFormat(item?.ETDDate ?? null)}
                          </p>
                        </div>
                      </div>
                      <div
                        className="destination-eta-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <p className="check-font">Destination</p>
                          <p className="check-font-list">
                            {item?.DestinationPortName ?? "N/A"}
                            {`(${item?.DestinationPortCode ?? ""})`}
                          </p>
                        </div>
                        <div>
                          <p className="check-font">ETA</p>
                          <p className="check-font-list">
                            {DateFormat(item?.ETADate)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div
            className="font-change"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40rem",
              fontSize: "var(--fs-mid)",
              color: "var(--color-miscellaneous-2)",
            }}
          >
            No data found!
          </div>
        )}
      </div>
    </div>
  );
}

// // Old code with grid
// {
//   // <Grid container spacing={3}>
//   //   <Grid item lg={6} md={6} sm={6} xs={12} className="common-marginTop">
//   //     <Paper
//   //       elevation={0}
//   //       style={{
//   //         height: "99.75%",
//   //         borderRadius: "8px",
//   //       }}
//   //     >
//   //       <Grid container spacing={0}>
//   //         <Grid
//   //           item
//   //           lg={9}
//   //           sm={9}
//   //           xs={9}
//   //           sx={{
//   //             display: "flex",
//   //             alignItems: "center",
//   //             paddingLeft: "1%",
//   //           }}
//   //         >
//   //           <LightTypography variant="subtitle2">
//   //             Favorite Orders
//   //           </LightTypography>
//   //         </Grid>
//   //         <Grid
//   //           item
//   //           xs={3}
//   //           lg={3}
//   //           sm={3}
//   //           sx={{
//   //             display: "flex",
//   //             alignItems: "center",
//   //             justifyContent: "end",
//   //             padding: "3%",
//   //           }}
//   //         >
//   //           <Button
//   //             variant="outlined"
//   //             endIcon={<FilterAltIcon />}
//   //             size="small"
//   //             sx={{
//   //               borderRadius: "25px",
//   //             }}
//   //           >
//   //             <Typography variant="caption">Filter</Typography>
//   //           </Button>
//   //         </Grid>
//   //       </Grid>
//   //       <Grid
//   //         container
//   //         spacing={2}
//   //         className="common-padding"
//   //         style={{
//   //           maxHeight: "55vh",
//   //           overflow: "auto",
//   //         }}
//   //       >
//   //         {[1, 2, 3, 4].map(() => {
//   //           return (
//   //             <Grid item lg={12} sm={12} xs={12}>
//   //               <Paper elevation={2}>
//   //                 <Grid container spacing={1}>
//   //                   <Grid item lg={5} sm={5} xs={5} md={5}>
//   //                     <div
//   //                       style={{
//   //                         // position: "relative",
//   //                         width: "80%",
//   //                       }}
//   //                     >
//   //                       <div
//   //                         style={{
//   //                           position: "relative",
//   //                           backgroundColor: "#236C9C",
//   //                           left: "-4px",
//   //                           top: "-1px",
//   //                           zIndex: 1,
//   //                           paddingLeft: "5px",
//   //                         }}
//   //                       >
//   //                         <Typography
//   //                           variant="subtitle2"
//   //                           color={"#fff"}
//   //                           gutterBottom
//   //                           style={{ fontSize: "var(--fs-mid)" }}
//   //                         >
//   //                           IN TRANSIT
//   //                         </Typography>
//   //                       </div>
//   //                     </div>
//   //                     <Typography
//   //                       variant="body2"
//   //                       style={{ fontSize: "var(--fs-mid)" }}
//   //                       color={"primary"}
//   //                     >
//   //                       12345678901234567
//   //                     </Typography>
//   //                   </Grid>
//   //                   <Grid item lg={1}>
//   //                     <VerticalDivider />
//   //                   </Grid>
//   //                   <Grid item lg={6} xs={6}>
//   //                     <Grid container spacing={1}>
//   //                       <Grid item lg={6} xs={6}>
//   //                         <Stack>
//   //                           <Typography
//   //                             variant="caption"
//   //                             color="#5B5675"
//   //                             style={{ fontSize: "var(--fs-small)" }}
//   //                           >
//   //                             Origin
//   //                           </Typography>
//   //                           <Typography
//   //                             variant="caption"
//   //                             color="#5B5675"
//   //                             style={{ fontSize: "var(--fs-mid)" }}
//   //                           >
//   //                             Sheikou Port
//   //                           </Typography>
//   //                         </Stack>
//   //                       </Grid>
//   //                       <Grid item lg={6} xs={6}>
//   //                         <Stack>
//   //                           <Typography
//   //                             variant="caption"
//   //                             color="#5B5675"
//   //                             style={{ fontSize: "var(--fs-small)" }}
//   //                           >
//   //                             ETD
//   //                           </Typography>
//   //                           <Typography
//   //                             variant="caption"
//   //                             color="#5B5675"
//   //                             style={{ fontSize: "var(--fs-mid)" }}
//   //                           >
//   //                             12-05-2022
//   //                           </Typography>
//   //                         </Stack>
//   //                       </Grid>
//   //                       <Grid item lg={6} xs={6}>
//   //                         <Stack>
//   //                           <Typography
//   //                             variant="caption"
//   //                             style={{ fontSize: "var(--fs-small)" }}
//   //                             color="#5B5675"
//   //                           >
//   //                             Destination
//   //                           </Typography>
//   //                           <Typography
//   //                             variant="caption"
//   //                             color="#5B5675"
//   //                             style={{ fontSize: "var(--fs-mid)" }}
//   //                           >
//   //                             Sheikou Port
//   //                           </Typography>
//   //                         </Stack>
//   //                       </Grid>
//   //                       <Grid item lg={6}>
//   //                         <Stack>
//   //                           <Typography
//   //                             variant="caption"
//   //                             color="#5B5675"
//   //                             style={{ fontSize: "var(--fs-small)" }}
//   //                           >
//   //                             ETA
//   //                           </Typography>
//   //                           <Typography
//   //                             variant="caption"
//   //                             color="#5B5675"
//   //                             style={{ fontSize: "var(--fs-mid)" }}
//   //                           >
//   //                             12-12-2022
//   //                           </Typography>
//   //                         </Stack>
//   //                       </Grid>
//   //                     </Grid>
//   //                   </Grid>
//   //                 </Grid>
//   //               </Paper>
//   //             </Grid>
//   //           );
//   //         })}
//   //       </Grid>
//   //     </Paper>
//   //   </Grid>
//   //   {/* Favorite Shipment */}
//   //   <Grid item lg={6} sm={6} xs={12} md={6} className="common-marginTop">
//   //     <Paper
//   //       elevation={0}
//   //       style={{
//   //         height: "99.75%",
//   //         borderRadius: "8px",
//   //         overflow: "hidden",
//   //       }}
//   //     >
//   //       <Grid container spacing={0}>
//   //         <Grid
//   //           item
//   //           lg={9}
//   //           sm={9}
//   //           xs={9}
//   //           sx={{
//   //             display: "flex",
//   //             alignItems: "center",
//   //             paddingLeft: "1%",
//   //           }}
//   //         >
//   //           <LightTypography variant="subtitle2">
//   //             Favorite Shipment
//   //           </LightTypography>
//   //         </Grid>
//   //         <Grid
//   //           item
//   //           xs={3}
//   //           lg={3}
//   //           sm={3}
//   //           sx={{
//   //             display: "flex",
//   //             alignItems: "center",
//   //             justifyContent: "end",
//   //             padding: "3%",
//   //           }}
//   //         >
//   //           <Button
//   //             variant="outlined"
//   //             endIcon={<FilterAltIcon />}
//   //             size="small"
//   //             sx={{
//   //               borderRadius: "25px",
//   //             }}
//   //           >
//   //             <Typography variant="caption">Filter</Typography>
//   //           </Button>
//   //         </Grid>
//   //       </Grid>
//   //       <Grid
//   //         container
//   //         spacing={2}
//   //         className="common-padding"
//   //         style={{
//   //           maxHeight: "59vh",
//   //           height: "100%",
//   //           overflow: "auto",
//   //         }}
//   //       >
//   //         {isloading ? (
//   //           <div>Loading...</div>
//   //         ) : favoriteShipment.length ? (
//   //           favoriteShipment.map((item, index) => {
//   //             return (
//   //               <Grid item lg={12} sm={12} xs={12} key={index}>
//   //                 <Paper elevation={2}>
//   //                   <Grid container spacing={1}>
//   //                     <Grid item lg={5} sm={5} xs={5} md={5}>
//   //                       <div
//   //                         style={{
//   //                           width: "80%",
//   //                         }}
//   //                       >
//   //                         <div
//   //                           style={{
//   //                             position: "relative",
//   //                             backgroundColor: "#236C9C",
//   //                             left: "-4px",
//   //                             top: "-1px",
//   //                             zIndex: 1,
//   //                             paddingLeft: "5px",
//   //                           }}
//   //                         >
//   //                           <Typography
//   //                             variant="subtitle2"
//   //                             color={"#fff"}
//   //                             gutterBottom
//   //                             style={{ fontSize: "var(--fs-mid)" }}
//   //                           >
//   //                             {item?.ShipmentStatus}
//   //                           </Typography>
//   //                         </div>
//   //                       </div>
//   //                       <Typography
//   //                         variant="body2"
//   //                         color={"primary"}
//   //                         style={{ fontSize: "var(--fs-mid)" }}
//   //                       >
//   //                         {item?.ShipmentId}
//   //                       </Typography>
//   //                     </Grid>
//   //                     <Grid item lg={1}>
//   //                       <VerticalDivider />
//   //                     </Grid>
//   //                     <Grid item lg={6} xs={6}>
//   //                       <Grid container spacing={1}>
//   //                         <Grid item lg={6} xs={6}>
//   //                           <Stack>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-small)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               Origin
//   //                             </Typography>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-mid)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               {item?.OriginPortName}
//   //                             </Typography>
//   //                           </Stack>
//   //                         </Grid>
//   //                         <Grid item lg={6} xs={6}>
//   //                           <Stack>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-small)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               ETD
//   //                             </Typography>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-mid)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               {DateFormat(item?.ETADate)}
//   //                             </Typography>
//   //                           </Stack>
//   //                         </Grid>
//   //                         <Grid item lg={6} xs={6}>
//   //                           <Stack>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-small)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               Destination
//   //                             </Typography>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-mid)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               {item?.DestinationPortName}
//   //                             </Typography>
//   //                           </Stack>
//   //                         </Grid>
//   //                         <Grid item lg={6} xs={6}>
//   //                           <Stack>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-small)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               ETA
//   //                             </Typography>
//   //                             <Typography
//   //                               variant="caption"
//   //                               style={{ fontSize: "var(--fs-mid)" }}
//   //                               color="#5B5675"
//   //                             >
//   //                               {DateFormat(item?.ETADate)}
//   //                             </Typography>
//   //                           </Stack>
//   //                         </Grid>
//   //                       </Grid>
//   //                     </Grid>
//   //                   </Grid>
//   //                 </Paper>
//   //               </Grid>
//   //             );
//   //           })
//   //         ) : (
//   //           <Grid
//   //             item
//   //             lg={12}
//   //             sm={12}
//   //             xs={12}
//   //             sx={{
//   //               display: "flex",
//   //               justifyContent: "center",
//   //               height: "48vh",
//   //               alignItems: "center",
//   //             }}
//   //           >
//   //             <Typography variant="overline">No Data Found !</Typography>
//   //           </Grid>
//   //         )}
//   //       </Grid>
//   //     </Paper>
//   //   </Grid>
//   // </Grid>
// }
