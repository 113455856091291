const Container = (props) => {
    return <div className="view-booking-container">{props.children}</div>
}
const DetailsContainer = (props) => {
    return <div className="view-booking-details-container">{props.children}</div>
}
const Heading = (props) => {
    return <h6 className="common-p">{props.children}</h6>
}
const HeadingDetails = (props) => {
    return <h5 className="common-p wordEllips">{props.children}</h5>
}
export { Container, DetailsContainer, Heading, HeadingDetails }