import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import "./TransferListModal.css";
// method to get item not available or unchecked
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(checked, leftOrRight) {
  // indexof is a method return first occurence of the value else return -1
  return checked.filter((value) => leftOrRight.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferListModal({
  senderName,
  left,
  setLeft,
  right,
  setRight,
  title,
  subtitle,
}) {
  const role = JSON.parse(localStorage.getItem("userData"))?.Role?.includes(
    "Admin"
  );

  const [checked, setChecked] = useState([]); //contain the list of selected item
  //seperate the item selected
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  // console.log(checked,leftChecked,rightChecked,"information")
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //method to get the number of check item in the items
  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    // length same means that we have to remove all of item from checked array
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      // in this case we have to combine the items item in the checked array
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const keyConversionToFullName = (value) => {
    const allPair = {
      Finance: "Statement of Account",
      GeneralEnquiry: "General Enquiry",
      PurchageOrder: "P.O Management",
      Rms: "Rate Management System",
      Shipment: "Shipment",
      Buyer: "Buyer",
      Supplier: "Supplier",
      Admin: "Admin",
      User: "User",
      Sales: "Sales",
      BookingModule : "Booking",
      ScheduleModule : "Schedule",
      // DashBoard :"DashBoard",
    };
    return allPair[value];
  };

  // receive the title subheadertitle and arry of item
  const customList = (title, items, subHeaderTitle) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0 || (role && items.includes("Admin"))}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${items.length} ${subHeaderTitle}`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          if (value === "DashBoard") return <ListItem />;
          return (
            <ListItem
              key={value}
              role="listitem"
              disabled={role && value === "Admin"}
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={keyConversionToFullName(value)}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={10} md={5} sm={5} lg={5}>
        {customList(title[0], left, subtitle[0])}
      </Grid>
      <Grid item xs={12} md={2} sm={2} lg={2} className="icon">
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={10} md={5} sm={5} lg={5}>
        {customList(title[1], right, subtitle[1])}
      </Grid>
    </Grid>
  );
}
