import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBookingPackages,
  deleteBookingPackages,
  updateBookingPackages,
} from "../../../../../redux/bookingRedux/bookingBasicDetailSlice";
import DeleteIcon from "@mui/icons-material/Delete";
const Air = () => {
  /* -----------------------------States----------------------------- */

  const {
    bookingBasicDetails: { BookingPackages },
  } = useSelector((state) => state);
  console.log(BookingPackages, "dimensions");
  const dispatch = useDispatch();

  /* -----------------------------Functions----------------------------- */

  const handleAdd = () => {
    dispatch(addBookingPackages());
  };
  const handleDelete = (index) => {
    console.log(index);
    dispatch(deleteBookingPackages({ index: index }));
  };

  return (
    <>
      <div className="table-container-div">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Packaging Type</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Weight per Peice</TableCell>
              <TableCell>Weight Unit</TableCell>
              <TableCell>Dimension Unit</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Length</TableCell>
              <TableCell>Width</TableCell>
              <TableCell>Volume (M3)</TableCell>
              <TableCell>Stackable</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BookingPackages.map((item, index) => {
              return (
                <TableRow>
                  <TableCell>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Packaging Type
                      </InputLabel>
                      <Select
                        size="small"
                        label="PackagingType"
                        value={BookingPackages[index]?.PakageTypeName}
                        onChange={(e) => {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "PakageTypeName",
                              value: e.target.value,
                            })
                          );
                        }}
                      >
                        <MenuItem value="Pallets">Pallets</MenuItem>
                        <MenuItem value="Skids">Skids</MenuItem>
                        <MenuItem value="Crates">Crates</MenuItem>
                        <MenuItem value="Boxes">Boxes</MenuItem>
                        <MenuItem value="Cartons">Cartons</MenuItem>
                        <MenuItem value="Cases">Cases</MenuItem>
                        <MenuItem value="Packages">Packages</MenuItem>
                        <MenuItem value="Drums">Drums</MenuItem>
                        <MenuItem value="Bags">Bags</MenuItem>
                        <MenuItem value="Rolls">Rolls</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Quantity"
                      size="small"
                      value={BookingPackages[index]?.PackageQuantity}
                      fullWidth
                      type="number"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "PackageQuantity",
                              value: e.target.value,
                            })
                          );
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Weight per Peice"
                      size="small"
                      value={BookingPackages[index]?.WeightPerPiece}
                      fullWidth
                      type="number"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "WeightPerPiece",
                              value: e.target.value,
                            })
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Weight Unit
                      </InputLabel>
                      <Select
                        label="WeightUnit"
                        size="small"
                        value={BookingPackages[index]?.WeightUnit}
                        onChange={(e) => {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "WeightUnit",
                              value: e.target.value,
                            })
                          );
                        }}
                      >
                        <MenuItem
                          className="font-change"
                          key={index}
                          value="KG"
                        >
                          KG
                        </MenuItem>
                        <MenuItem
                          className="font-change"
                          key={index}
                          value="Tonn"
                        >
                          Tonn
                        </MenuItem>
                        <MenuItem
                          className="font-change"
                          key={index}
                          value="Lb"
                        >
                          Lb
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      freeSolo
                      size="small"
                      disablePortal
                      value={{ label: BookingPackages[index]?.Unit ?? "" }}
                      id="combo-box-demo"
                      options={dimensionUnits}
                      onChange={(e, val) => {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: "Unit",
                            value: val?.label,
                          })
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ marginBottom: "0 !important" }}
                          {...params}
                          label="Dimension Unit"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Height"
                      size="small"
                      value={BookingPackages[index]?.Height}
                      fullWidth
                      type="number"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "Height",
                              value: e.target.value,
                            })
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Length"
                      fullWidth
                      size="small"
                      value={BookingPackages[index]?.Length}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "Length",
                              value: e.target.value,
                            })
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Width"
                      fullWidth
                      size="small"
                      value={BookingPackages[index]?.Width}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "Width",
                              value: e.target.value,
                            })
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Volume"
                      fullWidth
                      disabled
                      size="small"
                      value={
                        BookingPackages[index]?.Unit === "cm"
                          ? (
                              ((((BookingPackages[index]?.Height / 100) *
                                BookingPackages[index]?.Width) /
                                100) *
                                BookingPackages[index]?.Length) /
                              100
                            ).toFixed(4)
                          : (
                              ((((BookingPackages[index]?.Height / 39.37) *
                                BookingPackages[index]?.Width) /
                                39.37) *
                                BookingPackages[index]?.Length) /
                              39.37
                            ).toFixed(3)
                      }
                      type="number"
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      size="small"
                      checked={BookingPackages[index]?.IsStackable}
                      onChange={(e) => {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: "IsStackable",
                            value: e.target.checked,
                          })
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <div className="buttons-container d-flex justify-content-end">
        <button
          style={{ border: "none" }}
          onClick={() => {
            handleAdd();
          }}
        >
          Add package
        </button>
      </div>
    </>
  );
};
export default Air;

const dimensionUnits = [{ label: "cm" }, { label: "inch" }];
