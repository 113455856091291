import { TabPanel } from "@mui/lab";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import OrderSchedule from "./orderbody/OrderSchedule";
import OriginDestination from "./orderbody/OriginDestination";
import MapComp from "./orderbody/MapComp";
import instance from "../../../../api/Interceptor";
import { API_PATH } from "../../../../constant/apiPath";
const OrderBody = ({ EditPermission }) => {
  const { selectedTab, tabData } = useSelector((state) => state.orderplan);

  const email = localStorage.getItem("userData");
  console.log("email", email);

  const getUserData = () => {
    let body = {
      OrgCode: "",
      OrgFullName: "",
      EmailId: ``,
      SearchData: "",
      Role: "",
      RegisteredDateFrom: "",
      RegisteredDateTo: "",
      LastLoginUtcDateFrom: "",
      LastLoginUtcDateTo: "",
      ContactName: "",
      ContactNumber: "",
      Access: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
    };
    instance.post(`${API_PATH.GET_ALL_USER}`, body).then((res) => {
      console.log(res);
    });
  };

  return (
    <TabPanel value={selectedTab}>
      {/* {`hey${tabData[selectedTab]?.index}`} */}
      <Grid container columnSpacing={2}>
        <Grid item lg={3} md={12} xs={12}>
          <OriginDestination
            indexOfPlan={selectedTab}
            EditPermission={EditPermission}
          />
        </Grid>
        <Grid item lg={5}>
          <OrderSchedule EditPermission={EditPermission} />
        </Grid>
        <Grid
          item
          lg={4}
          md={12}
          xs={12}
          sx={{
            height: "50vh",
            width: "",
            position: "relative",
          }}
        >
          <MapComp cordinates={tabData} />
        </Grid>
      </Grid>
    </TabPanel>
  );
};
export default OrderBody;
