import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
// import instance from "../../api/Interceptor";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { DateFormat } from "../../../utils/moment";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SwapVertIcon from "@mui/icons-material/SwapVert";
const ListTable = ({
  getCollection,
  pagination,
  setPagination,
  loading,
  setLoading,
  data,
  setData,
  setSorting,
  setSortingVal,
  sorting,
  sortingVal,
  search,
  reason,
  setReason,
  count,
  loadMore,
  filterOptions,
}) => {
  const navigate = useNavigate();

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [open, setOpen] = useState(false);
  const [bookingNo, setBookingNo] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getCollection(pagination, "", filterOptions, false);
  }, [sorting, sortingVal]);

  const convertBooking = (status, id, reason) => {
    setLoading(true);
    const body = {
      Status: status,
      RejectedReason: reason,
      ShowDeveloperResponse: "",
      BookingNumber: id,
    };
    instance
      .post(`${API_PATH.CONVERT_BOOKING}`, body)
      .then((res) => {
        setData([]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setData([]);

        getCollection({}, "", filterOptions, true);
        // window.location.reload();
      });
  };

  // console.log(data);
  const ActionButtons = (props) => {
    const { item, index } = props;
    if (item?.StatusCode === "0") {
      return (
        <>
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setReason("hshhjh");
              convertBooking(1, item?.BookingNo, "");
            }}
          >
            <DoneIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setBookingNo(item?.BookingNo);
              setOpen(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      );
    } else if (item?.StatusCode === "1") {
      return (
        <IconButton color="primary" size="small">
          <DoneIcon />
        </IconButton>
      );
    } else if (item?.StatusCode === "2") {
      return (
        <IconButton size="small" color="primary">
          <CloseIcon />
        </IconButton>
      );
    } else {
      return <p>N/A</p>;
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ paddingBottom: "1rem" }}
          >
            Provide the reason of rejection of Quotation Number({bookingNo})
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <TextField
              size="small"
              label="Reason"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "15px" }}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="buttons-container">
            <button
              style={{ border: "none", padding: "0 0.8rem 0 " }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              style={{ border: "none", padding: "0 0.8rem 0 " }}
              onClick={() => {
                if (reason !== "") {
                  convertBooking(2, bookingNo, reason);
                  setOpen(false);
                } else {
                  alert("Please give a valid reason");
                }
              }}
            >
              Proceed
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <div className="tableWrap">
        <table className="table">
          <thead>
            <tr>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_BookingNumber");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Booking Number
              </th>
              <th scope="col" className="font-table font-shipment">
                CW Number
              </th>
              <th scope="col" className="font-table font-shipment">
                Transport Mode
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_CarrierName");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Preferred Carrier
                {sortingVal === "BH_CarrierName" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_CarrierName" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_ConsigneeCode");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Consignee
                {sortingVal === "BH_ConsigneeCode" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_ConsigneeCode" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_ConsignorCode");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Consignor{" "}
                {sortingVal === "BH_ConsignorCode" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_ConsignorCode" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th scope="col" className="font-table font-shipment">
                Inco Term
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_OriginPortName");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Origin{" "}
                {sortingVal === "BH_OriginPortName" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_OriginPortName" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_destinationportName");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Desination{" "}
                {sortingVal === "BH_destinationportName" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_destinationportName" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_ETD");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                ETD{" "}
                {sortingVal === "BH_ETD" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_ETD" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_ETA");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                ETA{" "}
                {sortingVal === "BH_ETA" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_ETA" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th scope="col" className="font-table font-shipment">
                Type
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_CreatedUserName");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Created By{" "}
                {sortingVal === "BH_CreatedUserName" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_CreatedUserName" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th
                scope="col"
                className="font-table font-shipment"
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true,
                  });
                  setData([]);
                  setSorting(!sorting);
                  setSortingVal("BH_Createdtime");
                  // getCollection(pagination, search, "BH_BookingNumber");
                }}
              >
                Created on{" "}
                {sortingVal === "BH_Createdtime" && sorting ? (
                  <ArrowDownwardIcon />
                ) : sortingVal === "BH_Createdtime" && !sorting ? (
                  <ArrowUpwardIcon />
                ) : (
                  <SwapVertIcon />
                )}
              </th>
              <th scope="col" className="font-table font-shipment">
                Status
              </th>
              <th scope="col" className="font-table font-shipment">
                Action
              </th>
              <th scope="col" className="font-table font-shipment">
                Reject reason
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <>
                {new Array(12).fill(1).map(() => {
                  return (
                    <tr>
                      {new Array(17).fill(1).map(() => {
                        return (
                          <td>
                            <Skeleton
                              animation="wave"
                              width={146}
                              height={25}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {data.map((item, index) => {
                  // console.log(item);
                  return (
                    <tr key={index}>
                      <td
                        className="font-change"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/booking/${item?.BookingNo}`);
                        }}
                      >
                        {item?.BookingNo}
                      </td>
                      <td className="font-change">{item?.CWBookingNumber}</td>
                      <td className="font-change">{item?.TransportMode}</td>
                      <td className="font-change">{item?.CarrierName}</td>
                      <td className="font-change">{item?.ConsigneeName}</td>
                      <td className="font-change">{item?.ConsignorName}</td>
                      <td className="font-change">{item?.Incoterm}</td>
                      <td className="font-change">{item?.OriginPortName}</td>
                      <td className="font-change">
                        {item?.DestinationPortName}
                      </td>
                      <td className="font-change">
                        {item?.ETD?.split("T")[0]}
                      </td>
                      <td className="font-change">
                        {item?.ETA?.split("T")[0]}
                      </td>
                      <td className="font-change">{item?.ContainerType}</td>
                      <td className="font-change">
                        {item?.CreatedUserName?.split("@")?.[0]}
                      </td>
                      <td className="font-change">
                        {DateFormat(item?.CreateDateTimeUTC)}{" "}
                      </td>
                      <td className="font-change">
                        {item?.StatusCode === "0" ? (
                          <p>Pending</p>
                        ) : item?.StatusCode === "1" ? (
                          <p>Accepted</p>
                        ) : item?.StatusCode === "2" ? (
                          <p>Rejected</p>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td className="font-change">
                        <ActionButtons item={item} index={index} />
                      </td>
                      <td className="font-change">{item?.ApproveRemark}</td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
        {!loading && data.length <= 0 && (
          <div className="d-flex justify-content-center align-items-center font-change">
            No data found!
          </div>
        )}
      </div>
      {data.length < count ? (
        <div
          className="buttons-container"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            disabled={loading}
            style={{
              border: "none",
            }}
            onClick={() => {
              const temp = {
                ...pagination,
              };
              temp.CurrentPage = pagination.CurrentPage + 1;
              temp.PageSize = pagination.PageSize;
              temp.intialLoad = false;
              setPagination(temp);
              getCollection(temp, "", filterOptions, false);
            }}
          >
            Show more
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default ListTable;
