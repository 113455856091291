import { Autocomplete, TextField, Typography, Grid } from "@mui/material";
import { getCountries, getUnlocos } from "../../apiCalls/get/getSelectList";
import { useEffect, useState } from "react";
import { updateInputState } from "../../../../../redux/orderRedux/createOrder/inputSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderPlan } from "../../../../../redux/orderRedux/createOrder/orderplanSlice";
import axios from "axios";

const OriginDestination = ({ indexOfPlan, EditPermission }) => {
  const { input, orderplan } = useSelector((state) => state);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const originCountryCode =
    orderplan?.tabData[indexOfPlan]?.originCountryCode?.Code;
  const destinationCountryCode =
    orderplan?.tabData[indexOfPlan]?.destinationCountryCode?.Code;

  const getCountryList = async () => {
    const getCountryPromise = getCountries();
    const dataOfCountryList = await getCountryPromise;
    dispatch(
      updateInputState({
        name: "countries",
        data: dataOfCountryList,
      })
    );
  };

  const getPortList = async (name, countryCode) => {
    // console.log("originCountryCode", originCountryCode);
    const getPortListPromise = getUnlocos(countryCode, search);
    const dataOfPortList = await getPortListPromise;
    dispatch(
      updateInputState({
        name: name,
        data: dataOfPortList,
      })
    );
  };

  // useEffect(()=>{

  // },[search])

  const handleChange = (index, name, value) => {
    dispatch(updateOrderPlan({ index, name, value }));
  };

  useEffect(() => {
    getCountryList();
  }, []);
  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item lg={12} xs={12}>
          <Typography
            variant="overline"
            color={"primary"}
            className="font-change"
          >
            Origin
          </Typography>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              freeSolo
              disabled={EditPermission ? false : true}
              size="small"
              id="currencyofpayment"
              // onChange={(e, value) =>
              //   updateOrderSpecificFun("currencyOfPayment", value)
              // }
              options={input?.countries}
              value={input?.countries?.Description}
              getOptionLabel={(option) => option?.Description}
              onChange={(e, val) => {
                // getPortList("originPorts", val?.Code);
                handleChange(indexOfPlan, "originCountryCode", val);
              }}
              renderInput={(params) => (
                <TextField
                  // error={errors?.hasOwnProperty("orderDetails.currencyOfPayment")}
                  {...params}
                  label="Select Country"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  // helperText={
                  //   errors?.hasOwnProperty("orderDetails.currencyOfPayment")
                  //     ? errors["orderDetails.currencyOfPayment"]
                  //     : ""
                  // }
                />
              )}
            />
          </div>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            {" "}
            <Autocomplete
              freeSolo
              disabled={EditPermission ? false : true}
              size="small"
              id="currencyofpayment"
              // onChange={(e, value) =>
              //   updateOrderSpecificFun("currencyOfPayment", value)
              // }
              options={input?.originPorts}
              value={input?.originPorts?.Description}
              getOptionLabel={(option) =>
                `${option?.Description}-(${option?.Code})`
              }
              onChange={(e, val) => {
                handleChange(indexOfPlan, "originPort", val);
              }}
              renderInput={(params) => (
                <TextField
                  // error={errors?.hasOwnProperty("orderDetails.currencyOfPayment")}
                  {...params}
                  label="Select Port"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    if (search.length > 2) {
                      getPortList("originPorts", originCountryCode);
                    }
                  }}
                  // helperText={
                  //   errors?.hasOwnProperty("orderDetails.currencyOfPayment")
                  //     ? errors["orderDetails.currencyOfPayment"]
                  //     : ""
                  // }
                />
              )}
            />
          </div>

          <Typography
            variant="overline"
            color={"primary"}
            className="font-change"
          >
            Destination
          </Typography>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            <Autocomplete
              freeSolo
              size="small"
              id="currencyofpayment"
              // onChange={(e, value) =>
              //   updateOrderSpecificFun("currencyOfPayment", value)
              // }
              options={input?.countries}
              disabled={EditPermission ? false : true}
              value={input?.countries?.Description}
              getOptionLabel={(option) => option?.Description}
              onChange={(e, val) => {
                // getPortList("destinationPorts", val?.Code);
                handleChange(indexOfPlan, "destinationCountryCode", val);
              }}
              renderInput={(params) => (
                <TextField
                  // error={errors?.hasOwnProperty("orderDetails.currencyOfPayment")}
                  {...params}
                  label="Select Country"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  // helperText={
                  //   errors?.hasOwnProperty("orderDetails.currencyOfPayment")
                  //     ? errors["orderDetails.currencyOfPayment"]
                  //     : ""
                  // }
                />
              )}
            />
          </div>
          <div
            className="font-change"
            style={{
              marginBottom: "1rem",
            }}
          >
            {" "}
            <Autocomplete
              freeSolo
              size="small"
              id="currencyofpayment"
              // onChange={(e, value) =>
              //   updateOrderSpecificFun("currencyOfPayment", value)
              // }
              options={input?.destinationPorts}
              disabled={EditPermission ? false : true}
              value={input?.destinationPorts?.Description}
              getOptionLabel={(option) =>
                `${option?.Description}-(${option?.Code})`
              }
              onChange={(e, val) => {
                handleChange(indexOfPlan, "desnationPort", val);
              }}
              renderInput={(params) => (
                <TextField
                  // error={errors?.hasOwnProperty("orderDetails.currencyOfPayment")}
                  {...params}
                  label="Select Port"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    if (search.length > 2) {
                      getPortList("destinationPorts", destinationCountryCode);
                    }
                  }}
                  // helperText={
                  //   errors?.hasOwnProperty("orderDetails.currencyOfPayment")
                  //     ? errors["orderDetails.currencyOfPayment"]
                  //     : ""
                  // }
                />
              )}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default OriginDestination;
