import { Grid } from "@mui/material";
import OrderNumber from "./OrderNumber";
import SupplierAndBuyer from "./SupplierAndBuyer";
import OrderDetails from "./OrderDetails";
import { useSelector } from "react-redux";

const OrderSpecifics = ({ EditPermission }) => {
  const { orderDetailsFromAPI } = useSelector((state) => state.order);
  // console.log("orderDetailsFromAPIIII", orderDetailsFromAPI);

  return (
    <Grid container columnSpacing={4}>
      <Grid
        item
        lg={3}
        xs={12}
        // className="overCard"
      >
        <OrderNumber EditPermission={EditPermission} />
      </Grid>
      <Grid
        item
        lg={3}
        xs={12}
        // className="overCard"
      >
        <SupplierAndBuyer EditPermission={EditPermission} />
      </Grid>
      <Grid
        item
        lg={6}
        xs={12}
        // className="overCard"
      >
        <OrderDetails EditPermission={EditPermission} />
      </Grid>
    </Grid>
  );
};
export default OrderSpecifics;
