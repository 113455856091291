import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
  MenuItem,
  Paper,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const TablerowEnquiry = ({ data, onDelete, onChange }) => {
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const [volumeCalulated, setVolumeCalulated] = useState("");
  const handleDelete = () => {
    onDelete(data.id);
  };
  // console.log(volumeCalulated, "volumeCalulated");
  // const handleInputChange = (field, value) => {
  //   if (
  //     field === "PackagingType" ||
  //     field === "DimensionalUnit" ||
  //     field === "WeightUnit" ||
  //     field === "containerType"
  //   ) {
  //     onChange(data.id, field, value);
  //   } else if (field === "Stakable") {
  //     onChange(data.id, field, !data.Stakable);
  //   } else {
  //     onChange(data.id, field, value);
  //   }
  // };
  const handleInputChange = (field, value) => {
    if (
      field === "Quantity" ||
      field === "Length" ||
      field === "Width" ||
      field === "Height" ||
      field === "WeightPerPiece" ||
      field === "containerQuant"
    ) {
      if (parseFloat(value) < 0) {
        return;
      }
    }

    if (
      field === "PackagingType" ||
      field === "DimensionalUnit" ||
      field === "WeightUnit" ||
      field === "containerType"
    ) {
      onChange(data.id, field, value);
    } else if (field === "Stakable") {
      onChange(data.id, field, !data.Stakable);
    } else {
      onChange(data.id, field, value);
    }
  };
  const transportTypeString = savedTransportData;
  const transportTypeParts = transportTypeString.split(" (");
  const code =
    transportTypeParts.length === 2
      ? transportTypeParts[1].replace(")", "")
      : null;

  useEffect(() => {
    if (
      data.DimensionalUnit.length &&
      data.Length.length &&
      data.Height.length &&
      data.Width.length
    ) {
      const conversionvalue = data.DimensionalUnit === "cm" ? 1000000 : 61023.8;
      setVolumeCalulated(
        ((data.Width * data.Height * data.Length) / conversionvalue).toFixed(4)
      );
    }
    // console.log(
    //   data.Width,
    //   data.Height,
    //   data.Length,
    //   data.DimensionalUnit,
    //   "data"
    // );
  }, [data.Length, data.Height, data.Width, data.DimensionalUnit]);
  return (
    <>
      {["LSE", "CON", "BCN", "LCL", "BLK", "LQD", "BBK", "OBC", "UNA"].includes(
        code
      ) ? (
        <>
          <TableRow
            style={{
              display: "table",
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: "0",
            }}
          >
            <TableCell className="table-data" align="center">
              <Button
                onClick={handleDelete}
                disabled={data.disableDelete} // Disable delete button based on disableDelete property
                style={{
                  opacity: data.id === 0 ? 0.5 : 1,
                  pointerEvents: data.id === 0 ? "none" : "auto",
                }}
              >
                Delete
              </Button>
            </TableCell>
            <TableCell className="table-data" align="center">
              <Select
                size="small"
                sx={{ padding: "0" }}
                className="MuiSelect-outlined"
                name="PackagingType"
                value={data.PackagingType}
                onChange={(e) =>
                  handleInputChange("PackagingType", e.target.value)
                }
                variant="outlined"
                IconComponent={KeyboardArrowDownOutlinedIcon}
                required
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Pallets">Pallets</MenuItem>
                <MenuItem value="Skids">Skids</MenuItem>
                <MenuItem value="Crates">Crates</MenuItem>
                <MenuItem value="boxes">boxes</MenuItem>
                <MenuItem value="Cartons">Cartons</MenuItem>
                <MenuItem value="cases">cases</MenuItem>
                <MenuItem value="Packages">Packages</MenuItem>
                <MenuItem value="Drums">Drums</MenuItem>
                <MenuItem value="Bags">Bags</MenuItem>
                <MenuItem value="Rolls">Rolls</MenuItem>
              </Select>
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="Quantity"
                value={data.Quantity}
                onChange={(e) => handleInputChange("Quantity", e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <Select
                sx={{ padding: "0" }}
                size="small"
                className="MuiSelect-outlined"
                type="text"
                name="DimensionalUnit"
                value={data.DimensionalUnit}
                onChange={(e) =>
                  handleInputChange("DimensionalUnit", e.target.value)
                }
                variant="outlined"
                IconComponent={KeyboardArrowDownOutlinedIcon}
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="cm">cm</MenuItem>
                <MenuItem value="in">inch</MenuItem>
              </Select>
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="Length"
                value={data.Length}
                onChange={(e) => handleInputChange("Length", e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="Width"
                value={data.Width}
                onChange={(e) => handleInputChange("Width", e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="Height"
                value={data.Height}
                onChange={(e) => handleInputChange("Height", e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                sx={{ fontWeight: "bold", color: "black" }}
                type="text"
                name="volume"
                value={volumeCalulated}
                // onChange={(e) => handleInputChange('Height', e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
                // disabled
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <Select
                sx={{ padding: "0" }}
                size="small"
                className="MuiSelect-outlined"
                type="text"
                name="WeightUnit"
                value={data.WeightUnit}
                onChange={(e) => {
                  handleInputChange("WeightUnit", e.target.value);
                }}
                variant="outlined"
                IconComponent={KeyboardArrowDownOutlinedIcon}
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Kg">Kg</MenuItem>
                <MenuItem value="Ton">Ton</MenuItem>
                <MenuItem value="LB">LB</MenuItem>
              </Select>
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="WeightPerPiece"
                value={data.WeightPerPiece}
                onChange={(e) =>
                  handleInputChange("WeightPerPiece", e.target.value)
                }
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <Switch
                size="small"
                checked={data.Stakable}
                onChange={() => handleInputChange("Stakable")}
              />
            </TableCell>
          </TableRow>
        </>
      ) : (
        <>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TableContainer component={Paper}>
              {/* <Table> */}
              {/* <TableHead> */}
              <TableRow
                style={{
                  display: "table",
                  width: "100%",
                  borderCollapse: "collapse",
                  borderSpacing: "0",
                }}
              >
                <TableCell className="table-data" align="center">
                  <Button
                    onClick={handleDelete}
                    disabled={data.disableDelete} // Disable delete button based on disableDelete property
                    style={{
                      opacity: data.id === 0 ? 0.5 : 1,
                      pointerEvents: data.id === 0 ? "none" : "auto",
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
                <TableCell
                  className="table-data"
                  align="center"
                  // span="1"
                  // className="table-data"
                  // align="center"
                  // style={{ width: "40%" }}
                >
                  <TextField
                    type="number"
                    name="containerQuant"
                    value={data.containerQuant}
                    onChange={(e) =>
                      handleInputChange("containerQuant", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    className="custom-textfield"
                  />
                </TableCell>{" "}
                <TableCell className="table-data" align="center">
                  <Select
                    name="containerType"
                    value={data.containerType}
                    onChange={(e) =>
                      handleInputChange("containerType", e.target.value)
                    }
                    size="small"
                    className="custom-textfield"
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    variant="outlined"
                  >
                    <MenuItem value="Select" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="20 ft">20 ft</MenuItem>
                    <MenuItem value="40 ft">40 ft</MenuItem>
                    <MenuItem value="40 HC">40 HC</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
              {/* </TableHead> */}
              {/* </Table> */}
            </TableContainer>
          </Grid>
        </>
      )}
    </>
  );
};

export default TablerowEnquiry;
