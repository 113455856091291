import React, { useEffect, useState } from "react";
import { marked } from "marked";
import "../common/MarkdownRenderer.css"

const MarkdownRenderer = ({ data }) => {
  const [htmlInformation, setHtmlInformation] = useState("");
  useEffect(() => {
    setHtmlInformation(marked(data?.MarkdownText ?? "No Data Found"));
  }, [data]);
  return (
    <div className="markDownImage">
      <div dangerouslySetInnerHTML={{ __html: htmlInformation }} />
    </div>
  );
};

export default MarkdownRenderer;
