import {
  Autocomplete,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBookingPackages,
  deleteBookingPackages,
  updateBookingPackages,
} from "../../../../../redux/bookingRedux/bookingBasicDetailSlice";
import instance from "../../../../../api/Interceptor";
import { API_PATH } from "../../../../../constant/apiPath";
import {
  addCSDetails,
  updateCSDetails,
} from "../../../../../redux/bookingRedux/createBookingSlice";
import DeleteIcon from "@mui/icons-material/Delete";

const Lcl = () => {
  const {
    bookingBasicDetails: { BookingPackages },
    createBooking: {
      otherStates: { CS_Description },
    },
  } = useSelector((state) => state);
  // console.log("dimensions", BookingPackages);
  const dispatch = useDispatch();
  const [containerType, setContainerType] = useState([]);
  const [containerInfo, setContainerInfo] = useState([]);
  const [selectedContainerType, setSelectedContainerType] = useState([]);
  console.log(CS_Description);

  const getContainerType = () => {
    instance
      .get(`${API_PATH.GET_CONTAINER_TYPE}`)
      .then((res) => {
        setContainerType(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getContainerType();
  }, []);

  const handleAdd = () => {
    dispatch(addBookingPackages());
    dispatch(addCSDetails());
  };
  const handleDelete = (index) => {
    dispatch(deleteBookingPackages(index));
  };

  // console.log(CS_Description);
  useEffect(() => {
    if (BookingPackages.length > 0 && containerType.length > 0) {
      const arrayOfObjContainer = [];
      BookingPackages.forEach((element) => {
        const value = element?.ContainerType;
        const obj = containerType.find((element) => element.CS_Code == value);
        arrayOfObjContainer.push({ ...obj });
      });
      setContainerInfo([...arrayOfObjContainer]);
    }
  }, [BookingPackages, containerType]);
  return (
    <>
      <div className="table-container-div">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "20rem" }}>Container Type</TableCell>
              <TableCell>Container Number</TableCell>
              <TableCell>Quantity</TableCell>
              {/* <TableCell>Stackable</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BookingPackages.map((item, index) => {
              return (
                <TableRow>
                  <TableCell>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      freeSolo
                      size="small"
                      value={containerInfo[index] || CS_Description[index]}
                      onChange={(e, val) => {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: "ContainerType",
                            value: val?.CS_Code,
                          })
                        );
                        dispatch(
                          updateCSDetails({
                            index: index,
                            value: val,
                          })
                        );
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={containerType}
                      getOptionLabel={(option) =>
                        option?.CS_Description
                          ? `${option?.CS_Description} (${option?.CS_Code})`
                          : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          sx={{ marginBottom: "0 !important" }}
                          {...params}
                          label="Container Type"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Container Number"
                      type="number"
                      value={BookingPackages[index]?.ContainerCount}
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "ContainerCount",
                              value: e.target.value,
                            })
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ marginBottom: "0 !important" }}
                      label="Quantity"
                      type="number"
                      value={BookingPackages[index]?.PackageQuantity}
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          dispatch(
                            updateBookingPackages({
                              index: index,
                              name: "PackageQuantity",
                              value: e.target.value,
                            })
                          );
                        }
                      }}
                    />
                  </TableCell>

                  {/* <TableCell>
                    <Switch
                      size="small"
                      checked={BookingPackages[index]?.IsStackable}
                      onChange={(e) => {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: "IsStackable",
                            value: e.target.checked,
                          })
                        );
                      }}
                    />
                  </TableCell> */}
                  <TableCell>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <div
        className="buttons-container d-flex justify-content-end"
        style={{ marginTop: "10px" }}
      >
        <button
          style={{ border: "none" }}
          onClick={() => {
            handleAdd();
          }}
        >
          Add package
        </button>
      </div>
    </>
  );
};
export default Lcl;
