import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { clearBasicDetails } from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import { clearCreateBooking, clearState } from "../../../../redux/bookingRedux/createBookingSlice";
const steps = [
  {
    label: "Basic Details",
  },
  {
    label: "Cargo Details",
  },
  {
    label: "Document Upload",
  },
];
const Sidebar = () => {
  const {
    createBooking: { sidebar },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(sidebar.selected);
  useEffect(() => {
    setActiveStep(sidebar.selected);
  }, [sidebar?.selected]);
  useEffect(() => {
    return () => {
      dispatch(clearBasicDetails());
      dispatch(clearCreateBooking());
      dispatch(clearState());
    };
  }, []);
  return (
    <div
      className="sidebar"
      style={{
        height: "100%",
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <span className="textVisiblity">{step.label}</span>
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 8 }}></Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
export default Sidebar;
