import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  switchPage,
} from "../../../redux/bookingRedux/createBookingSlice";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { addErrors } from "../../../redux/bookingRedux/bookingErrorSlice";
const Footer = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const handleClose = () => {
    setBackdropOpen(false);
  };
  const handleOpen = () => {
    setBackdropOpen(true);
  };
  const {
    errorBooking,
    createBooking: { sidebar, userData },
    bookingBasicDetails,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  /* -----------------------------APIs----------------------------- */

  const createBooking = (body) => {
    setSubmitLoading(true);
    instance
      .post(`${API_PATH.CREATE_BOOKING}`, body)
      .then((res) => {
        dispatch(clearState()); // clear buyer supplier
        setIsSuccess(true);
        setBookingId(res?.data[0]?.BookingNumber);
        // setIncoTerms(res?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const fieldValidation = () => {
    let dummy = {
      consignee: null,
      consigneeAddress: null,
      consignor: null,
      consignorAddress: null,
      origin: null,
      etd: null,
      destination: null,
      eta: null,
      transportmode: null,
      cargotype: null,
    };
    if (sidebar.selected === 0) {
      if (bookingBasicDetails?.BookingHeader?.ConsigneeName?.length === 0) {
        let copyDummy = { ...dummy };
        copyDummy.consignee = {
          error: true,
          label: "Please select Consignee Name",
        };
        dummy = copyDummy;
      }
      if (bookingBasicDetails?.BookingHeader?.ConsigneeAddress1?.length === 0) {
        let copyDummy = { ...dummy };
        copyDummy.consigneeAddress = {
          error: true,
          label: "Please select Consignee Address",
        };
        dummy = copyDummy;
      }
      if (bookingBasicDetails?.BookingHeader?.OriginPortName?.length === 0) {
        let copyDummy = { ...dummy };
        copyDummy.origin = {
          error: true,
          label: "Please select Origin",
        };
        dummy = copyDummy;
      }
      if (bookingBasicDetails?.BookingHeader?.ETD?.length === 0) {
        let copyDummy = { ...dummy };
        copyDummy.etd = {
          error: true,
          label: "Please select ETD Date",
        };
        dummy = copyDummy;
      }
      if (
        bookingBasicDetails?.BookingHeader?.DestinationPortName?.length === 0
      ) {
        let copyDummy = { ...dummy };
        copyDummy.destination = {
          error: true,
          label: "Please select Destination",
        };
        dummy = copyDummy;
      }
      if (bookingBasicDetails?.BookingHeader?.ETA?.length === 0) {
        let copyDummy = { ...dummy };
        copyDummy.eta = {
          error: true,
          label: "Please select ETA Date",
        };
        dummy = copyDummy;
      }
      if (bookingBasicDetails?.BookingHeader?.TransportMode?.length === 0) {
        let copyDummy = { ...dummy };
        copyDummy.transportmode = {
          error: true,
          label: "Please select Transportmode",
        };
        dummy = copyDummy;
      }
      if (bookingBasicDetails?.BookingHeader?.Carotype?.length === 0) {
        let copyDummy = { ...dummy };
        copyDummy.cargotype = {
          error: true,
          label: "Please select Cargo type",
        };
        dummy = copyDummy;
      }
      // else if (bookingBasicDetails.BookingHeader.ConsignorName.length > 0) {

      // }
      else {
        dispatch(switchPage(sidebar.selected + 1));
      }
    }
    dispatch(addErrors({ value: dummy }));
  };

  return (
    <>
      {sidebar.selected ? (
        <button
          className="btn-submit"
          style={{
            border: "none",
            padding: "0rem 1.8rem",
          }}
          onClick={() => {
            dispatch(switchPage(sidebar.selected - 1));
          }}
        >
          Previous
        </button>
      ) : (
        <></>
      )}
      {sidebar.selected < 2 ? (
        <button
          className="btn-submit"
          style={{
            border: "none",
            padding: "0rem 1.8rem",
            marginLeft: "0rem",
            marginRight: "0.5rem",
          }}
          onClick={() => {
            if (location.pathname.includes("edit")) {
              dispatch(switchPage(sidebar.selected + 1));
            } else if (sidebar.selected === 0) {
              fieldValidation();
            } else {
              dispatch(switchPage(sidebar.selected + 1));
            }
          }}
        >
          Next
        </button>
      ) : (
        <div>
          <Button
            className="btn-submit"
            disabled={submitLoading}
            onClick={() => {
              createBooking(bookingBasicDetails);
              handleOpen();
            }}
            sx={{
              border: "none",
              padding: "0rem 1.8rem",
              alignItems: "center",
            }}
          >
            {submitLoading ? (
              <CircularProgress
                style={{ color: "black", marginTop: "2px" }}
                size={"1rem"}
              />
            ) : (
              `Submit`
            )}
          </Button>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        // <button
        //   disabled={submitLoading}
        //   style={{
        //     border: "none",
        //     padding: "0rem 1.8rem",
        //     alignItems: "center",
        //   }}
        //   onClick={() => {
        //     createBooking(bookingBasicDetails);
        //   }}
        // >
        //   {submitLoading ? (
        //     <CircularProgress
        //       style={{ color: "black", marginTop: "2px" }}
        //       size={"1rem"}
        //     />
        //   ) : (
        //     `Submit`
        //   )}
        // </button>
      )}
      <Dialog
        open={isSuccess && bookingId}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {bookingBasicDetails?.BookingHeader?.BookingNo ? (
                <h4>Booking Updated Successfully</h4>
              ) : (
                <h4>Booking Created Successfully</h4>
              )}

              <CheckCircleIcon
                sx={{
                  fontSize: "2rem",
                  color: "green",
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate(Routeconstant.BOOKING_DASHBOARD);
              isSuccess(false);
            }}
            autoFocus
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Footer;
