import * as React from "react";
import { CircularProgress } from "@mui/material";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import "./QuoteCard.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateOrderPlan } from "../../../../../redux/orderRedux/createOrder/orderplanSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SelectQuoteCard = ({
  open,
  handleClose,
  scheduleCards,
  loading,
  setIndexOfCard,
}) => {
  const dispatch = useDispatch();

  const handleUpdate = (indvCard, index, etd, eta, cutOff) => {
    dispatch(
      updateOrderPlan({
        index: "0",
        name: "vessel",
        value: indvCard?.line[0]?.transShipments[0]?.vesselName,
      })
    );
    dispatch(
      updateOrderPlan({
        index: "0",
        name: "voyage",
        value: indvCard?.line[0]?.transShipments[0]?.voyage,
      })
    );
    dispatch(
      updateOrderPlan({
        index: "0",
        name: "carrier",
        value: indvCard?.line_name,
      })
    );
    dispatch(
      updateOrderPlan({
        index: "0",
        name: "departureDate",
        value: etd,
      })
    );
    dispatch(
      updateOrderPlan({
        index: "0",
        name: "arrivalDate",
        value: eta,
      })
    );
    dispatch(
      updateOrderPlan({
        index: "0",
        name: "cutOffDate",
        value: cutOff,
      })
    );
  };
  return (
    <>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {/* Sound */}
              </Typography>
              {/* <Button autoFocus color="inherit" onClick={handleClose}>
                save
              </Button> */}
            </Toolbar>
          </AppBar>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "100%" }}
            >
              <CircularProgress />
            </div>
          ) : (
            scheduleCards?.shipping_lines.map((indvCard, index) => {
              let etdDate = new Date(0);
              etdDate.setUTCSeconds(
                indvCard?.line[0]?.transShipments[0]?.departure?.date
              );
              const etd = moment(etdDate).format("MM/DD/YYYY");
              let etaDate = new Date(0);
              etaDate.setUTCSeconds(
                indvCard?.line[0]?.transShipments[0]?.arrival?.date
              );
              const eta = moment(etaDate).format("MM/DD/YYYY");
              const cutOffDate = new Date(0);
              cutOffDate.setUTCSeconds(indvCard?.line[0]?.cutOff);
              const cutOff = moment(cutOffDate).format("MM/DD/YYYY");
              return (
                <div
                  style={{
                    backgroundColor: "#fff",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1rem",
                  }}
                >
                  <Grid
                    container
                    className="quote-card-container-main-div"
                    onClick={() => {
                      setIndexOfCard(index);
                    }}
                  >
                    <Grid item xs={12} className="upper-container-div">
                      <Grid
                        container
                        style={{ height: "100%", paddingBottom: "0.5rem" }}
                      >
                        <Grid
                          item
                          md={3}
                          xs={6}
                          className="d-flex align-items-center"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          <div className="image-container-div">
                            <img src={`${indvCard?.line_logo}`} />
                          </div>
                          <div className="company-heading">
                            {indvCard?.line_name}
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          <div className="heading-small"></div>
                          <div className="text"></div>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          {" "}
                          <div className="heading-small">Transit Days</div>
                          <div className="data">{indvCard?.transitTime}</div>
                        </Grid>{" "}
                        <Grid
                          item
                          md={2}
                          xs={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          <div className="heading-small">ETA</div>
                          <div className="data">{cutOff}</div>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          xs={4}
                          className="buttons-container"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: "var(--family-label)",
                          }}
                        >
                          <button
                            style={{ border: "none" }}
                            // className="schedule-button "
                            onClick={() => {
                              handleUpdate(indvCard, index, etd, eta, cutOff);
                              handleClose();
                            }}
                          >
                            Select Schedule
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="lower-container-div">
                      <Grid
                        container
                        style={{ height: "100%", paddingTop: "0.5rem" }}
                      >
                        <Grid
                          item
                          md={3}
                          xs={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          {" "}
                          <div className="heading-small">Vessel</div>
                          <div className="data">
                            {indvCard?.line[0]?.transShipments[0]?.vesselName}
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          {" "}
                          <div className="heading-small">Service</div>
                          <div className="data">
                            {indvCard?.line[0]?.transShipments[0]?.service}
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          {" "}
                          <div className="heading-small">ETD</div>
                          <div className="data">{etd}</div>
                        </Grid>{" "}
                        <Grid
                          item
                          md={2}
                          xs={6}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRight: "2px solid #e7e7f0",
                          }}
                        >
                          {" "}
                          <div className="heading-small">ETA</div>
                          <div className="data">{eta}</div>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          xs={6}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              paddingLeft: "1.5rem",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "var(--fs-subtitle)",
                                fontWeight: "500",
                                color: "var(--color-primary-font)",
                                fontFamily: "var(--family-label)",
                              }}
                            >
                              Origin
                            </span>
                            <div
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: "var(--color-secondary-dark)",
                                borderRadius: "50%",
                                marginLeft: "5px",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "52%",
                                borderBottom:
                                  "2px solid var(--color-secondary-dark)",
                              }}
                            ></div>
                            <div
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: "var(--color-secondary-dark)",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <span
                              style={{
                                fontSize: "var(--fs-subtitle)",
                                fontWeight: "500",
                                color: "var(--color-primary-font)",
                                fontFamily: "var(--family-label)",
                                paddingLeft: "5px",
                              }}
                            >
                              Destination
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="data">
                              {
                                indvCard?.line[0]?.transShipments[0]?.departure
                                  ?.portName
                              }
                            </div>{" "}
                            <div className="data">
                              {
                                indvCard?.line[0]?.transShipments[0]?.arrival
                                  ?.portName
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          )}
        </Dialog>
      </div>
    </>
  );
};

export default SelectQuoteCard;
