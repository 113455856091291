import { createSlice } from "@reduxjs/toolkit";

const basicDetailSlice = createSlice({
  name: "basicDetails",
  initialState: {
    BookingHeader: {
      BookingNo: "",
      SupplierBuyerType: "",
      CWBookingNumber: "",
      TransportMode: "",
      ContainerType: "",
      MovementType: "",
      Incoterm: "",
      Carotype: "",
      IMOClass: "",
      HSCode: "",
      UnNumber: "",
      OriginPort: "",
      OriginPinCode: "",
      DestinationPort: "",
      DestinationPinCode: "",
      POReceipt: "",
      FinalDeliveryUnlocoCode: "",
      OriginPortName: "",
      DestinationPortName: "",
      ShipperInvoiceNumber: "",
      Goodsvalue: 0,
      CargoReadyDate: "2024-01-09T08:38:11.662Z",
      AdditionalServices: [
        {
          type: "Insurance",
          IsTrue: null,
        },
        {
          type: "Custom Clearance",
          IsTrue: null,
        },
      ],
      GoodsDescription: "",
      StatusCode: "",
      StatusDescription: "",
      JobType: "",
      CreatedUserName: "",
      ConsignorCode: "",
      ConsignorName: "",
      ConsignorAddress1: "",
      ConsignorAddress2: "",
      ConsigneeCode: "",
      ConsigneeName: "",
      ConsigneeAddress1: "",
      ConsigneeAddress2: "",
      NotifypartyCode: "",
      NotifypartyName: "",
      PaymentTerm: "",
      ClientNotes: "",
      CarrierCode: "",
      CarrierName: "",
      ValidityDate: "2024-01-09T08:38:11.662Z",
      IsHazardous: null,
      ReleaseType: "",
      GrossWeight: "",
      WeightUnit: "",
      GrossVolume: "",
      VolumeUnit: "",
      MarksandNumbers: "",
      HazardousGoodDetails: "",
      ETD: "",
      ETA: "",
    },
    BookingPackages: [
      {
        PakageTypeCode: "",
        PakageTypeName: "",
        BookingNumber: "",
        Length: "",
        Height: "",
        Width: "",
        Unit: "",
        PackageQuantity: "",
        Volume: "",
        VolumeUnit: "",
        Weight: "",
        WeightUnit: "",
        IsStackable: false,
        ContainerType: "",
        ContainerCount: "",
      },
    ],
    DocsCollection: [],
  },

  reducers: {
    editInitialState: (state, action) => {
      // console.log(action?.payload, "payload");
      state.BookingHeader = action.payload.BookingHeader;
      state.BookingPackages = action.payload.BookingPacking;
      state.DocsCollection = action.payload.BookingEDocs;
    },
    updateDetails: (state, action) => {
      state[action.payload.objName][action.payload.name] = action.payload.value;
    },
    updateBookingPackages: (state, action) => {
      state.BookingPackages[action.payload.index][action.payload.name] =
        action.payload.value;
    },
    addBookingPackages: (state, action) => {
      let temp = {
        PakageTypeCode: "",
        PakageTypeName: "",
        BookingNumber: "",
        Length: "",
        Height: "",
        Width: "",
        Unit: "",
        PackageQuantity: "",
        Volume: "",
        VolumeUnit: "",
        Weight: "",
        WeightUnit: "",
        IsStackable: true,
        ContainerType: "",
        ContainerCount: "",
      };
      state.BookingPackages.push(temp);
    },

    deleteBookingPackages: (state, action) => {
      console.log(action.payload.index);
      state.BookingPackages.splice(action.payload.index, 1);
    },

    updateAdditionalServices: (state, action) => {
      state.BookingHeader.AdditionalServices[action.payload.index].IsTrue =
        action.payload.value;
    },
    addDocument: (state, action) => {
      const { file, base, docType, docExtension } = action.payload;
      let tempObj = {
        DocsDocType: docType,
        docTypeDesc: "",
        DocsFileName: file.name,
        DocsFileSize: file.size,
        DocsFileSizeUnit: "bytes",
        DocsUploadedDate: "",
        DocsUploadedBY: "",
        DocsFileData: base[1],
        DocsFileExtention: docExtension,
        DocsFileConversion: `${base[0]},`,
      };
      state.DocsCollection.push(tempObj);
    },
    deleteDocument: (state, action) => {
      const { index } = action.payload;
      state.DocsCollection.splice(index, 1);
    },
    clearBasicDetails: (state, action) => {
      state.BookingHeader = {
        BookingNo: "",
        SupplierBuyerType: "",
        CWBookingNumber: "",
        TransportMode: "",
        ContainerType: "",
        MovementType: "",
        Incoterm: "",
        Carotype: "",
        IMOClass: "",
        HSCode: "",
        UnNumber: "",
        OriginPort: "",
        OriginPinCode: "",
        DestinationPort: "",
        DestinationPinCode: "",
        POReceipt: "",
        FinalDeliveryUnlocoCode: "",
        OriginPortName: "",
        DestinationPortName: "",
        ShipperInvoiceNumber: "",
        Goodsvalue: 0,
        CargoReadyDate: "2024-01-09T08:38:11.662Z",
        AdditionalServices: [
          {
            type: "Insurance",
            IsTrue: null,
          },
          {
            type: "Custom Clearance",
            IsTrue: null,
          },
        ],
        GoodsDescription: "",
        StatusCode: "",
        StatusDescription: "",
        JobType: "",
        CreatedUserName: "",
        ConsignorCode: "",
        ConsignorName: "",
        ConsignorAddress1: "",
        ConsignorAddress2: "",
        ConsigneeCode: "",
        ConsigneeName: "",
        ConsigneeAddress1: "",
        ConsigneeAddress2: "",
        NotifypartyCode: "",
        NotifypartyName: "",
        PaymentTerm: "",
        ClientNotes: "",
        CarrierCode: "",
        CarrierName: "",
        ValidityDate: "2024-01-09T08:38:11.662Z",
        IsHazardous: null,
        ReleaseType: "",
        GrossWeight: "",
        WeightUnit: "",
        GrossVolume: "",
        VolumeUnit: "",
        MarksandNumbers: "",
        HazardousGoodDetails: "",
        ETD: "",
        ETA: "",
      };
      state.BookingPackages = [
        {
          PakageTypeCode: "",
          PakageTypeName: "",
          BookingNumber: "",
          Length: "",
          Height: "",
          Width: "",
          Unit: "",
          PackageQuantity: "",
          Volume: "",
          VolumeUnit: "",
          Weight: "",
          WeightUnit: "",
          IsStackable: false,
          ContainerType: "",
          ContainerCount: "",
        },
      ];
      state.DocsCollection = [];
    },
  },
});

export const {
  updateDetails,
  updateBookingPackages,
  updateAdditionalServices,
  addBookingPackages,
  deleteBookingPackages,
  editInitialState,
  clearBasicDetails,
  addDocument,
  deleteDocument,
} = basicDetailSlice.actions;
export default basicDetailSlice.reducer;
