import { Grid } from "@mui/material";
import DocUpload from "./DocUpload";
import DocList from "./DocList";

const Edoc = ({ EditPermission }) => {
  return (
    <Grid container columnSpacing={{ sm: 2, xs: 0 }} className="overCard">
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <DocUpload EditPermission={EditPermission} />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <DocList EditPermission={EditPermission} />
      </Grid>
    </Grid>
  );
};
export default Edoc;
