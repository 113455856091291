const DocTableHeader = () => {
  return (
    <thead>
      <tr>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Sr. No
        </td>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Doc Type
        </td>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Doc Name
        </td>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Doc Size
        </td>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Uploaded On
        </td>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Uploaded By
        </td>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Download
        </td>
        <td
          className="font-change"
          style={{ color: "var(--color-primary-font)" }}
        >
          Delete
        </td>
      </tr>
    </thead>
  );
};
export default DocTableHeader;
