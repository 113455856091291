import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

export default function EditBuyerSupplier() {
    const {  bookingBasicDetails: { BookingHeader }, } = useSelector(state => state)
    console.log("editBooking",BookingHeader);
    return <div className="buyer-supplier input-gap">
        <p>Consignee (Buyer)</p>
        <TextField
                size="small"
                label="Please enter consignee address"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsigneeName ?? ""}
              />
        <TextField
                size="small"
                label="Address 1"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsigneeAddress1 ?? ""}
              />
        <TextField
                size="small"
                label="Address 2"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsigneeAddress2 ?? ""}
              />
        <TextField
                size="small"
                label="City"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsigneeCity ?? ""}
              />
        <TextField
                size="small"
                label="Pincode"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsigneePincode ?? ""}
        />
         
            <p>Consignor (Supplier)</p>
            <TextField
                size="small"
                label="Please enter consignee address"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsignorName ?? ""}
              />
        <TextField
                size="small"
                label="Address 1"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsignorAddress1 ?? ""}
              />
        <TextField
                size="small"
                label="Address 2"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsignorAddress2 ?? ""}
              />
        <TextField
                size="small"
                label="City"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsignorCity ?? ""}
              />
        <TextField
                size="small"
                label="Pincode"
                disabled
                variant="outlined"
                sx={{ width: "100%", marginBottom: "17px" }}
                value={BookingHeader?.ConsignorPincode ?? ""}
        />
    </div>
}